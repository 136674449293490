import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import NotificationCard from '../../shared/notificationCard';
import AlertDialog from '../../shared/alertDialog';
import CustomFormDrawer from '../../shared/customFormDrawer';
import ReadLessMore from '../../shared/readLessMore';
import Search from '../../shared/search';
import VisibleForPermission from '../../shared/visibleForPermission';
import PlaceholderButton from '../../shared/placeholderButton';
import CustomButton from '../../shared/customButton';
import CourseCard from '../../shared/courseCard';
import Filters from '../../shared/filters';
import {
  showSuccessMessage,
  parseDuplicateParameters,
  parseQueryParams,
} from '../../../utility/uiUtils';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import {
  isObjectEmpty,
  isArrayEmpty,
  buildQueryParams,
  trimString,
  isPermissionGranted,
  isArray,
  checkUserRole,
} from '../../../utility/helpers';
import { hasSelectedFilters } from '../../shared/filters/config';
import http from '../../../utility/http';
import { sticky } from '../../../constants/helperCssRules';
import {
  API_COURSES,
  api_course,
  API_UDEMY,
  API_IMPORT_COURSES,
} from '../../../constants/apiRoutes';
import { PERMISSIONS, ROLES } from '../../../constants/rolesAndPermissionList';
import {
  ALL,
  COURSE_SOURCES,
  DEFAULT_ORDERING,
  DEFAULT_SEARCH_VALUE,
} from '../../../constants/courses';
import {
  UDEMY_BASE_URL,
  FREEMIUM_LIMIT_TYPES,
} from '../../../constants/appConfig';
import {
  PARAMS,
  PAGE_WHITELISTED_PARAMS,
  COURSES_DEFAULT_PARAMS,
} from '../../../constants/pages';
import {
  getCourseLevels,
  prepareCourses,
  prepareCourseData,
} from '../../../utility/courseUtils';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import {
  INITIAL_ADD_COURSE_DATA,
  getCourseFields,
  getCourseActions,
  getPageFilters,
} from './config';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { ReactComponent as PlusIconWhite } from '../../../assets/icons/plus-icon-white.svg';

const styles = ({ palette: { primary }, spacing }) => ({
  header: {
    ...sticky(primary.white, 105),
    paddingBottom: spacing(4),
  },
  description: {
    padding: spacing(8, 0, 6, 0),
  },
  filtersAndActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    width: 300,
  },
  addButton: {
    marginLeft: spacing(2),
  },
  courses: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: '1fr',
    gridGap: spacing(8),
  },
  placeholderButton: {
    borderRadius: 8,
    width: 434,
    minHeight: 180,
  },
});

const { SEARCH, LEVEL, ATTRIBUTE, TAGS } = PARAMS;

class CoursesPage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    isLoadingImport: false,
    courseId: null,
    isReadMore: true,
    isManageCourseOpened: false,
    initialCourseData: INITIAL_ADD_COURSE_DATA,
    filters: {
      [LEVEL]: [],
      [ATTRIBUTE]: [],
      [TAGS]: [],
    },
    [SEARCH]: '',
    isEditCourse: false,
    source: COURSE_SOURCES.UDEMY,
    availableCourses: [],
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearCourses,
      clearAttributesWithQuestions,
      clearTagsCategories,
      clearPageQuickFilters,
    } = this.props;

    clearTagsCategories();
    clearCourses();
    clearAttributesWithQuestions();
    clearPageQuickFilters();
  }

  getInitialData = () => {
    const {
      location,
      getTagsCategories,
      getPageQuickFilters,
      getAttributesWithQuestions,
    } = this.props;
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.LEARNING
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          [SEARCH]: params[SEARCH]?.toString() || '',
          filters: {
            [LEVEL]: params[LEVEL] ? [params[LEVEL]] : [],
            [ATTRIBUTE]: params[ATTRIBUTE] ? [params[ATTRIBUTE]] : [],
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handleGetCourses(),
            getTagsCategories(),
            getPageQuickFilters(),
            getAttributesWithQuestions({ is_private: false }),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handleGetCourses(),
      getTagsCategories(),
      getPageQuickFilters(),
      getAttributesWithQuestions({ is_private: false }),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  handleGetCourses = () => {
    const { history, location, getCourses } = this.props;
    const { filters, search } = this.state;
    const params = {
      ...filters,
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/learning${query ? `/?${query}` : ''}`);
    }

    return getCourses(params);
  };

  handleToggleReadMore = () => {
    this.setState(prevState => ({
      isReadMore: !prevState.isReadMore,
    }));
  };

  handleApplyFilters = filters => {
    this.setState({ filters, isLoading: true }, () =>
      this.handleGetCourses().then(() => this.setState({ isLoading: false }))
    );
  };

  onSearch = value => {
    const searchTerm = trimString(value);

    this.setState({ [SEARCH]: searchTerm, isLoading: true }, () =>
      this.handleGetCourses().then(() => this.setState({ isLoading: false }))
    );
  };

  onUdemySearch = (searchValue, values = {}) => {
    const value = trimString(searchValue);
    const params = buildQueryParams({
      [SEARCH]: value || DEFAULT_SEARCH_VALUE,
      instructional_level: values.level?.toLowerCase() || ALL.key,
    });

    return http
      .get(`${API_UDEMY}${params}`)
      .then(({ data }) => this.setState({ availableCourses: data }));
  };

  onReset = (cb = () => {}) =>
    this.setState(
      {
        isManageCourseOpened: false,
        initialCourseData: INITIAL_ADD_COURSE_DATA,
        source: COURSE_SOURCES.UDEMY,
        isEditCourse: false,
        availableCourses: [],
      },
      cb
    );

  onChangeCourseSource = source => this.setState({ source });

  onAddCourse = course => async () => {
    const { initialCourseData } = this.state;

    if (course) {
      return this.setState({
        isManageCourseOpened: true,
        isEditCourse: true,
        initialCourseData: prepareCourseData(course),
        source: null,
      });
    }

    await this.onUdemySearch(initialCourseData.search, initialCourseData);
    this.setState({ isManageCourseOpened: true });
  };

  onViewDetails = courseId => () => {
    const { history } = this.props;

    history.push(`/learning/${courseId}`);
  };

  onDeleteCourseDialog = courseId => () => {
    const { setDialogVisibility } = this.props;

    this.setState({ courseId }, () => {
      setDialogVisibility({
        dialogName: 'forceDeleteCourseDialog',
        opened: true,
      });
    });
  };

  onDeleteCourse = () => {
    const { setDialogVisibility, translations } = this.props;
    const { courseId } = this.state;
    http.delete(`${API_COURSES}${courseId}/`).then(() => {
      this.onReset(() => {
        showSuccessMessage(translations.deleteCourseDialog.deleteSuccess);
        setDialogVisibility({
          dialogName: 'forceDeleteCourseDialog',
          opened: false,
        });
        this.handleGetCourses();
      });
    });
  };

  onSaveCourse = values => {
    const { translations } = this.props;
    const { isEditCourse } = this.state;
    const {
      source,
      selectedCourse,
      attribute,
      tags,
      level,
      description,
      cover_image,
    } = values;
    let payload;

    if (source === COURSE_SOURCES.UDEMY) {
      const {
        id,
        url,
        title,
        headline,
        image_125_H,
        image_240x135,
        image_480x270,
      } = selectedCourse;

      payload = {
        course_id: id,
        title,
        level: level === ALL.id ? undefined : level,
        description: headline,
        url: `${UDEMY_BASE_URL}${url}`,
        attribute: attribute || undefined,
        tags,
        cover_image_location:
          image_240x135 || image_125_H || image_480x270 || undefined,
      };
    } else {
      payload = new FormData();
      const sanitizedDescription = description ? trimString(description) : '';
      payload.append('title', values.title);
      payload.append('url', values.url);

      payload.append('description', sanitizedDescription || '');
      payload.append('level', level || '');
      if (!cover_image || cover_image instanceof File) {
        payload.append('cover_image', cover_image || '');
      }
      payload.append('attribute', attribute || '');
      if (!isArrayEmpty(tags)) {
        tags.forEach(tag => {
          payload.append('tags', tag);
        });
      }

      if (isEditCourse && isArrayEmpty(tags)) {
        payload.append('tags', '');
      }
    }

    if (isEditCourse) {
      return http.patch(api_course(values.id), payload).then(() => {
        showSuccessMessage(translations.successMessages.editCourse);
        this.handleGetCourses();
      });
    }

    return http.post(API_COURSES, payload).then(() => {
      showSuccessMessage(translations.successMessages.addUdemy);
      const { AddNewCourseInLibrary } = EVENT_ACTION_TYPES;
      tagManagerDataLayer(
        AddNewCourseInLibrary.action,
        AddNewCourseInLibrary.name,
        values.title
      );
      this.handleGetCourses();
    });
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  onImportRecommendedCourses = () => {
    const { setCourses } = this.props;

    this.setState({ isLoadingImport: true });
    return http
      .get(API_IMPORT_COURSES, {
        params: { ...COURSES_DEFAULT_PARAMS, ...DEFAULT_ORDERING },
      })
      .then(({ data }) => {
        setCourses(data);
        this.setState({ isLoadingImport: false });
      });
  };

  render() {
    const {
      auth,
      translations,
      classes,
      organizationSettings,
      grantedPermissions,
      courses,
      dialogs,
      setDialogVisibility,
      allAttributes,
      categories,
      pageQuickFilters,
      getCourseEnrolledUsers,
      getOrganizationSettings,
    } = this.props;
    const {
      isInitialLoad,
      isLoading,
      isReadMore,
      isManageCourseOpened,
      initialCourseData,
      source,
      isEditCourse,
      availableCourses,
      filters,
      search,
      isLoadingImport,
    } = this.state;
    const { canAddCourse, canManageCourse } = PERMISSIONS;
    const { forceDeleteCourseDialogOpened } = dialogs;
    const courseLevels = getCourseLevels(translations.levels);
    const filterIsActive = hasSelectedFilters(filters);
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isUser = checkUserRole(auth.role, ROLES.USER);
    const noResultsMessage = isUser ? '' : translations.noResultsMessage;

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.header}>
            <div className={classes.description}>
              <ReadLessMore
                translations={translations.readLessMore}
                isReadMore={isReadMore}
                toggleReadMore={this.handleToggleReadMore}
              >
                <Typography variant="body2">
                  {translations.pageDescriptionOne}
                </Typography>
                <Typography variant="body2">
                  {translations.pageDescriptionTwo}
                </Typography>
                <Typography variant="body2">
                  {translations.pageDescriptionThree}
                </Typography>
              </ReadLessMore>
            </div>
            <div className={classes.filtersAndActionsWrapper}>
              <Filters
                translations={translations.filters}
                selectedFilters={filters}
                filters={getPageFilters(
                  translations,
                  courseLevels.slice(1),
                  allAttributes,
                  categories,
                  pageQuickFilters
                )}
                onApplyFilters={this.handleApplyFilters}
              />
              <div className={classes.actions}>
                <Search
                  className={classes.search}
                  placeholder={translations.search}
                  value={search}
                  onChange={this.onSearch}
                />
                <VisibleForPermission
                  permission={canAddCourse}
                  permissions={grantedPermissions}
                >
                  <CustomButton
                    className={classes.addButton}
                    type="addRoundedNew"
                    onClick={validateFreemiumAction(
                      this.onAddCourse(),
                      FREEMIUM_LIMIT_TYPES.COURSES,
                      organizationSettings,
                      setDialogVisibility,
                      getOrganizationSettings
                    )}
                  >
                    {translations.addCourse}
                  </CustomButton>
                </VisibleForPermission>
              </div>
            </div>
          </div>
          {isArray(courses.results) && !isArrayEmpty(courses.results) && (
            <div className={classes.courses}>
              <VisibleForPermission
                permission={canAddCourse}
                permissions={grantedPermissions}
              >
                <PlaceholderButton
                  className={classes.placeholderButton}
                  label={translations.addCourse}
                  onAdd={validateFreemiumAction(
                    this.onAddCourse(),
                    FREEMIUM_LIMIT_TYPES.COURSES,
                    organizationSettings,
                    setDialogVisibility,
                    getOrganizationSettings
                  )}
                />
              </VisibleForPermission>
              {prepareCourses(courses.results).map(course => (
                <CourseCard
                  key={`course_item_${course.id}`}
                  translations={translations.courseLabels}
                  course={course}
                  levels={courseLevels.slice(1)}
                  actions={getCourseActions(
                    translations.courseActions,
                    this.onViewDetails(course.id),
                    this.onAddCourse(course),
                    this.onDeleteCourseDialog(course.id),
                    isPermissionGranted(canManageCourse, grantedPermissions)
                  )}
                  getCourseEnrolledUsers={() =>
                    getCourseEnrolledUsers(course.id)
                  }
                  onCardClick={this.onViewDetails(course.id)}
                  hasEnrolledUsersCount
                  hasCourseUrl
                />
              ))}
            </div>
          )}
          {!isLoading &&
            isArray(courses.results) &&
            isArrayEmpty(courses.results) && (
              <NotificationCard
                title={
                  search || filterIsActive ? '' : translations.noResultsTitle
                }
                content={
                  search || filterIsActive
                    ? translations.noSearchResultsMessage
                    : noResultsMessage
                }
                actionButtons={
                  isAdmin && !search && !filterIsActive
                    ? [
                        {
                          label: translations.addRecommendedLabel,
                          type: 'rounded',
                          startIcon: <PlusIconWhite />,
                          onButtonClick: this.onImportRecommendedCourses,
                        },
                      ]
                    : []
                }
                isLoading={isLoadingImport}
                loadingText={translations.loadingImportText}
              />
            )}
          <CustomFormDrawer
            translations={translations.addCourseForm}
            initialData={initialCourseData}
            isOpened={isManageCourseOpened}
            isInitialValid={isEditCourse}
            fields={getCourseFields(
              classes,
              translations,
              source,
              isEditCourse,
              this.onChangeCourseSource,
              this.onUdemySearch,
              this.onCreateTag
            )}
            allAttributes={allAttributes}
            categories={categories}
            courses={availableCourses}
            levels={courseLevels}
            onClose={this.onReset}
            onSave={this.onSaveCourse}
            onDelete={this.onDeleteCourseDialog(initialCourseData.id)}
            hasCancelButton
          />
          <AlertDialog
            translations={translations.deleteCourseDialog}
            isOpened={forceDeleteCourseDialogOpened}
            onClose={() => {
              this.setState({ courseId: null }, () => {
                setDialogVisibility({
                  dialogName: 'forceDeleteCourseDialog',
                  opened: false,
                });
              });
            }}
            onConfirm={this.onDeleteCourse}
            isWarning
          />
        </div>
      )
    );
  }
}

CoursesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  allAttributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  courses: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dialogs: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  getAttributesWithQuestions: PropTypes.func.isRequired,
  clearCourses: PropTypes.func.isRequired,
  clearAttributesWithQuestions: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default withStyles(styles)(CoursesPage);
