import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import DraggableScroll from '../../draggableScroll';
import CustomButton from '../../customButton';
import { ReactComponent as SubmittedIcon } from '../../../../assets/icons/check-circle-outline-green.svg';

const useStyles = makeStyles(
  ({ palette: { primary }, breakpoints, spacing }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      [breakpoints.up('sm')]: {
        height: 'auto',
        maxHeight: '100%',
      },
    },
    header: {
      overflow: 'hidden',
      [breakpoints.up('sm')]: {
        height: 'auto',
        maxHeight: '100%',
      },
    },
    welcomeMessage: {
      fontSize: 20,
      lineHeight: '24px',
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '28px',
        marginBottom: spacing(6),
      },
    },
    description: {
      fontSize: 14,
      lineHeight: '18px',
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '20px',
        marginBottom: spacing(6),
      },
    },
    surveyInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    review: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
      [breakpoints.up('sm')]: {
        marginBottom: spacing(4),
      },
    },
    reviewName: {
      fontSize: 16,
      lineHeight: '20px',
      wordBreak: 'break-word',
      [breakpoints.up('sm')]: {
        fontSize: 18,
      },
    },
    completionTimeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    submitted: {
      marginLeft: 'auto',
      width: 21,
      height: 21,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      paddingTop: 16,
      marginBottom: 16,
      flexShrink: 0,
      [breakpoints.up('sm')]: {
        paddingTop: 40,
        marginBottom: 0,
      },
    },
    button: {
      border: `1px solid ${primary.blue2}`,
      padding: spacing(1, 2),
      '& span': {
        fontFamily: 'ProximaNova-Bold',
        fontSize: 12,
        lineHeight: '24px',
        letterSpacing: 'normal',
      },
      [breakpoints.up('sm')]: {
        padding: spacing(2, 4),
        '& span': {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    },
    draggableScroll: {
      maxHeight: 'calc(100% - 36px)',
      [breakpoints.up('sm')]: {
        maxHeight: 'calc(100% - 52px)',
      },
    },
  })
);

const SurveyWelcomeScreen = ({ translations, survey, onGetStarted }) => {
  const classes = useStyles();

  const totalReviewsCount =
    survey.totalReviews === 1
      ? translations.oneGroup
      : `${survey.totalReviews} ${translations.groups}`;

  const handleGetStarted = () => onGetStarted();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.welcomeMessage} variant="h3">
          {translations.welcomeMessage}
        </Typography>
        <DraggableScroll
          className={classes.draggableScroll}
          showDesktopScrollbars
        >
          <div>
            <Typography className={classes.description}>
              {` ${translations.numberOfSurveys.replace(
                '[NUMBER_OF_SURVEYS]',
                totalReviewsCount
              )}`}
              {` ${translations.numberOfQuestions.replace(
                '[NUMBER_OF_QUESTIONS]',
                survey.totalQuestions
              )}`}
            </Typography>
            <div className={classes.surveyInfo}>
              {survey.attributes.map(review => (
                <div
                  key={`review_item_${review.id}`}
                  className={classes.review}
                >
                  <Typography className={classes.reviewName} variant="h5">
                    {review.name}
                  </Typography>
                  <div className={classes.completionTimeWrapper}>
                    {!review.active && (
                      <SubmittedIcon className={classes.submitted} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DraggableScroll>
      </div>
      <div className={classes.footer}>
        <CustomButton
          type="withTextDarkRounded"
          className={classes.button}
          onClick={handleGetStarted}
        >
          {translations.getStartedButtonLabel}
        </CustomButton>
      </div>
    </div>
  );
};

SurveyWelcomeScreen.propTypes = {
  translations: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  onGetStarted: PropTypes.func.isRequired,
};

export default SurveyWelcomeScreen;
