import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import SurveyInfo from '../surveyInfo';
import AttributeQuestion from '../../attributeQuestion';
import FeedbackForm from '../feedbackForm';
import CustomButton from '../../customButton';
import {
  isQuestionCompleted,
  hasErrors,
} from '../../../../utility/fillSurveyUtils';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  stepperWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  stepper: {
    flexGrow: 1,
  },
  step: {
    backgroundColor: primary.bluish7,
    borderRadius: 30,
    flexGrow: 1,
    flexShrink: 0,
    height: 6,
    padding: 0,
    marginRight: spacing(1),
    [breakpoints.up('sm')]: {
      marginRight: spacing(1.5),
      height: 8,
    },
  },
  completedStep: {
    backgroundColor: primary.bluish1,
  },
  stepperLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    width: 25,
  },
  stepperLabel: {
    color: primary.bluish3,
    fontSize: 10,
    lineHeight: '12px',
    [breakpoints.up('sm')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
  stepContent: {
    height: '100%',
    overflow: 'hidden',
    marginTop: spacing(4),
    [breakpoints.up('sm')]: {
      marginTop: spacing(6),
      height: 'auto',
      maxHeight: '100%',
    },
  },
  stepFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing(4),
    marginBottom: spacing(4),
    flexShrink: 0,
    [breakpoints.up('sm')]: {
      paddingTop: spacing(10),
      marginBottom: 0,
    },
  },
  button: {
    padding: spacing(1, 2),
    '& span': {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 12,
      lineHeight: '24px',
      letterSpacing: 'normal',
      textTransform: 'capitalize',
    },
    [breakpoints.up('sm')]: {
      padding: spacing(2, 4),
      '& span': {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  },
  backButton: {
    padding: spacing(1, 0),
    marginLeft: spacing(-2.25),
    '& span': {
      marginLeft: 0,
    },
    [breakpoints.up('sm')]: {
      padding: spacing(2, 0),
    },
  },
  backIconWrapper: {
    marginRight: 0,
  },
  nextButton: {
    border: `1px solid ${primary.blue2}`,
    marginLeft: 'auto',
    '&:disabled': {
      backgroundColor: primary.blue5,
      border: `1px solid ${primary.blue5}`,
    },
  },
});

class SurveySteps extends PureComponent {
  renderStepContent = (
    subjects,
    currentQuestion,
    results,
    isLastStep,
    isVertical,
    hasNeutralAnswer
  ) => {
    const {
      translations,
      survey,
      review,
      isPreview,
      feedbackErrors,
      answerReplyErrors,
      onSetAnswerReplyErrors,
      onSetFeedbackErrors,
      onSetFeedbackComments,
      onAnswerSelect,
      onAnswerChange,
    } = this.props;
    const { isSingleSurvey } = survey;
    const { withTextReplyQuestions, hasAdditionalFeedback } = review;

    if (isLastStep && hasAdditionalFeedback) {
      return (
        <FeedbackForm
          translations={translations.feedbackForm}
          initialComments={review.comments}
          subjects={subjects}
          isSingleReview={isSingleSurvey}
          isPreview={isPreview}
          errors={feedbackErrors}
          setErrors={onSetFeedbackErrors}
          setComments={onSetFeedbackComments}
        />
      );
    }

    return (
      <AttributeQuestion
        translations={translations.answers}
        isVertical={isVertical}
        question={currentQuestion}
        results={results}
        answerReplyErrors={answerReplyErrors}
        isSingleReview={isSingleSurvey}
        hasNeutralAnswer={hasNeutralAnswer}
        withTextReplyQuestions={withTextReplyQuestions}
        isPreview={isPreview}
        subjects={subjects}
        onSetAnswerReplyErrors={onSetAnswerReplyErrors}
        onAnswerSelect={onAnswerSelect}
        onAnswerChange={onAnswerChange}
      />
    );
  };

  render() {
    const {
      classes,
      translations,
      steps,
      activeStep,
      survey,
      review,
      surveyStep,
      isPreview,
      feedbackErrors,
      answerReplyErrors,
      onSubmit,
      onStepChange,
      onGoBack,
    } = this.props;
    const {
      results,
      subjects,
      isVertical,
      hasNeutralAnswer,
      hasAdditionalFeedback,
    } = review;

    const currentQuestion = review.questions[activeStep];
    const numberOfSteps = steps.length;
    const isLastStep = activeStep + 1 === numberOfSteps;
    const isNextDisabled =
      ((hasAdditionalFeedback ? !isLastStep : !hasAdditionalFeedback) &&
        !isQuestionCompleted(results, subjects, currentQuestion?.id)) ||
      hasErrors(answerReplyErrors) ||
      hasErrors(feedbackErrors);

    return (
      <>
        <SurveyInfo survey={survey} surveyStep={surveyStep} />
        <div className={classes.stepperWrapper}>
          <Stepper
            classes={{ root: classes.stepper }}
            activeStep={activeStep}
            connector={null}
          >
            {steps.map((step, index) => {
              return (
                <Step
                  key={`step_item_${step.id}`}
                  classes={{
                    horizontal: classes.step,
                    completed: classes.completedStep,
                  }}
                  completed={activeStep >= index}
                />
              );
            })}
          </Stepper>
          <div className={classes.stepperLabelWrapper}>
            <Typography className={classes.stepperLabel} variant="caption">{`${
              activeStep + 1
            }/ `}</Typography>
            <Typography
              className={classes.stepperLabel}
              variant="caption"
            >{`${numberOfSteps}`}</Typography>
          </div>
        </div>
        <div className={classes.stepContent}>
          {this.renderStepContent(
            subjects,
            currentQuestion,
            results,
            isLastStep,
            isVertical,
            hasNeutralAnswer
          )}
        </div>
        <div className={classes.stepFooter}>
          {!(surveyStep === 0 && activeStep === 0) && (
            <CustomButton
              className={classNames(classes.button, classes.backButton)}
              customIconWrapper={classes.backIconWrapper}
              type="back"
              onClick={onGoBack}
            >
              {translations.backLabel}
            </CustomButton>
          )}
          <CustomButton
            className={classNames(classes.button, classes.nextButton)}
            type="withTextDarkRounded"
            disabled={isPreview ? false : isNextDisabled}
            onClick={isLastStep ? onSubmit : () => onStepChange(activeStep + 1)}
          >
            {isLastStep ? translations.submitLabel : translations.nextLabel}
          </CustomButton>
        </div>
      </>
    );
  }
}

SurveySteps.defaultProps = {
  isPreview: false,
  feedbackErrors: {},
  answerReplyErrors: {},
};

SurveySteps.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeStep: PropTypes.number.isRequired,
  survey: PropTypes.shape({}).isRequired,
  review: PropTypes.shape({}).isRequired,
  surveyStep: PropTypes.number.isRequired,
  feedbackErrors: PropTypes.shape({}),
  answerReplyErrors: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  onSetAnswerReplyErrors: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onAnswerSelect: PropTypes.func.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  onSetFeedbackErrors: PropTypes.func.isRequired,
  onSetFeedbackComments: PropTypes.func.isRequired,
};

export default withStyles(styles)(SurveySteps);
