import { NEUTRAL_ANSWER_WEIGHT } from 'components/shared/questionEntry/config';
import { isArray, isArrayEmpty, isObjectEmpty } from './helpers';
import { getPersonFullName } from './uiUtils';
import {
  SURVEY_TYPES,
  GROUPED_SURVEY_REPORTING_TYPES,
} from '../constants/survey';
import { getAnswerWeight } from './attribute';

export const getGroupedSurveyOptions = (labels, shouldIncludePulse = false) =>
  GROUPED_SURVEY_REPORTING_TYPES.reduce((acc, option, index, array) => {
    if (!shouldIncludePulse && index === array.length - 1) {
      return acc;
    }

    return [
      ...acc,
      {
        ...option,
        name: labels[option.translationKey],
      },
    ];
  }, []);

export const transformSurveyData = survey => ({
  ...survey,
  createdAt: survey.created_at,
  sentAt: survey.sent_at,
  createdFor: survey?.created_for?.map(user => ({
    ...user,
    name: getPersonFullName(user),
  })),
  isAnonymous: survey.is_anonymous,
  isOneTime: !survey.is_statistical,
  hasForceFinish: survey.force_finish_available,
  reviewers: survey?.reviewers?.map(user => ({
    ...user,
    name: getPersonFullName(user),
  })),
  ...(survey?._reviewers
    ? {
        rawReviewers: survey?._reviewers?.map(user => ({
          ...user,
          name: getPersonFullName(user),
        })),
      }
    : {}),
  scheduledFor: survey.scheduled_for,
  expireAt: survey.expire_at,
});

export const transformReviewsData = reviews =>
  reviews.map(review => ({
    ...review,
    averageScore: +review.average_score || null,
    finishedCount: review.number_of_finished,
    isTextReply: review.text_reply_questions,
  }));

export const prepareReviewParticipants = (surveyType, participants = {}) => {
  const isTeamReview = surveyType === SURVEY_TYPES.TEAM;

  if (isObjectEmpty(participants)) return [];

  return isTeamReview ? participants.subjects : participants.reviewers;
};

export const transformReviewData = (review, surveyType = null) => ({
  ...review,
  hasAdditionalFeedback: review.feedback_field,
  hasNeutralAnswer: review.with_neutral_answer,
  isTextReply: review.text_reply_questions,
  totalAnswers: review.with_neutral_answer
    ? review.answer_number + 1
    : review.answer_number,
  ...(surveyType
    ? {
        participants: prepareReviewParticipants(surveyType, review),
        answers: review.text_reply_questions ? review.replies : review.answers,
      }
    : {}),
  ...(review.average_score
    ? { averageScore: +review.average_score || null }
    : {}),
});

export const getReviewGridColumns = (
  labels,
  totalAnswers,
  hasNeutralAnswer
) => {
  return Array.from({ length: totalAnswers + 1 }, (_, i) => {
    if (i === 0) {
      return {
        name: labels.question,
      };
    }

    if (hasNeutralAnswer && i === totalAnswers) {
      return {
        name: labels.neutral.shorthand,
      };
    }

    return {
      name: `${labels.answerShorthand}${i}`,
    };
  });
};

export const prepareAnswerGridResults = (results, selectedUsers) => {
  if (isObjectEmpty(results) || isArrayEmpty(selectedUsers)) return results;

  return Object.keys(results).reduce((acc, questionKey) => {
    const answers = Object.keys(results[questionKey]).reduce(
      (answersAcc, answerKey) => {
        const answerResult = results[questionKey][answerKey].filter(user =>
          selectedUsers.includes(user.id)
        );

        if (!isArrayEmpty(answerResult)) {
          return { ...answersAcc, [answerKey]: answerResult };
        }

        return answersAcc;
      },
      {}
    );

    if (!isObjectEmpty(answers)) {
      return { ...acc, [questionKey]: answers };
    }

    return acc;
  }, {});
};

export const prepareTextReplyResults = (
  results,
  selectedUsers,
  shouldFilterResults
) => {
  if (
    isObjectEmpty(results) ||
    isArrayEmpty(selectedUsers) ||
    !shouldFilterResults
  )
    return results;

  return Object.keys(results).reduce((acc, questionKey) => {
    const questionResults = results[questionKey].filter(result =>
      selectedUsers.includes(result.user.id)
    );

    if (!isArrayEmpty(questionResults)) {
      return { ...acc, [questionKey]: questionResults };
    }

    return acc;
  }, {});
};

export const calculateAverage = (part, total) => {
  if (part && total) {
    return Math.round((part / total + Number.EPSILON) * 10) / 10;
  }
  return 0;
};

export const getReportChartResults = (
  results,
  questions,
  hasNeutralAnswer,
  selectedUsers = [],
  isAverageView = false
) => {
  if (
    isArrayEmpty(questions) ||
    isObjectEmpty(results) ||
    isArrayEmpty(selectedUsers)
  )
    return [];

  if (isAverageView) {
    const weightArray = selectedUsers.reduce((userResults, userId) => {
      const userResult = questions.reduce((acc, question, questionIndex) => {
        const selectedAnswerId = Object.keys(results[question.id]).find(
          answerId => {
            return results[question.id][answerId].some(
              userAnswer => userAnswer.id === userId
            );
          }
        );
        const answerIndex = question.answers.findIndex(
          answer => answer.id === +selectedAnswerId
        );
        const answerWeight =
          getAnswerWeight(question.answers[answerIndex]?.weight) || 0;
        const weightList = userResults[questionIndex]
          ? userResults[questionIndex].concat(answerWeight)
          : [answerWeight];

        return {
          ...acc,
          [questionIndex]:
            answerWeight === NEUTRAL_ANSWER_WEIGHT
              ? userResults[questionIndex]
              : weightList,
        };
      }, {});

      return { ...userResult };
    }, {});

    return [
      Object.values(weightArray).map(weights => {
        if (!weights) {
          return null;
        }

        const answerCount = weights?.length;
        const totalWeights = weights.reduce((acc, curr) => acc + curr, 0);

        return calculateAverage(totalWeights, answerCount);
      }),
    ];
  }

  return selectedUsers.reduce((userResults, userId) => {
    const userResult = questions.reduce((acc, question) => {
      const selectedAnswerId = Object.keys(results[question.id]).find(
        answerId => {
          return results[question.id][answerId].some(
            userAnswer => userAnswer.id === userId
          );
        }
      );
      const answerIndex = question.answers.findIndex(
        answer => answer.id === +selectedAnswerId
      );
      const isNeutralAnswer =
        hasNeutralAnswer && answerIndex === question.answers.length - 1;

      return [...acc, isNeutralAnswer ? 0 : answerIndex + 1];
    }, []);

    return [...userResults, userResult];
  }, []);
};

export const getToggleReportViewItems = (labels, isChartView = false) => [
  {
    value: false,
    label: isChartView ? labels.answers : labels.people,
  },
  {
    value: true,
    label: isChartView ? labels.score : labels.percentage,
  },
];

export const getResponseCountPerQuestion = questionResults => {
  return Object.keys(questionResults).reduce((totalScore, answerKey) => {
    const currentScore =
      questionResults[answerKey]?.length || questionResults[answerKey];
    return totalScore + currentScore;
  }, 0);
};

export const getQuestionAverage = (row, result, totalResponses) => {
  let totalResponsesTemp = totalResponses;
  const total = row.answers.reduce(
    (acc, curr) => {
      let answerCount = result[curr.id]?.length || result[curr.id] || 0;

      if (
        answerCount &&
        getAnswerWeight(curr.weight) === NEUTRAL_ANSWER_WEIGHT
      ) {
        const neutralAnswer = result[curr.id];
        const neutralAnswerCount = isArray(neutralAnswer)
          ? neutralAnswer?.length
          : neutralAnswer;

        answerCount = 0;
        totalResponsesTemp -= neutralAnswerCount;
      }
      return {
        ...acc,
        totalAnsweredWeight:
          acc.totalAnsweredWeight + answerCount * getAnswerWeight(curr.weight),
      };
    },
    { totalAnsweredWeight: 0 }
  );
  const { totalAnsweredWeight } = total;

  return calculateAverage(totalAnsweredWeight, totalResponsesTemp);
};

export const calculatePercentage = (part, total) => {
  if (part && total) {
    return Math.round(((part * 100) / total + Number.EPSILON) * 10) / 10;
  }

  return 0;
};
