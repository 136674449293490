import { useState, useRef, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import SurveyReportInfo from '../../shared/surveyReportInfo';
import ReviewReport from '../../shared/reviewReport';
import ReportDescription from '../../shared/reportDescription';
import NotificationCard from '../../shared/notificationCard';
import TextBoxWithTooltip from '../../shared/textBoxWithTooltip';
import SurveyIconInfoTooltip from '../../shared/surveyIconInfoTooltip';
import ActionButton from '../../shared/actionButton';
import SurveySummaryDrawerForm from '../../shared/surveySummaryDrawerForm';
import { surveySelector } from '../../../store/selectors/surveySelector';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import {
  getSurvey,
  getReviews,
  getReview,
  clearSurvey,
  clearReviews,
} from '../../../store/modules/survey';
import { getAllUsers } from '../../../store/modules/people';
import { useTranslations } from '../../../utility/useTranslations';
import { isArrayEmpty, isUserClickable } from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import {
  getTranslatedSurveyTypes,
  getSurveyStatuses,
} from '../../../utility/survey';
import { usePreviousValue } from '../../../utility/hooks';
import { showSuccessMessage } from '../../../utility/uiUtils';
import {
  api_review_score_per_reviewer,
  api_review_score_per_subject,
  api_survey_summary_report,
} from '../../../constants/apiRoutes';
import {
  SURVEY_TYPES,
  CREATE_SURVEY_FIELD_NAMES,
} from '../../../constants/survey';
import { SURVEY_STATUS_TYPES } from '../../../constants/statuses';
import { sticky } from '../../../constants/helperCssRules';
import { APP_PAGES, PARAMS } from '../../../constants/pages';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import { hasResultsCheck } from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  title: {
    maxWidth: 'calc(100% - 260px)',
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 38px)',
  },
  anonymous: {
    display: 'block',
  },
  infoIcon: {
    flexShrink: 0,
    '& svg': {
      marginRight: 0,
    },
  },
  sharedIcon: {
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  divider: {
    paddingTop: spacing(8),
    ...sticky(primary.white, 105),
  },
  description: {
    marginTop: spacing(6),
    marginBottom: spacing(2),
  },
  reportButton: {
    marginLeft: spacing(2),
    width: 30,
    height: 30,
    '& svg': {
      width: 16,
    },
  },
}));

const SurveyReportPage = ({
  navigate,
  dispatch,
  organizationSettings,
  auth,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SURVEY_REPORT);
  const reviewRef = useRef({});
  const pageScrollRef = useRef(null);

  const { surveyId } = useParams();
  const { survey, reviews } = useSelector(surveySelector);
  const { allUsers } = useSelector(peopleSelector);

  const [isLoading, setIsLoading] = useState(true);
  const [reviewId, setReviewId] = useState(null);
  const [textReplyQuestionId, setTextReplyQuestionId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isSummaryDrawerOpened, setIsSummaryDrawerOpened] = useState(false);

  const previousReviewId = usePreviousValue(reviewId);

  const { title, isAnonymous, isOneTime, type, createdFor, reviewers } = survey;
  const isTeamReview = type === SURVEY_TYPES.TEAM;
  const statuses = getSurveyStatuses(translations.statuses);
  const types = getTranslatedSurveyTypes(translations);
  const hasResults = useMemo(() => hasResultsCheck(reviews), [reviews]);
  const shouldHideReport = isArrayEmpty(createdFor) || isArrayEmpty(reviewers);
  const defaultPageOffset = 137; // Header height + divider height
  const defaultReviewOffset = 77; // Height of collapsed review

  const goToProfilePage = userId => navigate(`/people/${userId}`);

  const hadleReviewScroll = useCallback(() => {
    if (previousReviewId !== reviewId) {
      const prevReview =
        reviewRef?.current?.[previousReviewId]?.getBoundingClientRect();
      const prevReviewOffset =
        reviewRef?.current?.[previousReviewId]?.offsetTop;
      const currReviewOffset = reviewRef?.current?.[reviewId]?.offsetTop;

      if (prevReview && prevReviewOffset < currReviewOffset) {
        setTimeout(() => {
          pageScrollRef?.current?.scrollTo({
            top:
              currReviewOffset -
              prevReview.height -
              defaultPageOffset +
              defaultReviewOffset,
            behavior: 'smooth',
          });
        }, 300);
      } else {
        setTimeout(() => {
          pageScrollRef?.current?.scrollTo({
            top: currReviewOffset - defaultPageOffset,
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  }, [previousReviewId, reviewId]);

  const handleViewReport = useCallback(
    attributeId => async () => {
      if (attributeId !== reviewId) {
        if (
          reviewRef?.current?.[reviewId] &&
          reviewRef?.current?.[reviewId]?.offsetTop <
            reviewRef?.current?.[attributeId]?.offsetTop
        ) {
          pageScrollRef?.current?.scrollTo({
            top: 0,
          });
        }
        await getReview(dispatch, surveyId, attributeId);
        setReviewId(attributeId);
        setTextReplyQuestionId(null);
      } else {
        setReviewId(null);
        setTextReplyQuestionId(null);
      }
    },
    [surveyId, reviewId, dispatch]
  );

  const getSurveyData = async () => {
    const { VisitSDP } = EVENT_ACTION_TYPES;

    const [surveyData, reviewsData] = await Promise.all([
      getSurvey(dispatch, surveyId),
      getReviews(dispatch, surveyId),
      getAllUsers(dispatch, { [PARAMS.EXCLUDE]: auth.id }),
    ]);
    const [review] = reviewsData;

    const isDraft =
      surveyData[CREATE_SURVEY_FIELD_NAMES.STATUS] ===
      SURVEY_STATUS_TYPES.DRAFT;

    if (isDraft) {
      return navigate(`/not-found`, { replace: true });
    }

    handleViewReport(review.id)();
    setIsLoading(false);
    tagManagerDataLayer(VisitSDP.action, VisitSDP.name);
  };

  const handleViewTextReply = questionId => e => {
    e.stopPropagation();

    setTextReplyQuestionId(prevTextReplyQuestionId =>
      prevTextReplyQuestionId !== questionId ? questionId : null
    );
  };

  const handleViewIndividualScores = attributeId => () =>
    survey.type === SURVEY_TYPES.TEAM
      ? http.get(api_review_score_per_subject(survey.id, attributeId))
      : http.get(api_review_score_per_reviewer(survey.id, attributeId));

  const onUserSelect = useCallback(
    currentReviewId => (selectedUserId, isSelected) => {
      if (selectedUsers[currentReviewId]) {
        if (isSelected) {
          return setSelectedUsers(prevSelectedUsers => ({
            ...prevSelectedUsers,
            [currentReviewId]: prevSelectedUsers[currentReviewId].filter(
              userId => userId !== selectedUserId
            ),
          }));
        }

        return setSelectedUsers(prevSelectedUsers => ({
          ...prevSelectedUsers,
          [currentReviewId]: [
            ...prevSelectedUsers[currentReviewId],
            selectedUserId,
          ],
        }));
      }

      setSelectedUsers(prevSelectedUsers => ({
        ...prevSelectedUsers,
        [currentReviewId]: [selectedUserId],
      }));
    },
    [selectedUsers]
  );

  const onClearSelectedUsers = useCallback(() => {
    setSelectedUsers(prevSelectedUsers => {
      return Object.keys(prevSelectedUsers).reduce(
        (acc, reviewKey) => ({
          ...acc,
          ...(+reviewKey !== reviewId
            ? { [reviewKey]: [...prevSelectedUsers[reviewKey]] }
            : {}),
        }),
        {}
      );
    });
  }, [reviewId]);

  useEffect(() => {
    getSurveyData();

    return () => {
      dispatch(clearSurvey());
      dispatch(clearReviews());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenSummaryDrawer = () => setIsSummaryDrawerOpened(true);

  const onCloseSummaryDrawer = () => setIsSummaryDrawerOpened(false);

  const onSaveSummary = async values => {
    const summaryData = {
      title: values.title,
      ...(values.description ? { description: values.description } : {}),
      report_data: values.report_data,
      share_to: values.share_to,
      anon_reviewers: values.anon_reviewers,
    };

    await http.post(api_survey_summary_report(surveyId), summaryData);
    showSuccessMessage(translations.surveySummaryForm.successMessage);
  };

  const renderReportHeader = () => {
    return (
      <div className={classes.title}>
        {!isLoading && (
          <div className={classes.titleWrapper}>
            <div className={classes.titleContent}>
              <TextBoxWithTooltip variant="h1" text={title} />
              {isOneTime && (
                <SurveyIconInfoTooltip
                  className={classes.infoIcon}
                  tooltipText={translations.oneTimeSurvey}
                  isOneTimeSurvey
                />
              )}
              {isAnonymous && (
                <SurveyIconInfoTooltip
                  className={classNames(classes.anonymous, classes.infoIcon)}
                  tooltipText={translations.anonymousSurvey}
                />
              )}
            </div>
            <div className={classes.reportButton}>
              <ActionButton
                type={ACTION_BUTTON_TYPES.REPORT_CREATE}
                tooltipText={translations.createSummaryReport}
                disabledTooltipText={translations.createSummaryReportDisabled}
                isDisabled={
                  survey[CREATE_SURVEY_FIELD_NAMES.STATUS] !==
                    SURVEY_STATUS_TYPES.COMPLETED || !hasResults
                }
                onClickHandler={onOpenSummaryDrawer}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    !isLoading && (
      <PageContainer
        {...rest}
        translations={translations}
        headerProps={{
          renderTitle: renderReportHeader,
        }}
        auth={auth}
        organizationSettings={organizationSettings}
        navigate={navigate}
        pageScrollRef={pageScrollRef}
        shouldPassProps={false}
        isFullWidthContent
      >
        <div>
          <div className={classes.divider} />
          {!shouldHideReport && (
            <>
              <SurveyReportInfo
                labels={translations.info}
                survey={survey}
                statuses={statuses}
                types={types}
                isTeamReview={isTeamReview}
                isUserClickable={isUserClickable(
                  auth,
                  organizationSettings.global_see_himself
                )}
                onGoToPersonProfile={goToProfilePage}
              />
              <ReportDescription
                className={classes.description}
                translations={translations.surveyTypesDescription}
                createdFor={createdFor}
                reviewers={reviewers}
                surveyType={type}
                isUserClickable={isUserClickable(
                  auth,
                  organizationSettings.global_see_himself
                )}
                onGoToPersonProfile={goToProfilePage}
                canGoToReviewerProfile
              />
              {reviews.map(review => (
                <div
                  key={`review_${review.id}`}
                  ref={el => {
                    reviewRef.current[review.id] = el;
                  }}
                >
                  <ReviewReport
                    translations={translations.review}
                    review={review}
                    selectedUsers={selectedUsers[review.id]}
                    reviewersCount={survey?.reviewers?.length}
                    isExpanded={reviewId === review.id}
                    isTeamReview={isTeamReview}
                    isReadOnlyFeedback={survey.isAnonymous}
                    textReplyQuestionId={textReplyQuestionId}
                    isUserClickable={isUserClickable(
                      auth,
                      organizationSettings.global_see_himself
                    )}
                    shouldDisableIndividualScores={survey.isAnonymous}
                    onUserSelect={onUserSelect(review.id)}
                    onClearSelectedUsers={onClearSelectedUsers}
                    onViewIndividualScores={handleViewIndividualScores(
                      review.id
                    )}
                    onGoToPersonProfile={goToProfilePage}
                    onViewTextReply={handleViewTextReply}
                    onViewReport={handleViewReport(review.id)}
                    onScrollInToView={hadleReviewScroll}
                    hasCompletedCount
                  />
                </div>
              ))}
            </>
          )}
          <NotificationCard
            title={translations.noReport}
            shouldFade={shouldHideReport}
          />
          <SurveySummaryDrawerForm
            translations={translations.surveySummaryForm}
            isOpened={isSummaryDrawerOpened}
            survey={survey}
            reviews={reviews}
            allUsers={allUsers}
            selectedUsers={selectedUsers}
            onClose={onCloseSummaryDrawer}
            onSave={onSaveSummary}
          />
        </div>
      </PageContainer>
    )
  );
};

SurveyReportPage.propTypes = {
  organizationSettings: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(SurveyReportPage);
