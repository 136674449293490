import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles, Typography } from '@material-ui/core';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ActionButton from '../actionButton';
import AttributeScore from './attributeScore';
import ColorBox from '../colorBox';
import ConditionalTooltip from '../conditionalTooltip';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(3, 4),
    minHeight: 52,
    transition: 'background-color .3s ease',
  },
  rootClickable: {
    cursor: 'pointer',
    '&:hover': {
      borderRadius: 4,
      backgroundColor: primary.bluish9,
    },
  },
  rootOpenEnded: {
    padding: spacing(3, 4, 3, 0),
  },
  title: {
    position: 'relative',
    zIndex: 1,
    marginRight: spacing(2),
  },
  collapseButton: {
    backgroundColor: ({ color }) => color || primary.bluish1,
    borderRadius: 4,
    alignSelf: 'flex-start',
    width: 52,
    height: 52,
    marginLeft: spacing(4),
    '&:hover': {
      backgroundColor: ({ color }) => color || primary.bluish1,
    },
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
    width: 14,
    height: 14,
    '& path': {
      fill: primary.white,
    },
  },
  expandIconOpened: {
    transform: 'rotate(180deg)',
  },
  completed: {
    position: 'relative',
    zIndex: 10,
    flexShrink: 0,
    marginLeft: spacing(2),
  },
  scoreWrapper: {
    position: 'relative',
    zIndex: 10,
    flexShrink: 0,
    width: 40,
  },
  scorePercentage: {
    backgroundColor: ({ color }) => color || primary.bluish1,
    borderRadius: 4,
    position: 'absolute',
    left: 0,
    height: 52,
    width: ({ averageScore }) => `${averageScore * 10}%`,
    maxWidth: ({ hasCollapseButton }) =>
      hasCollapseButton ? 'calc(100% - 76px)' : '100%',
    transition: 'background-color .3s ease',
  },
  colorBox: {
    marginRight: spacing(2),
  },
}));

const AttributeInfoBar = ({
  className,
  translations,
  attribute,
  averageScore,
  reviewersCount,
  hasCollapseButton,
  isExpanded,
  isClickable,
  isOpenEndedAttribute,
  shouldShowScores,
  shouldDisableIndividualScores,
  hasCompletedCount,
  onViewIndividualScores,
  onClickHandler,
}) => {
  const {
    name,
    color,
    isTextReplyAttribute,
    text_reply_questions,
    finishedCount,
  } = attribute;
  const classes = useStyles({
    averageScore,
    color,
    hasCollapseButton,
  });

  return (
    <Fade in appear>
      <div
        className={classNames(
          classes.root,
          {
            [classes.rootClickable]: isClickable,
            [classes.rootOpenEnded]:
              isOpenEndedAttribute || !averageScore || !shouldShowScores,
          },
          className
        )}
        onClick={onClickHandler}
      >
        {(!averageScore || !shouldShowScores) && attribute?.color && (
          <ColorBox className={classes.colorBox} bgColor={attribute?.color} />
        )}
        <TextBoxWithTooltip
          className={classes.title}
          variant="h5"
          text={name}
        />
        {shouldShowScores && (
          <div className={classes.scoreWrapper}>
            <AttributeScore
              translations={translations}
              isTextReply={isTextReplyAttribute || text_reply_questions}
              averageScore={averageScore}
              onGetScores={onViewIndividualScores}
              shouldDisableIndividualScores={shouldDisableIndividualScores}
            />
          </div>
        )}
        {hasCompletedCount && (
          <ConditionalTooltip
            className={classes.completed}
            message={translations.completedCountTooltip}
            addTooltip
          >
            <Typography variant="h5">{`${finishedCount} / ${reviewersCount}`}</Typography>
          </ConditionalTooltip>
        )}
        <Fade in={hasCollapseButton} unmountOnExit>
          <div>
            <ActionButton
              className={classes.collapseButton}
              iconClass={classNames(classes.expandIcon, {
                [classes.expandIconOpened]: isExpanded,
              })}
              type={ACTION_BUTTON_TYPES.ARROW}
              size="small"
              onClickHandler={onClickHandler}
            />
          </div>
        </Fade>
        {shouldShowScores && <div className={classes.scorePercentage} />}
      </div>
    </Fade>
  );
};

AttributeInfoBar.defaultProps = {
  className: null,
  hasCollapseButton: false,
  isExpanded: false,
  averageScore: null,
  reviewersCount: 0,
  isClickable: false,
  isOpenEndedAttribute: false,
  shouldDisableIndividualScores: false,
  shouldShowScores: false,
  hasCompletedCount: false,
  onClickHandler: () => {},
  onViewIndividualScores: () => {},
};

AttributeInfoBar.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
  hasCollapseButton: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isClickable: PropTypes.bool,
  shouldShowScores: PropTypes.bool,
  shouldDisableIndividualScores: PropTypes.bool,
  isOpenEndedAttribute: PropTypes.bool,
  averageScore: PropTypes.number,
  reviewersCount: PropTypes.number,
  hasCompletedCount: PropTypes.bool,
  onViewIndividualScores: PropTypes.func,
  onClickHandler: PropTypes.func,
};

export default memo(AttributeInfoBar);
