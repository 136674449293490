import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import DraggableScroll from '../draggableScroll';
import Answers from '../answers';
import { usePreviousValue } from '../../../utility/hooks';
import { updateScrollPosition } from '../../../utility/fillSurveyUtils';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  questionTitle: {
    fontSize: 16,
    lineHeight: '20px',
    wordBreak: 'break-word',
    marginBottom: spacing(3),
    [breakpoints.up('sm')]: {
      fontSize: 24,
      lineHeight: '28px',
      marginBottom: spacing(6),
    },
  },
  questionTitleHorizontalSingle: {
    marginBottom: 0,
  },
  verticalWeightLabel: {
    fontSize: 12,
    fontFamily: 'ProximaNova-Bold',
    justifyContent: 'end',
    display: 'flex',
    marginBottom: spacing(6),
    marginRight: spacing(2),
  },
  scroll: {
    height: '100%',
  },
}));

const AttributeQuestion = ({
  className,
  translations,
  question,
  isVertical,
  isSingleReview,
  withTextReplyQuestions,
  isPreview,
  isAttributePreview,
  hasNeutralAnswer,
  results,
  answerReplyErrors,
  subjects,
  onSetAnswerReplyErrors,
  onAnswerSelect,
  onAnswerChange,
}) => {
  const classes = useStyles();
  const draggableScrollRef = useRef(null);
  const previousQuestion = usePreviousValue(question);

  useEffect(() => {
    if (previousQuestion?.id !== question?.id) {
      updateScrollPosition(draggableScrollRef.current);
    }
  }, [previousQuestion, question]);

  return (
    <DraggableScroll
      ref={el => {
        draggableScrollRef.current = el;
      }}
      className={classes.scroll}
      showDesktopScrollbars
    >
      <div className={classNames(classes.root, className)}>
        <Typography
          className={classNames(classes.questionTitle, {
            [classes.questionTitleHorizontalSingle]:
              !isVertical && isSingleReview,
          })}
          variant="h3"
        >
          {question.text}
        </Typography>
        {isVertical && isAttributePreview && !withTextReplyQuestions && (
          <div className={classes.verticalWeightLabel}>
            {translations.weight}
          </div>
        )}
        <Answers
          translations={translations}
          questionId={question.id}
          isVertical={isVertical}
          answers={question.answers}
          subjects={subjects}
          hasNeutralAnswer={hasNeutralAnswer}
          isSingleReview={isSingleReview}
          withTextReplyQuestions={withTextReplyQuestions}
          isPreview={isPreview}
          isAttributePreview={isAttributePreview}
          results={results}
          answerReplyErrors={answerReplyErrors}
          onSetAnswerReplyErrors={onSetAnswerReplyErrors}
          onSelect={onAnswerSelect}
          onChange={onAnswerChange}
        />
      </div>
    </DraggableScroll>
  );
};

AttributeQuestion.defaultProps = {
  className: null,
  subjects: [],
  isSingleReview: false,
  withTextReplyQuestions: false,
  hasNeutralAnswer: false,
  isPreview: false,
  isAttributePreview: false,
  results: {},
  answerReplyErrors: {},
  onSetAnswerReplyErrors: () => {},
  onAnswerSelect: () => {},
  onAnswerChange: () => {},
};

AttributeQuestion.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.shape({}).isRequired,
  isVertical: PropTypes.bool.isRequired,
  isSingleReview: PropTypes.bool,
  answerReplyErrors: PropTypes.shape({}),
  withTextReplyQuestions: PropTypes.bool,
  isPreview: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  hasNeutralAnswer: PropTypes.bool,
  question: PropTypes.shape({}).isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  results: PropTypes.shape({}),
  onSetAnswerReplyErrors: PropTypes.func,
  onAnswerSelect: PropTypes.func,
  onAnswerChange: PropTypes.func,
};

export default AttributeQuestion;
