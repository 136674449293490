import ColorBox from 'components/shared/colorBox';
import http from '../../../utility/http';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
} from '../../../utility/validation';
import {
  asyncDebounce,
  getObjectToNumberArray,
  isArrayEmpty,
  isValueInList,
  trimString,
} from '../../../utility/helpers';
import { filterTags } from '../../../utility/tagUtils';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { API_UNIQUE_POSITION } from '../../../constants/apiRoutes';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';
import { PARAMS } from '../../../constants/pages';
import { USER_INFO } from '../../../constants/people';

const { TEXT, SELECT } = FIELD_TYPES;

export const USERS_DEFAULT_PARAMS = {
  page: 1,
  ordering: 'first_name',
};

export const INITIAL_JOB_TITLE_DATA = {
  name: '',
  description: '',
  attributes: [],
  tags: [],
};

export const getJobTitleFields = (onCreateTag, classes) => [
  {
    name: 'name',
    type: TEXT,
    fieldWrapperClass: classes.name,
    required: true,
    validators: [
      {
        validator: isEmpty,
        type: 'required',
      },
      {
        validator: forbiddenCharacters,
        type: 'forbiddenCharacters',
      },
      {
        validator: fieldValue => validateLength(fieldValue, 0, 255),
        type: 'nameMaxLength',
      },
      {
        type: 'availableName',
        validator: asyncDebounce(jobTitle => {
          const params = {
            name: trimString(jobTitle),
          };
          return http
            .get(API_UNIQUE_POSITION, { params })
            .then(({ data }) => !data.exists);
        }, 300),
        async: true,
      },
    ],
  },
  {
    name: 'attributes',
    type: SELECT,
    fieldWrapperClass: classes.attributes,
    optionsKey: 'allAttributes',
    isMulti: true,
    isAttribute: true,
    hasColorBox: true,
    parser: {
      value: 'id',
      label: 'name',
    },
  },
  {
    name: 'tags',
    type: SELECT,
    fieldWrapperClass: classes.tags,
    optionsKey: 'categories',
    creatableOptionField: CREATABLE_TAG_FIELD,
    isCategorized: true,
    hasCategoryColorBox: true,
    isMulti: true,
    hasHash: true,
    isCreatable: true,
    isTag: true,
    parser: {
      value: 'id',
      label: 'name',
      categoryLabel: 'name',
      categoryValue: 'tags',
    },
    onCreateOption: onCreateTag,
  },
  {
    name: 'description',
    type: FIELD_TYPES.RICH_TEXT_EDITOR,
    fieldWrapperClass: classes.descriptionSection,
    validators: [
      {
        validator: fieldValue => validateLength(fieldValue, 0, 10000),
        type: 'descriptionMaxLength',
      },
    ],
  },
];

export const getPageFilters = (translations, categories, quickFilters = []) => {
  const { TAGS } = PARAMS;

  return [
    {
      id: TAGS,
      name: translations.filters.labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: categories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const prepareUsersForUpdate = (
  assignableUsers,
  assignTo,
  jobTitleUsers,
  jobTitleId
) => {
  const jobTitleUserIds = getObjectToNumberArray(jobTitleUsers);
  const assignableUsersIds = getObjectToNumberArray(assignableUsers);

  const removeJobTitleUsers = jobTitleUserIds
    .filter(
      user =>
        !isValueInList(assignTo, user) &&
        isValueInList(assignableUsersIds, user)
    )
    .map(user => {
      return {
        id: user,
        [USER_INFO.POSITION]: null,
      };
    });
  const assignJobTitleUsers = assignTo
    .filter(user => !isValueInList(jobTitleUsers, user))
    .map(user => {
      return {
        id: user,
        [USER_INFO.POSITION]: jobTitleId,
      };
    });

  return removeJobTitleUsers.concat(assignJobTitleUsers);
};
