import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { getAnswerWeight } from 'utility/attribute';
import { NEUTRAL_ANSWER_WEIGHT } from 'components/shared/questionEntry/config';
import SelectableUserList from '../../selectableUserList';
import { isArray, isArrayEmpty } from '../../../../utility/helpers';
import { shouldRenderSubject } from '../../../../utility/fillSurveyUtils';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      marginBottom: spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    rootAttributePreview: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    answerContent: {
      boxSizing: 'border-box',
      border: `1px solid ${primary.bluish6}`,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      userSelect: 'none',
      marginBottom: spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
      '&.selected': {
        backgroundColor: primary.blue6,
        '& $answerOrder': {
          backgroundColor: primary.blue1,
          border: 'none',
          color: primary.white,
        },
        '&$singleSelectDisabled': {
          backgroundColor: primary.bluish8,
          '& $answerOrder': {
            backgroundColor: primary.bluish7,
          },
        },
      },
    },
    answerWrapper: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'flex-start',
      padding: spacing(2),
      [breakpoints.up('sm')]: {
        padding: spacing(4),
      },
    },
    answerWrapperGroup: {
      padding: spacing(2, 2, 0, 2),
      [breakpoints.up('sm')]: {
        padding: spacing(4, 4, 0, 4),
      },
    },
    singleSelect: {
      cursor: 'pointer',
    },
    singleSelectDisabled: {
      cursor: 'default',
    },
    answerOrder: {
      boxSizing: 'border-box',
      border: `1px solid ${primary.bluish6}`,
      borderRadius: 4,
      fontSize: 12,
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      marginRight: spacing(1.5),
      height: 24,
      width: 24,
      [breakpoints.up('sm')]: {
        borderRadius: 8,
        fontSize: 16,
        lineHeight: '18px',
        height: 32,
        width: 32,
        marginRight: spacing(4),
      },
    },
    answerText: {
      flexGrow: 1,
      fontSize: 14,
      lineHeight: '16px',
      alignSelf: 'center',
      wordWrap: 'break-word',
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
    weightLabel: {
      width: '91.5%',
    },
    answerWeight: {
      marginBottom: spacing(2),
      fontSize: 12,
      fontFamily: 'ProximaNova-Regular',
    },
  })
);

const VerticalAnswer = ({
  neutralAnswerLabel,
  subjects,
  questionId,
  answer,
  selection,
  isDisabled,
  isSingleReview,
  isAttributePreview,
  onSelect,
  getSelectedAnswer,
}) => {
  const classes = useStyles();

  const [subject] = subjects;
  const isSelected = getSelectedAnswer(selection, answer.id, subject);
  const isGroupReview =
    !isSingleReview && isArray(subjects) && !isArrayEmpty(subjects);
  const answerWeight = getAnswerWeight(answer.weight);

  const handleSingleSelect = (answerId, user) => () => {
    if (isDisabled) return;

    onSelect(questionId, answerId, user);
  };

  const handleMultipleSelect = answerId => (user, shouldDeleteAnswer) => {
    if (!isDisabled) {
      onSelect(questionId, answerId, user, shouldDeleteAnswer);
    }
  };

  const isSubjectVisible = answerId => user =>
    shouldRenderSubject(questionId, answerId, selection, user);

  return (
    <div
      className={classNames({
        [classes.root]: !isAttributePreview,
        [classes.rootAttributePreview]: isAttributePreview,
      })}
    >
      <div
        className={classNames(classes.answerContent, {
          [classes.singleSelect]: isSingleReview,
          [classes.singleSelectDisabled]: isDisabled,
          [classes.weightLabel]: isAttributePreview,
          selected: isSingleReview && isSelected,
        })}
        onClick={
          isSingleReview && !isSelected
            ? handleSingleSelect(answer.id, subject)
            : undefined
        }
      >
        <div
          className={classNames(classes.answerWrapper, {
            [classes.answerWrapperGroup]: isGroupReview,
          })}
        >
          <Typography
            className={classes.answerOrder}
            variant="subtitle1"
            component="div"
          >
            {answer.order <= 9 ? `0${answer.order}` : answer.order}
          </Typography>
          <Typography className={classes.answerText} variant="subtitle1">
            {answerWeight === NEUTRAL_ANSWER_WEIGHT
              ? neutralAnswerLabel
              : answer.text}
          </Typography>
        </div>
        {isGroupReview && (
          <SelectableUserList
            questionId={questionId}
            users={subjects}
            selectionKey={answer.id}
            selection={selection}
            shouldRenderUser={isSubjectVisible(answer.id)}
            isDisabled={isDisabled}
            getSelectedUser={getSelectedAnswer}
            onSelect={handleMultipleSelect(answer.id)}
          />
        )}
      </div>
      {isAttributePreview && (
        <div className={classes.answerWeight}>
          {answerWeight !== NEUTRAL_ANSWER_WEIGHT
            ? getAnswerWeight(answer.weight)
            : ''}
        </div>
      )}
    </div>
  );
};

VerticalAnswer.defaultProps = {
  subjects: [],
  neutralAnswerLabel: '',
  isSingleReview: false,
  isDisabled: false,
  isAttributePreview: false,
  questionId: 0,
  selection: {},
  getSelectedAnswer: () => {},
  onSelect: () => {},
};

VerticalAnswer.propTypes = {
  answer: PropTypes.shape({}).isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  questionId: PropTypes.number,
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  neutralAnswerLabel: PropTypes.string,
  selection: PropTypes.shape({}),
  getSelectedAnswer: PropTypes.func,
  onSelect: PropTypes.func,
};

export default VerticalAnswer;
