import { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import ActionButton from '../../actionButton';
import AttributesPerformanceSubgroup from './attributesPerformanceSubgroup';
import { isObjectEmpty } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import { getAttributeGroups } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  main: {
    boxSizing: 'border-box',
    padding: spacing(6, 6, 8),
    width: '100%',
    position: 'relative',
  },
  subGroup: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  reportButton: {
    position: 'absolute',
    right: 34,
    top: 12,
  },
}));

const AttributesPerformance = ({
  className,
  translations,
  coreAttributes,
  nicheAttributes,
  hasSendSurveyButton,
  hasJobTitle,
  isSendSurveyDisabled,
  isSelf,
  hasCompareReportButtonCheck,
  onRedirectToCompareReport,
  onSendSurvey,
}) => {
  const classes = useStyles();

  const attributeGroups = getAttributeGroups(
    translations,
    hasJobTitle,
    coreAttributes,
    nicheAttributes
  );

  const hasReportButton = hasCompareReportButtonCheck(
    nicheAttributes.concat(coreAttributes)
  );

  const handleGoToCompareReport = useCallback(() => {
    onRedirectToCompareReport(isSelf);
  }, [isSelf, onRedirectToCompareReport]);

  const handleSendSurvey = useCallback(
    attributeId => () => {
      onSendSurvey(attributeId, isSelf);
    },
    [isSelf, onSendSurvey]
  );

  return (
    <div className={classNames(classes.main, className)}>
      <TransitionGroup>
        {attributeGroups.map((group, index) => {
          if (isObjectEmpty(group)) return null;

          return (
            <Fade
              key={`group_item_${index}`}
              className={classes.subGroup}
              in={!isObjectEmpty(group)}
              unmountOnExit
            >
              <div>
                <AttributesPerformanceSubgroup
                  translations={translations}
                  title={group.title}
                  attributes={group.attributes}
                  hasSendSurveyButton={hasSendSurveyButton}
                  isSendSurveyDisabled={isSendSurveyDisabled}
                  onSendSurvey={handleSendSurvey}
                />
              </div>
            </Fade>
          );
        })}
      </TransitionGroup>
      {hasReportButton && (
        <ActionButton
          className={classes.reportButton}
          type={ACTION_BUTTON_TYPES.PEOPLE_COMPARE}
          tooltipText={translations.compareReport}
          onClickHandler={handleGoToCompareReport}
        />
      )}
    </div>
  );
};

AttributesPerformance.defaultProps = {
  className: undefined,
  coreAttributes: [],
  nicheAttributes: [],
  isSendSurveyDisabled: false,
  isSelf: false,
};

AttributesPerformance.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  coreAttributes: PropTypes.array,
  nicheAttributes: PropTypes.array,
  hasSendSurveyButton: PropTypes.bool.isRequired,
  isSendSurveyDisabled: PropTypes.bool,
  hasJobTitle: PropTypes.bool.isRequired,
  isSelf: PropTypes.bool,
  onSendSurvey: PropTypes.func.isRequired,
  hasCompareReportButtonCheck: PropTypes.func.isRequired,
  onRedirectToCompareReport: PropTypes.func.isRequired,
};

export default memo(AttributesPerformance);
