import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomFormDrawer from '../customFormDrawer';
import { SURVEY_TYPES } from '../../../constants/survey';
import { SUMMARY_FORM_INITIAL_DATA, getSurveySummaryFields } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  root: {},
  summary: {
    '& .ql-container': {
      height: 376,
    },
    '& > div': {
      height: 445,
    },
  },
  subtitle: {
    marginBottom: spacing(3),
  },
  field: {
    maxWidth: 434,
  },
}));

const SurveySummaryDrawerForm = ({
  translations,
  isOpened,
  survey,
  reviews,
  allUsers,
  selectedUsers,
  onSave,
  onClose,
}) => {
  const classes = useStyles();
  const isSelfAssesment = survey.type === SURVEY_TYPES.SELF;

  return (
    <CustomFormDrawer
      customFormClass={classes.root}
      translations={translations}
      isOpened={isOpened}
      initialData={SUMMARY_FORM_INITIAL_DATA}
      fields={getSurveySummaryFields(
        classes,
        isSelfAssesment,
        survey.isAnonymous
      )}
      allUsers={allUsers}
      reviews={reviews}
      survey={survey}
      selectedUsers={selectedUsers}
      onClose={onClose}
      onSave={onSave}
      hasCancelButton
      isMiddleWidth
      hideDelete
    />
  );
};

SurveySummaryDrawerForm.defaultProps = {
  allUsers: [],
  selectedUsers: {},
};

SurveySummaryDrawerForm.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  survey: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})),
  selectedUsers: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SurveySummaryDrawerForm;
