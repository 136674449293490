import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, FormHelperText, makeStyles } from '@material-ui/core';
import AttributeInfoBar from '../attributeInfoBar';
import CustomCheckbox from '../customCheckbox';
import UsersFilter from '../reviewReport/usersFilter';
import http from '../../../utility/http';
import { customSearch } from '../../../utility/uiUtils';
import {
  isArrayEmpty,
  getItemById,
  isItemInList,
  removeObjectFromArray,
  trimString,
  replaceObjectInList,
  getItemIndex,
} from '../../../utility/helpers';
import { prepareReviewParticipants } from '../../../utility/reportUtils';
import { api_review_participants_info } from '../../../constants/apiRoutes';
import { SURVEY_TYPES } from '../../../constants/survey';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    width: '100%',
  },
  review: {
    borderBottom: `1px solid ${primary.bluish7}`,
    width: '100%',
    position: 'relative',
  },
  info: {
    boxSizing: 'border-box',
    display: 'grid',
    borderRadius: 4,
    gridTemplateColumns: '22px minmax(30px,1fr)',
    gridColumnGap: 8,
    alignItems: 'center',
    paddingLeft: spacing(4),
    width: '100%',
    transition: 'background-color .3s ease',
  },
  infoCollapsable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: primary.bluish9,
    },
  },
  infoBar: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    boxSizing: 'border-box',
    padding: spacing(0, 0, 2, 4),
    width: '100%',
  },
  error: {
    position: 'absolute',
    bottom: 0,
    left: 16,
  },
}));

const ReviewsField = ({
  translations,
  survey,
  reviews,
  selectedUsers,
  value,
  errors,
  errorMessages,
  onChange,
}) => {
  const classes = useStyles();

  const [reviewId, setReviewId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [participants, setParticipants] = useState([]);

  const isAnonymousSurvey = !!survey?.isAnonymous;
  const isTeamReview = survey.type === SURVEY_TYPES.TEAM;
  const isSingleParticipant = participants?.length === 1;
  const users = isTeamReview ? 'subjects' : 'reviewers';

  const getParticipants = useCallback(
    async currentReviewId => {
      const { data } = await http.get(
        api_review_participants_info(survey.id, currentReviewId)
      );
      const formatedParticipants = prepareReviewParticipants(survey.type, data);
      const isSingle = formatedParticipants?.length === 1;
      const [participant] = formatedParticipants || [];

      setParticipants(formatedParticipants);
      setReviewId(currentReviewId);

      if (isAnonymousSurvey) {
        return formatedParticipants.reduce(
          (acc, fParticipant) => [...acc, fParticipant.id],
          []
        );
      }

      if (
        isSingle &&
        participant?.finished &&
        (!selectedUsers[currentReviewId] ||
          isArrayEmpty(selectedUsers[currentReviewId]))
      ) {
        return [participant.id];
      }

      return [];
    },
    [survey, selectedUsers, isAnonymousSurvey]
  );

  const handleCollapse = useCallback(
    (currentReviewId, isReviewSelected) => e => {
      e.stopPropagation();
      if (isAnonymousSurvey || !isReviewSelected) return;

      if (reviewId !== currentReviewId) {
        getParticipants(currentReviewId);
      } else {
        setReviewId(null);
      }
    },
    [reviewId, getParticipants, isAnonymousSurvey]
  );

  const handleSelectReview = useCallback(
    currentReviewId => async isSelected => {
      if (isSelected) {
        const previouslySelectedUsers = selectedUsers?.[currentReviewId] || [];
        const preselectedParticipants = await getParticipants(currentReviewId);
        const reviewers = !isArrayEmpty(preselectedParticipants)
          ? preselectedParticipants
          : previouslySelectedUsers;

        return onChange([
          ...value,
          {
            attribute: currentReviewId,
            ...(isAnonymousSurvey ? {} : { [users]: reviewers }),
          },
        ]);
      }
      if (currentReviewId === reviewId) {
        setReviewId(null);
      }
      return onChange(
        removeObjectFromArray(
          value,
          { attribute: currentReviewId },
          'attribute'
        )
      );
    },
    [
      value,
      users,
      reviewId,
      isAnonymousSurvey,
      selectedUsers,
      getParticipants,
      onChange,
    ]
  );

  const handleSearch = searchTerm => {
    setSearchValue(searchTerm);
  };

  const handleUserChange = useCallback(
    currentReviewId => (selectedUserId, isSelected) => {
      const reviewIndex = getItemIndex(value, currentReviewId, 'attribute');
      let review = { ...value[reviewIndex] };

      if (isSelected) {
        review = {
          ...review,
          [users]: review[users].filter(userId => userId !== selectedUserId),
        };
      } else {
        review = { ...review, [users]: [...review[users], selectedUserId] };
      }

      return onChange(replaceObjectInList(value, reviewIndex, review));
    },
    [value, users, onChange]
  );

  const handleAddAll = useCallback(
    currentReviewId => () => {
      const reviewIndex = getItemIndex(value, currentReviewId, 'attribute');

      if (value[reviewIndex]?.[users]?.length !== participants.length) {
        return onChange(
          replaceObjectInList(value, reviewIndex, {
            ...value[reviewIndex],
            [users]: participants.reduce(
              (acc, fParticipant) => [
                ...acc,
                ...(fParticipant?.finished ? [fParticipant.id] : []),
              ],
              []
            ),
          })
        );
      }
    },
    [value, users, participants, onChange]
  );

  const handleClearAll = useCallback(
    currentReviewId => () => {
      const reviewIndex = getItemIndex(value, currentReviewId, 'attribute');

      return onChange(
        replaceObjectInList(value, reviewIndex, {
          ...value[reviewIndex],
          [users]: [],
        })
      );
    },
    [value, users, onChange]
  );

  return (
    <div className={classes.root}>
      {reviews.map(review => {
        const { id, averageScore, finishedCount } = review;
        const isExpanded = reviewId === id;
        const isReviewSelected = isItemInList(
          value,
          { ...review, attribute: id },
          'attribute'
        );
        const reviewUsers = getItemById(value, id, 'attribute')?.[users];
        const hasError = !!errors?.[id];

        return (
          <div key={`review_${id}`} className={classes.review}>
            <div
              className={classNames(classes.info, {
                [classes.infoCollapsable]:
                  !isAnonymousSurvey && isReviewSelected,
              })}
            >
              <CustomCheckbox
                isChecked={isReviewSelected}
                onChange={handleSelectReview(id)}
                disabled={finishedCount === 0}
                isControlled
              />
              <AttributeInfoBar
                className={classes.infoBar}
                translations={translations}
                attribute={review}
                averageScore={averageScore}
                reviewersCount={survey?.reviewers?.length}
                isAnonymous={isAnonymousSurvey}
                isExpanded={isExpanded}
                onClickHandler={handleCollapse(id, isReviewSelected)}
                isClickable={!isAnonymousSurvey && isReviewSelected}
                hasCollapseButton={!isAnonymousSurvey && isReviewSelected}
                shouldDisableIndividualScores
                hasCompletedCount
              />
            </div>
            <Collapse
              className={classes.content}
              in={!isAnonymousSurvey && isExpanded}
              timeout="auto"
              unmountOnExit
            >
              <UsersFilter
                translations={translations.usersFilter}
                title={
                  isTeamReview
                    ? translations.usersFilter.titleReviewed
                    : translations.usersFilter.titleReviewers
                }
                searchTerm={searchValue}
                users={customSearch(
                  participants,
                  trimString(searchValue),
                  true
                )}
                selectedUsers={reviewUsers}
                isTeamReview={isTeamReview}
                hasFilter={!isAnonymousSurvey}
                hasSearch={!isSingleParticipant}
                isUserClearable={!isSingleParticipant}
                hasAddAll={!isAnonymousSurvey && !isSingleParticipant}
                onSelectAll={handleAddAll(id)}
                onClear={handleClearAll(id)}
                onSearch={handleSearch}
                onSelect={handleUserChange(id)}
                shouldEnableOnlyFinishedUsers
              />
            </Collapse>
            {hasError && (
              <FormHelperText className={classes.error} error={hasError}>
                {errorMessages?.[errors?.[id]]}
              </FormHelperText>
            )}
          </div>
        );
      })}
    </div>
  );
};

ReviewsField.defaultProps = {
  selectedUsers: {},
  errors: null,
};

ReviewsField.propTypes = {
  translations: PropTypes.object.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  survey: PropTypes.object.isRequired,
  selectedUsers: PropTypes.object,
  errors: PropTypes.object,
  errorMessages: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReviewsField;
