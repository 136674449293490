import { memo } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Typography, makeStyles } from '@material-ui/core';
import CustomCheckbox from '../customCheckbox';
import { isItemInList } from '../../../utility/helpers';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    marginBottom: spacing(4),
  },
  placements: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(1,1fr)',
  },
  placement: {
    marginBottom: spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  placementLabel: {
    width: 350,
  },
  helpText: {
    marginTop: spacing(2),
  },
}));

const FiltersSetup = ({
  label,
  subtext,
  helpText,
  allPlacements,
  placements,
  onChange,
}) => {
  const classes = useStyles();
  const handleRecommendedPlacementChange = checkedPlacement => isChecked => {
    if (isChecked) {
      return onChange([...placements, checkedPlacement]);
    }
    return onChange(
      placements.filter(placement => placement.id !== checkedPlacement.id)
    );
  };

  return (
    <div>
      <Typography variant="h4" component="p" className={classes.label}>
        {label}
      </Typography>
      <FormHelperText>
        <span>{subtext}</span>
      </FormHelperText>
      <div className={classes.placements}>
        {allPlacements.map(placement => (
          <CustomCheckbox
            key={`placement_item_${placement.id}`}
            className={classes.placement}
            labelTextClass={classes.placementLabel}
            labelText={placement.name}
            isChecked={isItemInList(placements || [], placement)}
            onChange={handleRecommendedPlacementChange(placement)}
            isControlled
          />
        ))}
      </div>
      {helpText && (
        <Typography
          variant="caption"
          component="p"
          className={classes.helpText}
        >
          {helpText}
        </Typography>
      )}
    </div>
  );
};

FiltersSetup.defaultProps = {
  placements: [],
  helpText: '',
};

FiltersSetup.propTypes = {
  label: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  allPlacements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default memo(FiltersSetup);
