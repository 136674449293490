import { useState, useCallback, useEffect, useRef } from 'react';
import { isArrayEmpty, isObjectEmpty } from './helpers';
import { parseDuplicateParameters } from './uiUtils';
import http from './http';
import { PARAMS } from '../constants/pages';
import { PEOPLE_DEFAULT_ORDERING } from '../constants/people';
import { ROLES } from '../constants/rolesAndPermissionList';
import { UserStatuses } from '../constants/statuses';
import { API_USERS_BASIC } from '../constants/apiRoutes';

export const usePreviousValue = value => {
  const currentValueRef = useRef(value);
  const previousValueRef = useRef();

  if (currentValueRef.current !== value) {
    previousValueRef.current = currentValueRef.current;
    currentValueRef.current = value;
  }

  return previousValueRef.current;
};

export const useCustomEffect = (
  callback,
  dependencies = [],
  shouldRunOnMount = true
) => {
  const isMountedRef = useRef(shouldRunOnMount);

  useEffect(() => {
    if (isMountedRef.current) return callback();

    isMountedRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export const useAvailableUsers = (params = {}, organizationUser = {}) => {
  const [availableUsers, setAvailableMUsers] = useState([]);

  const getAvailableUsers = useCallback(async () => {
    const { EXCLUDE_ROLE, EXCLUDE_STATUS } = PARAMS;
    const [, , , SUSPENDED] = UserStatuses;

    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [EXCLUDE_ROLE]: ROLES.ASSOCIATE,
        [EXCLUDE_STATUS]: SUSPENDED.id,
        ...params,
      },
      paramsSerializer: d => parseDuplicateParameters(d),
    });

    return setAvailableMUsers(data);
  }, [params]);

  const cleanup = useCallback(() => setAvailableMUsers([]), []);

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      getAvailableUsers();
    }

    return cleanup;
  }, [params, cleanup, getAvailableUsers]);

  return isArrayEmpty(availableUsers)
    ? availableUsers
    : [
        ...(!isObjectEmpty(organizationUser) ? [organizationUser] : []),
        ...availableUsers,
      ];
};

export const useResizeObserver = () => {
  const ref = useRef(null);
  const [rect, setRect] = useState(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        const boundingRect = ref.current.getBoundingClientRect();
        setRect(boundingRect);
      }
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  return [ref, rect];
};

export const useDraggableScrollPosition = (containerRef, itemsRef) => {
  const [scrollPosition, setScrollPosition] = useState({
    scrollLeft: 0,
    scrollTop: 0,
  });

  const itemsContainer = itemsRef?.current?.children?.[0];
  const containerRect = containerRef?.current?.getBoundingClientRect();
  const itemsRect = itemsContainer?.getBoundingClientRect();
  const activeScroll = {
    top: scrollPosition.scrollTop !== 0,
    left: scrollPosition.scrollLeft !== 0,
    right:
      Math.round(containerRect?.width || 0) + scrollPosition.scrollLeft <
      Math.round(itemsRect?.width || 0),
    bottom:
      Math.round(containerRect?.height || 0) + scrollPosition.scrollTop <
      Math.round(itemsRect?.height || 0),
  };

  const handleChangeScrollPosition = useCallback(
    () =>
      setScrollPosition({
        scrollLeft: containerRef?.current?.scrollLeft || 0,
        scrollTop: containerRef?.current?.scrollTop || 0,
      }),
    [containerRef]
  );

  const onScrollToRight = () => {
    containerRef?.current?.scrollTo({
      left: itemsRect?.width,
      behavior: 'smooth',
    });
  };

  const onScrollToBottom = () => {
    containerRef?.current?.scrollTo({
      top: itemsRect?.height,
      behavior: 'smooth',
    });
  };

  const onScrollToStart = () => {
    containerRef?.current?.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  return [
    activeScroll,
    handleChangeScrollPosition,
    onScrollToRight,
    onScrollToBottom,
    onScrollToStart,
  ];
};
