import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import ListWithTooltip from '../listWithTooltip';
import Tooltip from '../tooltip';
import CustomSwitch from '../customSwitch';
import { formatDate } from '../../../utility/dateUtils';
import { SURVEY_TYPES } from '../../../constants/survey';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      borderBottom: `1px solid ${primary.bluish8}`,
      display: 'grid',
      gridColumnGap: 12,
      gridAutoColumns: 'minmax(0, 1fr)',
      gridAutoFlow: 'column',
      paddingBottom: spacing(8),
      [breakpoints.up('xLg')]: {
        gridColumnGap: 24,
      },
    },
    labelWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(2),
    },
    label: {
      color: primary.bluish3,
      lineHeight: '20px',
    },
    labelTooltipIcon: {
      marginLeft: spacing(1),
      width: 14,
      height: 14,
      fill: primary.bluish3,
    },
    userMoreItems: {
      backgroundColor: 'transparent',
      fontSize: 16,
      lineHeight: '20px',
      padding: spacing(0, 2),
      marginTop: spacing(0.5),
      maxWidth: 'fit-content',
    },
  })
);

const SurveySummaryInfo = ({
  translations,
  report,
  canManage,
  isManagingView,
  hasSharedResults,
  onChangeView,
}) => {
  const classes = useStyles();
  const {
    creator,
    createdAt,
    isAnonymizedReport,
    survey,
    createdFor,
    reviewers,
  } = report;
  const createdForList = hasSharedResults ? createdFor : survey.createdFor;
  const reviewersList = hasSharedResults ? reviewers : survey.reviewers;
  const isSingleCreatedFor = createdForList.length === 1;
  const isSingleReviewer = reviewersList.length === 1;
  const isAnonymousSurvey = survey?.isAnonymous;
  const isSelfAssesmentSurvey = survey?.type === SURVEY_TYPES.SELF;

  const renderLabel = (label, helpTooltipText) => (
    <div className={classes.labelWrapper}>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
      {helpTooltipText && (
        <Tooltip
          customIconClass={classes.labelTooltipIcon}
          text={helpTooltipText}
        />
      )}
    </div>
  );

  const renderCreator = () => (
    <div>
      {renderLabel(translations.createdBy)}
      {creator ? (
        <UserAvatar variant="subtitle1" user={creator} small caption />
      ) : (
        <Typography variant="subtitle1">-</Typography>
      )}
    </div>
  );

  const renderDate = (label, date) => {
    return (
      <div>
        {renderLabel(label)}
        <Typography variant="subtitle1">{formatDate(date)}</Typography>
      </div>
    );
  };

  const renderReviewUsers = (label, users) => (
    <div>
      <ListWithTooltip
        labelClass={classes.label}
        moreItemsClass={classes.userMoreItems}
        label={label}
        items={users}
        visibleItemsCount={4}
        asyncListProps={{
          position: 'top',
          shouldDisablePortal: false,
          isDisabled: true,
        }}
        isUserList
        isReadOnly
      />
    </div>
  );

  const renderAnonymized = () => (
    <div>
      {renderLabel(
        translations.anonymized,
        translations.anonymizedInfoTooltip.default
      )}
      {canManage && isAnonymizedReport && (
        <CustomSwitch
          tooltipText={translations.toggleViewDataTooltip}
          isChecked={!isManagingView}
          onChange={onChangeView}
        />
      )}
      {(!canManage || !isAnonymizedReport) && (
        <Typography variant="subtitle1">
          {isAnonymizedReport ? translations.yes : translations.no}
        </Typography>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      {renderCreator()}
      {renderDate(translations.createdAt, createdAt)}
      {renderReviewUsers(
        isSingleCreatedFor
          ? translations.reviewedUser
          : translations.reviewedUsers,
        createdForList
      )}
      {renderReviewUsers(
        isSingleReviewer ? translations.reviewer : translations.reviewers,
        isAnonymousSurvey ? survey?._reviewers : reviewersList
      )}
      {!isAnonymousSurvey && !isSelfAssesmentSurvey && renderAnonymized()}
    </div>
  );
};

SurveySummaryInfo.propTypes = {
  translations: PropTypes.object.isRequired,
  report: PropTypes.shape({}).isRequired,
  hasSharedResults: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
  isManagingView: PropTypes.bool.isRequired,
  onChangeView: PropTypes.func.isRequired,
};

export default SurveySummaryInfo;
