import { useState } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import ActionPlanCard from './actionPlanCard';
import PreviewDescriptionDialog from '../previewDescriptionDialog';
import { canManagePerson, checkUserRole } from '../../../utility/helpers';
import { ROLES } from '../../../constants/rolesAndPermissionList';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
  },
  content: {
    position: 'relative',
    minHeight: 350,
  },
  actionPlanWrapper: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const ActionPlanCards = ({
  className,
  cardClass,
  translations,
  currentUser,
  actionPlans,
  statuses,
  user,
  isDisabled,
  isMiniCard,
  isUserProfileAccessible,
  onCardClick,
  onDelete,
  onEdit,
  onManageTasks,
  onDismissProposal,
  canSeeUserProfileCheck,
  goToUserProfilePage,
}) => {
  const classes = useStyles();

  const [selectedActionPlan, setSelectedActionPlan] = useState(null);

  const isAdmin = checkUserRole(currentUser.role, ROLES.ADMIN);
  const canAcceptProposal = canManagePerson(currentUser, user?.id, true);

  const handleCloseDescriptionDialog = () => setSelectedActionPlan(null);

  const handleOpenDescriptionDialog = actionPlan =>
    setSelectedActionPlan(actionPlan);

  return (
    <div className={classNames(classes.root, className)}>
      <TransitionGroup>
        {actionPlans.map(aPlan => {
          const canManage = isAdmin || currentUser.id === aPlan?.creator?.id;
          const isCreatedByMe = currentUser.id === aPlan?.creator?.id;
          const isCreatorAccessible =
            !!aPlan?.creator && isUserProfileAccessible(aPlan.creator.id);

          return (
            <Fade key={`action_plan_${aPlan.id}`} in unmountOnExit>
              <div className={classes.actionPlanWrapper}>
                <ActionPlanCard
                  goToUserProfilePage={goToUserProfilePage}
                  canSeeUserProfileCheck={canSeeUserProfileCheck}
                  className={cardClass}
                  translations={translations}
                  actionPlan={aPlan}
                  statuses={statuses}
                  isCreatorAccessible={isCreatorAccessible}
                  isDisabled={isDisabled}
                  canManage={canManage}
                  canAcceptProposal={canAcceptProposal}
                  isMiniCard={isMiniCard}
                  isCreatedByMe={isCreatedByMe}
                  onCardClick={onCardClick}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onManageTasks={onManageTasks}
                  onPreviewDescription={handleOpenDescriptionDialog}
                  onDismissProposal={onDismissProposal}
                />
              </div>
            </Fade>
          );
        })}
      </TransitionGroup>
      <PreviewDescriptionDialog
        isOpened={!!selectedActionPlan}
        title={translations.description}
        description={selectedActionPlan?.description}
        onClose={handleCloseDescriptionDialog}
      />
    </div>
  );
};

ActionPlanCards.defaultProps = {
  className: undefined,
  cardClass: undefined,
  actionPlans: [],
  user: null,
  isDisabled: false,
  isMiniCard: false,
  onManageTasks: () => {},
  onCardClick: () => {},
  onDelete: () => {},
  onEdit: () => {},
  onDismissProposal: () => {},
};

ActionPlanCards.propTypes = {
  className: PropTypes.string,
  cardClass: PropTypes.string,
  translations: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  user: PropTypes.object,
  isMiniCard: PropTypes.bool,
  actionPlans: PropTypes.arrayOf(PropTypes.shape({})),
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onCardClick: PropTypes.func,
  onManageTasks: PropTypes.func,
  onDismissProposal: PropTypes.func,
  isUserProfileAccessible: PropTypes.func.isRequired,
  canSeeUserProfileCheck: PropTypes.func.isRequired,
  goToUserProfilePage: PropTypes.func.isRequired,
};

export default ActionPlanCards;
