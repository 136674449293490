import { useMemo, useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Typography, makeStyles } from '@material-ui/core';
import { PLACEMENTS } from 'constants/tags';
import PageContainer from '../../shared/pageContainer';
import CustomButton from '../../shared/customButton';
import Search from '../../shared/search';
import NotificationCard from '../../shared/notificationCard';
import CustomFormDrawer from '../../shared/customFormDrawer';
import TagsCategory from '../../shared/tagsCategory';
import ColorBox from '../../shared/colorBox';
import TagHash from '../../shared/tagHash';
import AlertDialog from '../../shared/alertDialog';
import WarningDialog from '../../shared/warningDialog';
import ReorderItems from '../../shared/reorderItems';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import {
  getTagsCategories,
  clearTagsCategories,
  deleteTagCategory,
  addTagCategory,
  editTagCategory,
  manageMultipleTags,
  editTag,
  deleteTag,
  setCategories,
} from '../../../store/modules/tags';
import { useTranslations } from '../../../utility/useTranslations';
import {
  isPermissionGranted,
  isArrayEmpty,
  trimString,
  reorder,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import {
  showSuccessMessage,
  parseDuplicateParameters,
  parseQueryParams,
} from '../../../utility/uiUtils';
import http from '../../../utility/http';
import {
  prepareMultipleTagsForSave,
  filterTags,
} from '../../../utility/tagUtils';
import { PERMISSIONS } from '../../../constants/rolesAndPermissionList';
import {
  APP_PAGES,
  PAGE_WHITELISTED_PARAMS,
  PARAMS,
} from '../../../constants/pages';
import {
  API_TAGS_IN_USE,
  API_TAG_CATEGORIES_REORDER,
} from '../../../constants/apiRoutes';
import { sticky } from '../../../constants/helperCssRules';
import {
  getCategoryFields,
  getCategoryData,
  getTagData,
  getTagFields,
  formatPlacements,
} from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(8, 0, 6, 0),
    ...sticky(primary.white, 105),
  },
  description: {
    maxWidth: 500,
  },
  search: { width: 300 },
  addTagButton: {
    marginLeft: spacing(2),
  },
  addTagCategoryButton: {
    padding: spacing(1, 4, 1, 2),
    marginBottom: spacing(8),
    width: '100%',
    maxHeight: 32,
    '& span': {
      fontSize: 14,
    },
  },
  category: {
    marginBottom: spacing(4),
  },
  adornment: {
    marginRight: spacing(2),
  },
}));

const TagsPage = ({ grantedPermissions, navigate, ...rest }) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.TAGS);

  const [isLoading, setIsLoading] = useState(true);
  const [isManageCategoryOpened, setIsManageCategoryOpened] = useState(false);
  const [isDeleteCategoryOpened, setIsDeleteCategoryOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isManageTagsOpened, setIsManageTagsOpened] = useState(false);
  const [updatedTags, setUpdatedTags] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isDeleteTagOpened, setIsDeleteTagOpened] = useState(false);
  const [isDeleteTagsOpened, setIsDeleteTagsOpened] = useState(false);
  const [tagsInUse, setTagsInUse] = useState([]);

  const { categories } = useSelector(tagsSelector);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const params = useMemo(
    () => parseQueryParams(search, PAGE_WHITELISTED_PARAMS.TAGS),
    [search]
  );

  const filteredCategories = useMemo(
    () => filterTags(categories, params[PARAMS.SEARCH]?.toString() || ''),
    [categories, params]
  );
  const isEditCategory = !!selectedCategory;
  const hasTags = !isArrayEmpty(selectedCategory?.tags);
  const isActiveSearch = trimString(params[PARAMS.SEARCH]?.toString() || '');

  const canManageTags = isPermissionGranted(
    PERMISSIONS.canAddTags,
    grantedPermissions
  );

  const getCategories = useCallback(async () => {
    const query = parseDuplicateParameters(params);

    if (search !== `?${query}`) {
      navigate(`/tags${query ? `/?${query}` : ''}`, { replace: true });
    }

    try {
      await getTagsCategories(dispatch);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, params, search, navigate]);

  const getTagsInUse = async (tags = []) => {
    if (!isArrayEmpty(tags)) {
      const { data } = await http.get(API_TAGS_IN_USE, {
        params: { id: tags },
        paramsSerializer: d => {
          return parseDuplicateParameters(d);
        },
      });

      setTagsInUse(data);

      return !isArrayEmpty(data);
    }

    return false;
  };

  const onSearch = value => {
    const query = parseDuplicateParameters(
      trimString(value) ? { [PARAMS.SEARCH]: value } : {}
    );

    navigate(`/tags${query ? `/?${query}` : ''}`, { replace: true });
  };

  const onSaveUpdatedTags = async tags => {
    await manageMultipleTags(dispatch, tags, selectedCategory.id);
    showSuccessMessage(translations.successMessages.tag.saved);
  };

  useEffect(() => {
    getCategories();

    return () => dispatch(clearTagsCategories());
  }, [dispatch, getCategories]);

  const onPreviewCategory = categoryId =>
    navigate(`/tags/${categoryId}/people`);

  const onManageCategory = category => {
    if (category?.id) {
      setSelectedCategory(category);
    }

    setIsManageCategoryOpened(true);
  };

  const onCloseCategoryDrawer = () => {
    setSelectedCategory(null);
    setIsManageCategoryOpened(false);
  };

  const onSaveCategory = async category => {
    const { placements } = category;
    const formattedPlacement = isArrayEmpty(placements)
      ? []
      : getObjectToNumberArray(placements);
    const preparedData = { ...category, placements: formattedPlacement };

    if (selectedCategory) {
      await editTagCategory(dispatch, preparedData);
      showSuccessMessage(translations.successMessages.tagCategory.edited);
    } else {
      await addTagCategory(dispatch, preparedData);
      showSuccessMessage(translations.successMessages.tagCategory.created);
    }
  };

  const toggleDeleteCategory = () => {
    setIsDeleteCategoryOpened(prevOpened => !prevOpened);
  };

  const onDeleteCategory = async () => {
    toggleDeleteCategory();
    onCloseCategoryDrawer();
    await deleteTagCategory(dispatch, selectedCategory.id);
    showSuccessMessage(translations.categoryDeleteDialog.deleteSuccess);
  };

  const handleReorderCategory = result => {
    const sourceIndex = categories.findIndex(
      category => category.id === filteredCategories[result.source.index].id
    );
    const destinationIndex = categories.findIndex(
      category =>
        category.id === filteredCategories[result.destination.index].id
    );

    if (!result.destination || result.source.index === result.destination.index)
      return;

    const updatedOrder = reorder(categories, sourceIndex, destinationIndex);
    dispatch(setCategories(updatedOrder));

    return http.post(API_TAG_CATEGORIES_REORDER, {
      order: getObjectToNumberArray(updatedOrder),
    });
  };

  const onCloseTagDrawer = () => {
    setSelectedCategory(null);
    setSelectedTag(null);
    setIsManageTagsOpened(false);
  };

  const onManageTags = category => {
    const ctg = categories.find(currentCtg => currentCtg.id === category.id);
    setSelectedCategory(ctg);
    setIsManageTagsOpened(true);
  };

  const onEditTag = (tag, category) => {
    setSelectedCategory(category);
    setSelectedTag(tag);
    setIsManageTagsOpened(true);
  };

  const toggleDeleteTag = () => {
    setIsDeleteTagOpened(prevOpened => !prevOpened);
  };

  const handleDeleteTag = async () => {
    await getTagsInUse([selectedTag.id]);
    toggleDeleteTag();
  };

  const onDeleteTag = async () => {
    onCloseTagDrawer();
    toggleDeleteTag();
    setSelectedTag(null);
    await deleteTag(dispatch, selectedTag.id, selectedCategory.id);
    showSuccessMessage(translations.tagDeleteDialog.deleteSuccess);
  };

  const toggleDeleteTags = () => {
    setIsDeleteTagsOpened(prevOpened => !prevOpened);
  };

  const handleTagsDeleteReset = () => {
    toggleDeleteTags();
    setTagsInUse([]);
    setUpdatedTags(null);
  };

  const onTagsDelete = () => {
    onCloseTagDrawer();
    handleTagsDeleteReset();
    onSaveUpdatedTags(updatedTags);
  };

  const onSaveTags = async values => {
    if (selectedTag) {
      await editTag(dispatch, {
        ...values,
        currentCategoryId: selectedCategory.id,
      });
      showSuccessMessage(translations.successMessages.tag.edited);
    } else {
      const tags = prepareMultipleTagsForSave(values.tags);
      const hasUsedTags = await getTagsInUse(tags.delete);

      if (hasUsedTags) {
        setUpdatedTags(tags);
        toggleDeleteTags();
        return Promise.resolve({ shouldResetForm: false });
      }

      if (
        !isArrayEmpty(tags.create) ||
        !isArrayEmpty(tags.update) ||
        !isArrayEmpty(tags.delete)
      ) {
        return onSaveUpdatedTags(tags);
      }

      return Promise.resolve();
    }
  };

  const renderCategoryBox = ({ color }) => (
    <ColorBox className={classes.adornment} bgColor={color} isSmall />
  );

  const renderTagAdornment = ({ color }) => (
    <TagHash className={classes.adornment} bgColor={color} />
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      grantedPermissions={grantedPermissions}
      navigate={navigate}
      shouldPassProps={false}
    >
      <div>
        <div className={classes.header}>
          <Typography className={classes.description} variant="body2">
            {translations.description}
          </Typography>
          <Search
            className={classes.search}
            placeholder={translations.search}
            value={params[PARAMS.SEARCH]?.toString() || ''}
            onChange={onSearch}
          />
        </div>
        {!isLoading && (
          <>
            {canManageTags && (
              <CustomButton
                className={classes.addTagCategoryButton}
                type="addDarkRoundedOutlined"
                onClick={onManageCategory}
              >
                {translations.addCategoryButton}
              </CustomButton>
            )}
            {!isArrayEmpty(filteredCategories) ? (
              <ReorderItems
                isDragDisabled={!canManageTags}
                onSave={handleReorderCategory}
              >
                {filteredCategories.map(category => (
                  <TagsCategory
                    key={`category_${category.id}`}
                    id={category.id}
                    className={classes.category}
                    translations={translations.categories}
                    category={category}
                    isEditable={canManageTags}
                    isTagSelectable={canManageTags}
                    isDraggable={canManageTags}
                    onPreview={onPreviewCategory}
                    onManageTags={onManageTags}
                    onSelectTag={onEditTag}
                    onEdit={onManageCategory}
                    hasPeoplePreview
                    hasPlacements
                  />
                ))}
              </ReorderItems>
            ) : (
              <NotificationCard
                title={
                  isActiveSearch ? '' : translations.noData.noCategories.title
                }
                content={
                  isActiveSearch
                    ? translations.noData.noSearch
                    : translations.noData.noCategories.message
                }
              />
            )}
          </>
        )}
        <CustomFormDrawer
          translations={translations.manageCategoryForm}
          isOpened={isManageCategoryOpened}
          initialData={getCategoryData(selectedCategory)}
          isInitialValid={isEditCategory}
          hideDelete={hasTags || selectedCategory.is_protected}
          fields={getCategoryFields(
            renderCategoryBox,
            formatPlacements(Object.values(PLACEMENTS))
          )}
          onClose={onCloseCategoryDrawer}
          onSave={onSaveCategory}
          onDelete={toggleDeleteCategory}
          hasCancelButton
        />
        <CustomFormDrawer
          titleText={
            selectedTag
              ? translations.manageTagsForm.edit
              : translations.manageTagsForm.manage
          }
          translations={translations.manageTagsForm}
          isOpened={isManageTagsOpened}
          initialData={getTagData(selectedCategory, selectedTag)}
          isInitialValid={!!selectedTag || hasTags}
          hideDelete={!selectedTag && hasTags}
          fields={getTagFields(
            categories,
            renderTagAdornment,
            !!selectedTag,
            selectedCategory
          )}
          categories={categories}
          onClose={onCloseTagDrawer}
          onSave={onSaveTags}
          onDelete={handleDeleteTag}
          hasCancelButton
        />
        <AlertDialog
          isOpened={isDeleteCategoryOpened}
          translations={translations.categoryDeleteDialog}
          onClose={toggleDeleteCategory}
          onConfirm={onDeleteCategory}
          isWarning
        />
        <AlertDialog
          isOpened={isDeleteTagOpened}
          translations={
            !isArrayEmpty(tagsInUse)
              ? translations.usedTagDeleteDialog
              : translations.tagDeleteDialog
          }
          onClose={toggleDeleteTag}
          onConfirm={onDeleteTag}
          isWarning
        />
        <WarningDialog
          translations={translations.usedTagsDeleteDialog}
          isOpened={isDeleteTagsOpened}
          items={tagsInUse}
          onClose={handleTagsDeleteReset}
          onForceDelete={onTagsDelete}
          isTagsDelete
          hasForceDelete
        />
      </div>
    </PageContainer>
  );
};

TagsPage.propTypes = {
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default memo(TagsPage);
