import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { NEUTRAL_ANSWER_WEIGHT } from 'components/shared/questionEntry/config';
import { getAnswerWeight } from 'utility/attribute';
import { ellipsis } from '../../../../../constants/helperCssRules';
import { HORIZONTAL_ANSWER_SIZES } from '../../../../../utility/fillSurveyUtils';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    main: {
      boxSizing: 'border-box',
      borderRadius: 3,
      border: `1px solid ${primary.bluish6}`,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 30,
      padding: spacing(1),
      flexShrink: 0,
      userSelect: 'none',
      '&:last-of-type': {
        marginRight: 0,
      },
      '&.selected': {
        backgroundColor: primary.blue1,
        border: 'none',
        '& $answerText': {
          color: primary.white,
        },
        '&$disabled': {
          backgroundColor: primary.bluish7,
        },
      },
      [breakpoints.up('sm')]: {
        height: 40,
        padding: spacing(2),
      },
    },
    small: {
      width: 30,
      [breakpoints.up('sm')]: {
        width: 50,
      },
    },
    medium: {
      width: 78,
    },
    large: {
      width: 87,
      [breakpoints.up('sm')]: {
        width: 107,
      },
    },
    disabled: {
      cursor: 'default',
    },
    answerText: {
      fontSize: 14,
      lineHeight: '16px',
      alignSelf: 'center',
      wordWrap: 'break-word',
      ...ellipsis(),
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
    answerTextSmall: {
      fontSize: 10,
      [breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
  })
);

const HorizontalAnswer = ({
  neutralAnswerLabel,
  answer,
  numberOfAnswers,
  isSelected,
  isDisabled,
  onSelect,
}) => {
  const classes = useStyles();
  const { SMALL, MEDIUM } = HORIZONTAL_ANSWER_SIZES;

  return (
    <div
      className={classNames(classes.main, {
        [classes.disabled]: isDisabled,
        [classes.large]: numberOfAnswers <= SMALL,
        [classes.medium]: numberOfAnswers > SMALL && numberOfAnswers < MEDIUM,
        [classes.small]: numberOfAnswers >= MEDIUM,
        selected: isSelected,
      })}
      onClick={!isSelected ? onSelect : undefined}
    >
      <Typography
        className={classNames(classes.answerText, {
          [classes.answerTextSmall]: numberOfAnswers >= MEDIUM,
        })}
        variant="overline"
      >
        {getAnswerWeight(answer.weight) === NEUTRAL_ANSWER_WEIGHT
          ? neutralAnswerLabel
          : answer.text}
      </Typography>
    </div>
  );
};

HorizontalAnswer.defaultProps = {
  numberOfAnswers: HORIZONTAL_ANSWER_SIZES.LARGE,
  isSelected: false,
  onSelect: () => {},
};

HorizontalAnswer.propTypes = {
  answer: PropTypes.shape({}).isRequired,
  numberOfAnswers: PropTypes.number,
  neutralAnswerLabel: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default HorizontalAnswer;
