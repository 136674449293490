import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import Search from '../../shared/search';
import CustomButton from '../../shared/customButton';
import AlertDialog from '../../shared/alertDialog';
import ReadLessMore from '../../shared/readLessMore';
import SurveyActionBar from '../../shared/surveyActionBar';
import GridTable from '../../shared/gridTable';
import DotsMenu from '../../shared/dotsMenu';
import Filters from '../../shared/filters';
import StatusIndicator from '../../shared/statusIndicator';
import SurveyTypeDialog from '../../shared/surveyTypeDialog';
import SendSurveyNotificationDialog from '../../shared/sendSurveyNotificationDialog';
import NotificationCard from '../../shared/notificationCard';
import SurveyType from '../../shared/surveyType';
import ChooseNameDialog from '../../shared/chooseNameDialog';
import CustomFormDrawer from '../../shared/customFormDrawer';
import ActionButton from '../../shared/actionButton';
import UserAvatar from '../../shared/userAvatar';
import TextBoxWithTooltip from '../../shared/textBoxWithTooltip';
import SurveyIconInfoTooltip from '../../shared/surveyIconInfoTooltip';
import CustomSelectDialog from '../../shared/customSelectDialog';
import SurveyGroups from '../../shared/surveyGroups';
import SelectField from '../../shared/selectField';
import { ReactComponent as TitleIcon } from '../../../assets/icons/title.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as ReminderIcon } from '../../../assets/icons/notifications_none.svg';
import { ReactComponent as ForceCompleteIcon } from '../../../assets/icons/check_circle_outline.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/content_copy.svg';
import { ReactComponent as ManageGroupIcon } from '../../../assets/icons/playlist_add.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/close-icon-dark.svg';
import {
  showSuccessMessage,
  parseDuplicateParameters,
  parseQueryParams,
  hasNextPage,
} from '../../../utility/uiUtils';
import {
  validateFreemiumAction,
  isPremiumUserCheck,
  isSubscriptionExpiredCheck,
} from '../../../utility/subscriptionHelper';
import {
  isObjectEmpty,
  isArray,
  isArrayEmpty,
  checkUserRole,
  trimString,
  getItemById,
  prepareAllUsers,
  getEmployees,
  getAssociates,
  getObjectToNumberArray,
  getActiveUsers,
} from '../../../utility/helpers';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { hasSelectedFilters } from '../../shared/filters/config';
import http from '../../../utility/http';
import {
  isDateInFuture,
  addNumberOfDays,
  isDateInPast,
  getStartOfDay,
} from '../../../utility/dateUtils';
import { getActiveAttributes } from '../../../utility/attribute';
import {
  getSurveyViewOptions,
  getTranslatedSurveyTypes,
  getSurveysPageFilters,
  getSurveysHeaderActions,
  getSurveysTableHeaders,
  prepareSurveysTableData,
  prepareSurveyGroupsData,
  getCreateSurveyInitialData,
  getCreateSurveyColumns,
  prepareSurveyDataForCreation,
  prepareDuplicateSurveyData,
  getNoSurveyResultsData,
  getSurveyDeleteTranslations,
} from '../../../utility/survey';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { SURVEY_STATUS_TYPES } from '../../../constants/statuses';
import { USER_INFO } from '../../../constants/people';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import {
  INIT_SORT,
  SURVEY_TYPES,
  CREATE_SURVEY_FIELD_NAMES,
  SURVEY_GROUP_FIELD,
  SURVEY_TITLE_FIELD,
  SURVEYS_GROUP_FIELD,
} from '../../../constants/survey';
import {
  PARAMS,
  SURVEYS_DEFAULT_PARAMS,
  SURVEY_GROUPS_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import { sticky } from '../../../constants/helperCssRules';
import {
  api_survey,
  API_SURVEY_GROUPS,
  API_SUBSCRIPTION,
  api_survey_send_reminder,
  API_SURVEYS,
  api_survey_group,
  api_survey_group_duplicate,
  api_survey_activate,
  api_survey_group_items_reorder,
} from '../../../constants/apiRoutes';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  stickyHeader: {
    ...sticky(primary.white, 105),
  },
  descriptionWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(8, 0, 6, 0),
    minHeight: 88,
  },
  descriptionReadMoreWrapper: {
    alignItems: 'flex-start',
  },
  pendingSurveysBar: {
    flexShrink: 0,
    marginLeft: 'auto',
  },
  search: {
    width: 300,
  },
  actionButton: {
    marginLeft: spacing(2),
  },
  table: {
    '& .grid-table-header': {
      ...sticky(primary.bluish8, 249, 998),
    },

    '& .grid-table-header$readMoreActive': {
      top: 271,
    },

    '& .status-cell': {
      padding: 0,
    },

    '& .report-cell': {
      padding: 0,
    },

    '& .actions-dots-menu': {
      padding: spacing(3, 1),
      overflow: 'visible',

      [breakpoints.up('xLg')]: {
        padding: spacing(3.5, 3),
      },
    },
  },
  readMoreActive: {},
  filtersAndPagerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing(4),
  },
  filterSection: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  selectedFilters: {
    marginLeft: spacing(2),
    '& button': { marginBottom: 0 },
    '& div': {
      '& div': { marginBottom: 0 },
    },
  },
  completedValue: {
    marginLeft: spacing(2),
  },
  reportTooltip: {
    fontFamily: 'ProximaNova-Bold',
  },
  statusAndCompletedWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(4, 2),
    [breakpoints.up('xLg')]: {
      padding: spacing(4),
    },
  },
  fillInlineButtonWrapper: {
    padding: spacing(2.5, 2),
    [breakpoints.up('xLg')]: {
      padding: spacing(2.5, 4),
    },
  },
  createSurveyHeader: {
    padding: spacing(5, 8, 5, 5),
    [breakpoints.up('xLg')]: {
      padding: spacing(5, 8, 5, 9),
    },
  },
  surveyType: {
    '& .MuiPaper-root': {
      minHeight: 238,
      '& > *': {
        minHeight: 300,
      },
    },
  },
  createSurveyContent: {
    padding: 0,
  },
  columns: {
    gridTemplateColumns: 'repeat(2, minmax(340px, 1fr)) minmax(0, 2fr)',
    [breakpoints.up('xLg')]: {
      gridTemplateColumns: 'repeat(2, minmax(372px, 1fr)) minmax(0, 2fr)',
    },
    '& .column, & .peopleColumn, & .mutualColumn': {
      padding: spacing(5, 5, 0),
      [breakpoints.up('xLg')]: {
        padding: spacing(8, 9, 0, 9),
      },
    },

    '& .mutualColumn': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      gridColumnGap: spacing(2),
      gridTemplateAreas: `
        "type type type"
        "title title title"
        "anonymous anonymous anonymous"
        "statistical statistical statistical"
        "expiryDate expiryDate expiryTime"
        "email email email"
        "emailDescription emailDescription emailDescription"
        "schedule schedule schedule"
        "scheduleDate scheduleDate scheduleTime"
      `,
      '& .type': {
        gridArea: 'type',
      },
      '& .title': {
        gridArea: 'title',
      },
      '& .anonymous': {
        gridArea: 'anonymous',
      },
      '& .statistical': {
        gridArea: 'statistical',
        marginBottom: 0,
      },
      '& .email': {
        gridArea: 'email',
        marginBottom: spacing(4),
      },
      '& .emailDescription': {
        gridArea: 'emailDescription',
        marginBottom: 0,
      },
      '& .schedule': {
        gridArea: 'schedule',
        marginBottom: spacing(4),
      },
      '& .scheduleDate': {
        position: 'relative',
        gridArea: 'scheduleDate',
        marginBottom: 0,
        '&::after': {
          width: 'calc(100% + 8px)',
        },
      },
      '& .scheduleTime': {
        gridArea: 'scheduleTime',
        marginBottom: 0,
        '& .scheduleTimeRoot': {
          zIndex: 97,
        },
      },
      '& .expiryDate': {
        gridArea: 'expiryDate',
        marginBottom: 0,
        '&::after': {
          width: 'calc(100% + 8px)',
        },
      },
      '& .expiryTime': {
        gridArea: 'expiryTime',
        paddingTop: spacing(6.5),
        marginBottom: 0,
        '& .expiryTimeRoot': {
          zIndex: 98,
        },
      },
    },

    '& .preview-wrapper': {
      backgroundColor: primary.bluish9,
      '& .column': {
        padding: spacing(5, 5, 4, 5),
        [breakpoints.up('xLg')]: {
          padding: spacing(8, 9, 4, 9),
        },
      },
    },
  },
  column: {},
  surveyTitle: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  title: {
    width: 'auto',
  },
  infoIcon: {
    flexShrink: 0,
    '& svg': {
      width: 16,
      height: 16,
      marginRight: 0,
    },
  },
  anonymous: {
    display: 'block',
    '& svg': {
      width: 16,
      height: 14,
    },
  },
  fillSurveyButton: {
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 48,
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    padding: spacing(2, 4),
    maxHeight: 32,
  },
  buttonIconWrapper: {
    marginLeft: 0,
    marginRight: spacing(1),
  },
  buttonIcon: {
    height: 16,
    width: 16,
  },
  organizationUser: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 14,
    lineHeight: '18px',
    width: '100%',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  viewMenu: {
    zIndex: 1000,
  },
  surveyTypeTooltip: {
    cursor: 'pointer',
  },
  typeIcon: {
    marginRight: 0,
  },
});

const {
  PAGE,
  SEARCH,
  ORDERING,
  TYPE,
  REVIEWERS,
  CREATOR,
  CREATED_FOR,
  STATUS,
} = PARAMS;

class SurveysPage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
    filters: {
      [TYPE]: [],
      [REVIEWERS]: [],
      [CREATOR]: [],
      [CREATED_FOR]: [],
      [STATUS]: [],
    },
    [SEARCH]: '',
    [ORDERING]: INIT_SORT,
    isReadMore: true,
    isSurveyTypeDialogOpened: false,
    selectedSurveyType: null,
    isCreateSurveyOpened: false,
    isSendSurveyNotificationOpened: false,
    surveyData: null,
    predefinedSurveyData: {},
    isSurveyTitleDialogOpened: false,
    isGroupDialogOpened: false,
    isManageSurveyGroupDialogOpened: false,
    survey: null,
    isGroupView: false,
    surveyGroup: null,
    isDeleteSurveyGroupOpened: false,
    isDuplicateGroupOpened: false,
    isDuplicateSurveyOpened: false,
    isRemoveFromGroupOpened: false,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearSurveys,
      clearPendingSurveys,
      clearAllUsers,
      clearAttributesWithQuestions,
      clearAttribute,
      clearSurvey,
      clearSurveyGroups,
      clearAllSurveyGroups,
    } = this.props;

    clearSurveys();
    clearPendingSurveys();
    clearAllUsers();
    clearAttributesWithQuestions();
    clearAttribute();
    clearSurvey();
    clearSurveyGroups();
    clearAllSurveyGroups();
  }

  getInitialData = () => {
    const {
      translations,
      location,
      getAttributesWithQuestions,
      getPendingSurveys,
      getAllUsers,
      getAllSurveyGroups,
    } = this.props;
    const { state, search } = location;
    const isGroupView = !!state?.isGroupView;
    const surveyData = location?.state?.surveyData;
    const columns = getSurveysTableHeaders(translations.columns);
    const params = parseQueryParams(
      search,
      isGroupView
        ? PAGE_WHITELISTED_PARAMS.SURVEY_SECTIONS
        : PAGE_WHITELISTED_PARAMS.SURVEYS
    );

    if (surveyData) {
      return this.setState(
        {
          selectedSurveyType: surveyData[CREATE_SURVEY_FIELD_NAMES.SURVEY_TYPE],
          isCreateSurveyOpened: true,
          predefinedSurveyData: surveyData,
        },
        () =>
          Promise.all([
            this.handlePageChange(),
            getAllUsers(),
            getAttributesWithQuestions(),
            getPendingSurveys(),
            getAllSurveyGroups(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          })
      );
    }

    if (!isObjectEmpty(params)) {
      const [ascending, descending] = isGroupView
        ? []
        : params[ORDERING].split('-');
      const isAscending = !!ascending;

      return this.setState(
        {
          isGroupView,
          ...(!isGroupView
            ? {
                [ORDERING]: {
                  column: getItemById(
                    columns,
                    isAscending ? ascending : descending,
                    'sortAs'
                  )?.rowKey,
                  asc: isAscending,
                  sortKey: params[ORDERING],
                },
              }
            : {}),
          [SEARCH]: params[SEARCH]?.toString() || '',
          filters: {
            [STATUS]: params[STATUS] ? [params[STATUS]] : [],
            [TYPE]: params[TYPE] ? [params[TYPE]] : [],
            [REVIEWERS]: params[REVIEWERS]
              ? [
                  ...(isArray(params[REVIEWERS])
                    ? params[REVIEWERS]
                    : [params[REVIEWERS]]),
                ]
              : [],
            [CREATOR]: params[CREATOR]
              ? [
                  ...(isArray(params[CREATOR])
                    ? params[CREATOR]
                    : [params[CREATOR]]),
                ]
              : [],
            [CREATED_FOR]: params[CREATED_FOR]
              ? [
                  ...(isArray(params[CREATED_FOR])
                    ? params[CREATED_FOR]
                    : [params[CREATED_FOR]]),
                ]
              : [],
          },
        },
        () => {
          return Promise.all([
            this.handlePageChange(false),
            getAllUsers(),
            getAttributesWithQuestions(),
            getPendingSurveys(),
            getAllSurveyGroups(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(),
      getAllUsers(),
      getAttributesWithQuestions(),
      getPendingSurveys(),
      getAllSurveyGroups(),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  handlePageChange = (isLoadMore = false) => {
    const { history, location, getSurveys, getSurveyGroups } = this.props;
    const { page, filters, ordering, search, isGroupView } = this.state;
    const params = {
      page,
      ...(isGroupView ? {} : { [ORDERING]: ordering.sortKey }),
      ...(isGroupView ? {} : filters),
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/surveys/?${query}`, { isGroupView });
    }

    return isGroupView
      ? getSurveyGroups(isLoadMore, params)
      : getSurveys(isLoadMore, params);
  };

  handleRowClick = isAdmin => survey => {
    const { history, auth } = this.props;

    if (isAdmin || auth.id === survey.creatorId) {
      return history.push(`/surveys/${survey.id}/settings`);
    }

    if (survey.isDraft) return;

    return history.push(`/surveys/${survey.id}/report`);
  };

  onSearch = text => {
    const searchTerm = trimString(text);

    this.setState(
      {
        [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
        [SEARCH]: searchTerm,
        isLoading: true,
      },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onSort = newOrdering => {
    this.setState(
      { [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE], [ORDERING]: newOrdering },
      () => this.handlePageChange()
    );
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  handleApplyFilters = filters =>
    this.setState(
      { [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE], filters, isLoading: true },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );

  handleSendReminder = surveyId => {
    const { translations } = this.props;
    const params = {
      client_url: `${window.location.origin}/fill-survey`,
      group_id: surveyId,
    };

    http.post(api_survey_send_reminder(surveyId), params).then(() => {
      showSuccessMessage(translations.reminderSent);
    });
  };

  handleFillInlineSurvey = async surveyId => {
    const { history, getTrackingById, setIsInlineSurvey } = this.props;
    const { data } = await getTrackingById(surveyId);
    const surveyToken = data && data.tracking_token ? data.tracking_token : '';

    if (surveyToken) {
      setIsInlineSurvey(true);

      history.push(`/fill-survey/${surveyToken}`);
    }
  };

  handleOpenSurveyTypeDialog = (e, surveyGroup = null) =>
    this.setState({
      isSurveyTypeDialogOpened: true,
      surveyGroup,
    });

  handleCloseSurveyType = () =>
    this.setState({
      isSurveyTypeDialogOpened: false,
      selectedSurveyType: null,
      surveyGroup: null,
    });

  handleSaveSurveyType = () => {
    const { selectedSurveyType } = this.state;

    return this.setState({
      isSurveyTypeDialogOpened: false,
      isCreateSurveyOpened: true,
      selectedSurveyType: selectedSurveyType || SURVEY_TYPES.SELF,
    });
  };

  handleSelectSurveyType = surveyType => () => {
    const { selectedSurveyType } = this.state;

    if (selectedSurveyType !== surveyType) {
      this.setState({ selectedSurveyType: surveyType });
    }
  };

  handleChangeSurveyType = surveyType =>
    this.setState({
      selectedSurveyType: surveyType,
      predefinedSurveyData: {},
    });

  handleCloseCreateSurveyDrawer = () => {
    const { clearAttribute } = this.props;

    clearAttribute();
    this.setState({
      isCreateSurveyOpened: false,
      selectedSurveyType: null,
      predefinedSurveyData: {},
      surveyGroup: null,
    });
  };

  handleCreateSurveySave = values => {
    this.setState({ surveyData: values, isSendSurveyNotificationOpened: true });

    return Promise.resolve({ shouldResetForm: false });
  };

  handleCloseSurveyNotificationDialog = () =>
    this.setState({ isSendSurveyNotificationOpened: false, surveyData: null });

  handleFillInlineChange = isFillInline => {
    const { FILL_INLINE } = CREATE_SURVEY_FIELD_NAMES;

    this.setState(prevState => ({
      surveyData: { ...prevState.surveyData, [FILL_INLINE]: isFillInline },
    }));
  };

  handleCreateSurvey = (e, isDraftCreation = false) => {
    const { history, translations, setIsInlineSurvey, clearAttribute } =
      this.props;
    const { surveyData, isGroupView, predefinedSurveyData } = this.state;
    const { SURVEY_TYPE, FILL_INLINE } = CREATE_SURVEY_FIELD_NAMES;
    const surveyType = surveyData[SURVEY_TYPE];
    const isInlineSurvey = surveyData[FILL_INLINE];
    const params = prepareSurveyDataForCreation(
      surveyData,
      isGroupView,
      predefinedSurveyData.isDraftActivation,
      isDraftCreation
    );

    if (isInlineSurvey && !isDraftCreation) {
      setIsInlineSurvey(true);
    }

    return http
      .post(
        predefinedSurveyData.isDraftActivation
          ? api_survey_activate(predefinedSurveyData.id)
          : `${API_SURVEYS}${surveyType.toLowerCase()}/`,
        params
      )
      .then(
        ({ data }) => {
          const { ComposeNewSurvey } = EVENT_ACTION_TYPES;
          tagManagerDataLayer(
            ComposeNewSurvey.action,
            ComposeNewSurvey.name,
            params.title
          );
          showSuccessMessage(translations.successMessages.created);
          this.setState(
            {
              [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
              isSendSurveyNotificationOpened: false,
              isCreateSurveyOpened: false,
              selectedSurveyType: null,
              surveyData: null,
              surveyGroup: null,
              predefinedSurveyData: {},
            },
            () => {
              clearAttribute();
              if (isInlineSurvey && !isDraftCreation) {
                history.push(`/fill-survey/${data.tracking_token}`);
              } else {
                this.handlePageChange();
              }
            }
          );
        },
        () => {
          clearAttribute();
          this.setState({
            selectedSurveyType: null,
            isCreateSurveyOpened: false,
            isSendSurveyNotificationOpened: false,
            surveyData: null,
            surveyGroup: null,
            predefinedSurveyData: {},
          });
        }
      );
  };

  handleDeleteSurvey = () => {
    const { setDialogVisibility, translations } = this.props;
    const { survey } = this.state;

    http.delete(api_survey(survey.id)).then(() => {
      this.setState(
        {
          [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
          survey: null,
        },
        () => {
          showSuccessMessage(translations.surveyDeleteDialog.deleteSuccess);
          setDialogVisibility({
            dialogName: 'forceDeleteSurveyDialog',
            opened: false,
          });
          this.handlePageChange();
        }
      );
    });
  };

  handleForceCompleteSurvey = () => {
    const { editSurvey, setDialogVisibility, translations } = this.props;
    const { survey } = this.state;

    return editSurvey(survey.id, {
      [CREATE_SURVEY_FIELD_NAMES.EXPIRATION_DATE]: getStartOfDay(new Date()),
    }).then(() => {
      this.setState({ survey: null }, () => {
        showSuccessMessage(translations.forceCompleteDialog.completeSuccess);
        setDialogVisibility({
          dialogName: 'forceCompleteSurveyDialog',
          opened: false,
        });
        this.handlePageChange();
      });
    });
  };

  handleToggleReadMore = () => {
    this.setState(prevState => ({
      isReadMore: !prevState.isReadMore,
    }));
  };

  handleToggleSurveyTitleDialog = survey =>
    this.setState(prevState => ({
      isSurveyTitleDialogOpened: !prevState.isSurveyTitleDialogOpened,
      survey,
    }));

  handleChangeSurveyTitle = surveyTitle => {
    const { translations } = this.props;
    const { survey } = this.state;

    return http
      .patch(api_survey(survey.id), { title: surveyTitle })
      .then(() => {
        showSuccessMessage(translations.renameSurveyDialog.success);
        return this.setState(
          {
            [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
            isSurveyTitleDialogOpened: false,
            survey: null,
          },
          this.handlePageChange
        );
      });
  };

  handleActivateSurvey = async (surveyId, isDraftActivation = false) => {
    const { isGroupView } = this.state;

    const [survey] = await Promise.all([http.get(api_survey(surveyId))]);

    const {
      id,
      title,
      attributes,
      created_for,
      is_statistical,
      scheduled_for,
      is_anonymous,
      reviewers,
      message,
      type,
      group,
      status,
    } = survey.data;

    const predefinedData = {
      id,
      status,
      isDraftActivation,
      [CREATE_SURVEY_FIELD_NAMES.TITLE]: title,
      [CREATE_SURVEY_FIELD_NAMES.ATTRIBUTES]: getActiveAttributes(attributes),
      [CREATE_SURVEY_FIELD_NAMES.CREATED_FOR]: getActiveUsers(created_for),
      [CREATE_SURVEY_FIELD_NAMES.IS_STATISTICAL]: is_statistical,
      [CREATE_SURVEY_FIELD_NAMES.IS_SCHEDULE]: Boolean(scheduled_for),
      [CREATE_SURVEY_FIELD_NAMES.IS_ANONYMOUS]: is_anonymous,
      [CREATE_SURVEY_FIELD_NAMES.REVIEWERS]: getActiveUsers(reviewers),
      [CREATE_SURVEY_FIELD_NAMES.HAS_EMAIL_MESSAGE]: true,
      [CREATE_SURVEY_FIELD_NAMES.EMAIL_MESSAGE]: message || '',
      ...(isGroupView || isDraftActivation
        ? { [CREATE_SURVEY_FIELD_NAMES.GROUP]: group }
        : {}),
    };
    this.setState({
      isCreateSurveyOpened: true,
      selectedSurveyType: type,
      predefinedSurveyData: predefinedData,
    });
  };

  handleOpenSurveyDuplicate = async surveyId => {
    const [survey] = await Promise.all([http.get(api_survey(surveyId))]);

    this.setState({ isDuplicateSurveyOpened: true, surveyData: survey.data });
  };

  handleCloseSurveyDuplicate = () =>
    this.setState({ isDuplicateSurveyOpened: false, surveyData: null });

  handleSaveSurveyDuplicate = async () => {
    const { translations } = this.props;
    const { surveyData } = this.state;
    const { ComposeNewSurvey } = EVENT_ACTION_TYPES;
    const duplicateData = prepareDuplicateSurveyData(surveyData);

    try {
      await http.post(
        `${API_SURVEYS}${surveyData.type.toLowerCase()}/`,
        duplicateData
      );
      tagManagerDataLayer(
        ComposeNewSurvey.action,
        ComposeNewSurvey.name,
        surveyData[CREATE_SURVEY_FIELD_NAMES.TITLE]
      );
      showSuccessMessage(translations.surveyDuplicateDialog.duplicateSuccess);
      this.setState(
        {
          [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
        },
        () => {
          this.handlePageChange();
        }
      );
    } finally {
      this.handleCloseSurveyDuplicate();
    }
  };

  handleOpenGroupDialog = (e, surveyGroup = null) =>
    this.setState({ isGroupDialogOpened: true, surveyGroup });

  handleCloseGroupDialog = () =>
    this.setState({ isGroupDialogOpened: false, surveyGroup: null });

  handleSaveGroup = groupName => {
    const { translations, getAllSurveyGroups } = this.props;
    const { surveyGroup } = this.state;

    if (surveyGroup) {
      return http
        .patch(api_survey_group(surveyGroup.id), { title: groupName })
        .then(() => {
          this.setState(
            {
              [PAGE]: SURVEY_GROUPS_DEFAULT_PARAMS[PAGE],
              isGroupDialogOpened: false,
              surveyGroup: null,
            },
            () => {
              showSuccessMessage(translations.manageGroupDialog.success.edit);
              getAllSurveyGroups();
              this.handlePageChange();
            }
          );
        });
    }

    return http
      .post(API_SURVEY_GROUPS, { title: groupName, surveys: [] })
      .then(() => {
        this.setState(
          {
            [PAGE]: SURVEY_GROUPS_DEFAULT_PARAMS[PAGE],
            isGroupDialogOpened: false,
          },
          () => {
            showSuccessMessage(translations.manageGroupDialog.success.create);
            this.handlePageChange();
          }
        );
      });
  };

  handleOpenGroupDuplicate = async surveyGroup => {
    const {
      organizationSettings,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const isPremiumUser = isPremiumUserCheck(organizationSettings);
    const isSubscriptionExpired =
      isSubscriptionExpiredCheck(organizationSettings);

    if (!isPremiumUser) {
      const { data } = await http.get(API_SUBSCRIPTION);

      if (data.is_active) {
        await getOrganizationSettings();

        return this.setState({ isDuplicateGroupOpened: true, surveyGroup });
      }

      if (
        data.usage[FREEMIUM_LIMIT_TYPES.SURVEYS] + surveyGroup.surveys.length <=
        data.limit[FREEMIUM_LIMIT_TYPES.SURVEYS]
      ) {
        return this.setState({ isDuplicateGroupOpened: true, surveyGroup });
      }

      return setDialogVisibility({
        dialogName: 'isLimitReachedDialog',
        opened: true,
      });
    }

    if (isSubscriptionExpired) {
      const { data } = await http.get(API_SUBSCRIPTION);

      if (data.is_active) {
        await getOrganizationSettings();

        return this.setState({ isDuplicateGroupOpened: true, surveyGroup });
      }

      if (
        data.usage[FREEMIUM_LIMIT_TYPES.SURVEYS] + surveyGroup.surveys.length <=
        data.limit[FREEMIUM_LIMIT_TYPES.SURVEYS]
      ) {
        return this.setState({ isDuplicateGroupOpened: true, surveyGroup });
      }

      const isExpired = await getOrganizationSettings();

      if (isExpired) return;

      return this.setState({ isDuplicateGroupOpened: true, surveyGroup });
    }

    return this.setState({ isDuplicateGroupOpened: true, surveyGroup });
  };

  handleCloseGroupDuplicate = () =>
    this.setState({ isDuplicateGroupOpened: false, surveyGroup: null });

  handleDuplicateGroup = () => {
    const { translations, getAllSurveyGroups } = this.props;
    const { surveyGroup } = this.state;

    return http.post(api_survey_group_duplicate(surveyGroup.id)).then(
      () => {
        this.setState(
          {
            [PAGE]: SURVEY_GROUPS_DEFAULT_PARAMS[PAGE],
            isDuplicateGroupOpened: false,
            surveyGroup: null,
          },
          () => {
            showSuccessMessage(
              translations.surveyGroupDuplicateDialog.duplicateSuccess
            );
            this.handlePageChange();
            getAllSurveyGroups();
          }
        );
      },
      () => {
        this.setState({
          [PAGE]: SURVEY_GROUPS_DEFAULT_PARAMS[PAGE],
          isDuplicateGroupOpened: false,
          surveyGroup: null,
        });
      }
    );
  };

  handleOpenGroupDelete = surveyGroup =>
    this.setState({ isDeleteSurveyGroupOpened: true, surveyGroup });

  handleCloseGroupDelete = () =>
    this.setState({ isDeleteSurveyGroupOpened: false, surveyGroup: null });

  handleDeleteGroup = () => {
    const { translations, getAllSurveyGroups } = this.props;
    const { surveyGroup } = this.state;

    return http.delete(api_survey_group(surveyGroup.id)).then(() => {
      this.setState(
        {
          [PAGE]: SURVEY_GROUPS_DEFAULT_PARAMS[PAGE],
          isDeleteSurveyGroupOpened: false,
          surveyGroup: null,
        },
        () => {
          showSuccessMessage(
            translations.surveyGroupDeleteDialog.deleteSuccess
          );
          this.handlePageChange();
          getAllSurveyGroups();
        }
      );
    });
  };

  handleOpenSurveyGroupDialog = (survey = null) =>
    this.setState({ isManageSurveyGroupDialogOpened: true, survey });

  handleCloseSurveyGroupDialog = () =>
    this.setState({ isManageSurveyGroupDialogOpened: false, survey: null });

  handleSaveSurveyGroup = groupId => {
    const { translations } = this.props;
    const { survey } = this.state;

    return http.patch(api_survey(survey.id), { group: groupId }).then(() => {
      this.setState(
        {
          [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
          isManageSurveyGroupDialogOpened: false,
          survey: null,
        },
        () => {
          showSuccessMessage(
            survey.group
              ? translations.manageGroupItemsDialog.success.edit
              : translations.manageGroupItemsDialog.success.create
          );
          this.handlePageChange();
        }
      );
    });
  };

  handleOpenRemoveFromGroupDialog = survey =>
    this.setState({ isRemoveFromGroupOpened: true, survey });

  handleCloseRemoveFromGroupDialog = () =>
    this.setState({ isRemoveFromGroupOpened: false, survey: null });

  handleSaveRemoveFromGroup = () => {
    const { translations, deleteSurveyFromGroup } = this.props;
    const { isGroupView, survey } = this.state;

    return http.patch(api_survey(survey.id), { group: null }).then(() => {
      if (isGroupView) {
        deleteSurveyFromGroup({
          groupId: survey[CREATE_SURVEY_FIELD_NAMES.GROUP],
          survey,
        });

        return this.setState(
          {
            isRemoveFromGroupOpened: false,
            survey: null,
          },
          () => {
            showSuccessMessage(translations.removeFromGroupDialog.success);
          }
        );
      }

      return this.setState(
        {
          [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE],
          isRemoveFromGroupOpened: false,
          survey: null,
        },
        () => {
          showSuccessMessage(translations.removeFromGroupDialog.success);
          this.handlePageChange();
        }
      );
    });
  };

  handleReorderGroupItems = (groupId, reorderedSurveys) => {
    const { setGroupSurveys } = this.props;
    const surveyIds = getObjectToNumberArray(reorderedSurveys);

    setGroupSurveys({ id: groupId, surveyIds });

    return http.post(api_survey_group_items_reorder(groupId), {
      order: surveyIds,
    });
  };

  handleChangeView = view =>
    this.setState(
      { isGroupView: view, [PAGE]: SURVEYS_DEFAULT_PARAMS[PAGE] },
      this.handlePageChange
    );

  getMenuItems = (survey, isAdmin) => {
    const {
      translations,
      organizationSettings,
      auth,
      allSurveyGroups,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const isAdminOrCreator = isAdmin || Number(survey.creator) === auth.id;
    const hasGroups = !isArrayEmpty(allSurveyGroups);
    const hasGroup = !!survey[CREATE_SURVEY_FIELD_NAMES.GROUP];
    const isDraft = survey.status === SURVEY_STATUS_TYPES.DRAFT;

    return [
      {
        type: 'reminder',
        label: translations.menuItemLabels.reminder,
        shouldItemRender: () =>
          survey.status === SURVEY_STATUS_TYPES.ACTIVE && isAdminOrCreator,
        action: () => this.handleSendReminder(survey.id),
        icon: ReminderIcon,
      },
      {
        type: 'sendSurvey',
        label: translations.menuItemLabels.reviewAndSend,
        shouldItemRender: () => isDraft && isAdminOrCreator,
        action: () => this.handleActivateSurvey(survey.id, true),
        icon: SendIcon,
      },
      {
        type: 'edit',
        label: translations.menuItemLabels.renameSurvey,
        shouldItemRender: () => isAdminOrCreator,
        action: () => this.handleToggleSurveyTitleDialog(survey),
        icon: TitleIcon,
      },
      {
        type: 'manageGroup',
        label: translations.menuItemLabels.manageGroup,
        shouldItemRender: () => hasGroups && isAdmin,
        action: () => this.handleOpenSurveyGroupDialog(survey),
        icon: ManageGroupIcon,
      },
      {
        type: 'removeGroup',
        label: translations.menuItemLabels.removeGroup,
        shouldItemRender: () => hasGroup && isAdmin,
        action: () => this.handleOpenRemoveFromGroupDialog(survey),
        icon: RemoveIcon,
      },
      {
        type: 'duplicate',
        label: translations.menuItemLabels.duplicate,
        shouldItemRender: () => isAdminOrCreator,
        action: () =>
          validateFreemiumAction(
            this.handleOpenSurveyDuplicate,
            FREEMIUM_LIMIT_TYPES.SURVEYS,
            organizationSettings,
            setDialogVisibility,
            getOrganizationSettings
          )(survey.id),
        icon: DuplicateIcon,
      },
      {
        type: 'forceComplete',
        label: translations.menuItemLabels.forceComplete,
        shouldItemRender: () =>
          survey.status !== SURVEY_STATUS_TYPES.COMPLETED &&
          survey.status !== SURVEY_STATUS_TYPES.SCHEDULED &&
          isDateInPast(addNumberOfDays(getStartOfDay(survey.sent_at), 2)) &&
          isDateInFuture(survey.expire_at),

        action: () => {
          this.setState({ survey }, () => {
            setDialogVisibility({
              dialogName: 'forceCompleteSurveyDialog',
              opened: true,
            });
          });
        },
        icon: ForceCompleteIcon,
      },
      {
        type: 'delete',
        label: translations.menuItemLabels.delete,
        shouldItemRender: () => {
          return true;
        },
        action: () => {
          this.setState({ survey }, () => {
            setDialogVisibility({
              dialogName: 'forceDeleteSurveyDialog',
              opened: true,
            });
          });
        },
        icon: DeleteIcon,
      },
    ];
  };

  renderTitle = survey => {
    const { classes, translations } = this.props;

    return (
      <div className={classes.surveyTitle}>
        <TextBoxWithTooltip
          className={classes.title}
          text={survey.title}
          variant="subtitle1"
        />
        {!survey.is_statistical && (
          <SurveyIconInfoTooltip
            className={classes.infoIcon}
            tooltipText={translations.oneTimeSurvey}
            isOneTimeSurvey
          />
        )}
        {survey.is_anonymous && (
          <SurveyIconInfoTooltip
            className={classNames(classes.anonymous, classes.infoIcon)}
            tooltipText={translations.anonymousSurvey}
          />
        )}
      </div>
    );
  };

  renderActions = (survey, isAdmin) => {
    const { classes, translations } = this.props;
    const isDraft =
      survey[CREATE_SURVEY_FIELD_NAMES.STATUS] === SURVEY_STATUS_TYPES.DRAFT;

    return (
      <div className={classes.actions}>
        {!isDraft && (
          <ActionButton
            type={ACTION_BUTTON_TYPES.REPORT}
            tooltipText={translations.viewReport}
            linkProps={{
              to: `/surveys/${survey.id}/report`,
            }}
            isLink
          />
        )}
        <DotsMenu menuItems={this.getMenuItems(survey, isAdmin)} />
      </div>
    );
  };

  renderStatusAndCompleted = (survey, status, type, isDraft) => {
    const { classes, translations } = this.props;
    const { id, completed, review_link_available } = survey;
    const { surveyIcon: Icon } = type;

    if (review_link_available) {
      return (
        <div className={classes.fillInlineButtonWrapper}>
          <CustomButton
            className={classes.fillSurveyButton}
            classes={{ startIcon: classes.buttonIconWrapper }}
            type="addWithTextRounded"
            startIcon={<Icon className={classes.buttonIcon} />}
            onClick={e => {
              e.stopPropagation();
              return this.handleFillInlineSurvey(id);
            }}
          >
            {translations.menuItemLabels.fillSurvey}
          </CustomButton>
        </div>
      );
    }

    return (
      <div className={classes.statusAndCompletedWrapper}>
        <StatusIndicator status={status} />
        {!isDraft && (
          <Typography className={classes.completedValue}>
            {completed}
          </Typography>
        )}
      </div>
    );
  };

  renderType = type => {
    const { classes } = this.props;
    return (
      <SurveyType
        typeIconClass={classes.typeIcon}
        tooltipClass={classes.surveyTypeTooltip}
        type={type}
        isIconStatus
      />
    );
  };

  renderOrganizationUser = () => {
    const { classes, translations, organizationSettings } = this.props;

    return (
      <UserAvatar
        labelClass={classes.organizationUser}
        user={{
          [USER_INFO.FIRST_NAME]: organizationSettings.name,
          [USER_INFO.LAST_NAME]: `(${translations.organization})`,
        }}
        caption
        small
        isOrganizationUser
      />
    );
  };

  render() {
    const {
      translations,
      classes,
      surveys,
      surveyGroups,
      allSurveyGroups,
      allUsers,
      auth,
      dialogs,
      organizationSettings,
      setDialogVisibility,
      pendingSurveys,
      attributesWithQuestions,
      attribute,
      getAttribute,
      clearAttribute,
      getOrganizationSettings,
    } = this.props;
    const {
      isInitialLoad,
      isLoading,
      isReadMore,
      filters,
      ordering,
      isSurveyTypeDialogOpened,
      selectedSurveyType,
      isCreateSurveyOpened,
      isSendSurveyNotificationOpened,
      surveyData,
      predefinedSurveyData,
      search,
      isSurveyTitleDialogOpened,
      isManageSurveyGroupDialogOpened,
      isGroupDialogOpened,
      isGroupView,
      survey,
      surveyGroup,
      isDeleteSurveyGroupOpened,
      isDuplicateGroupOpened,
      isDuplicateSurveyOpened,
      isRemoveFromGroupOpened,
    } = this.state;
    const { forceCompleteSurveyDialogOpened, forceDeleteSurveyDialogOpened } =
      dialogs;
    const surveyTypes = getTranslatedSurveyTypes(translations);
    const surveyViewOptions = getSurveyViewOptions(
      translations.surveyViewOptions
    );
    const filterIsActive = hasSelectedFilters(filters);
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const users = prepareAllUsers(allUsers, auth);
    const employees = getEmployees(users);
    const associates = getAssociates(users);
    const hasLoadMore = hasNextPage(surveys);
    const hasLoadMoreGroups = hasNextPage(surveyGroups);
    const gridHeaders = getSurveysTableHeaders(
      translations.columns,
      !isGroupView
    );
    const gridHeaderActions = getSurveysHeaderActions(translations.columns);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <div
              className={classNames(classes.descriptionWrapper, {
                [classes.descriptionReadMoreWrapper]: !isReadMore,
              })}
            >
              <ReadLessMore
                translations={translations}
                isReadMore={isReadMore}
                toggleReadMore={this.handleToggleReadMore}
              >
                <Typography variant="body2" className={classes.descriptionItem}>
                  {translations.descriptionPartOne}
                </Typography>
                <Typography variant="body2" className={classes.descriptionItem}>
                  {translations.descriptionPartTwo}
                </Typography>
              </ReadLessMore>
              <SurveyActionBar
                className={classes.pendingSurveysBar}
                translations={translations.surveysActionBar}
                surveys={pendingSurveys}
                onFillInlineSurvey={this.handleFillInlineSurvey}
              />
            </div>
            <div className={classes.filtersAndPagerContainer}>
              {!isGroupView && (
                <div className={classes.filterSection}>
                  <Filters
                    translations={translations.filters}
                    selectedFilters={filters}
                    filters={getSurveysPageFilters(translations, allUsers)}
                    onApplyFilters={this.handleApplyFilters}
                  />
                </div>
              )}
              <div className={classes.rightSide}>
                <Search
                  className={classes.search}
                  placeholder={translations.searchPlaceholder}
                  value={search}
                  onChange={this.onSearch}
                />
                {isAdmin && (
                  <SelectField
                    className={classes.actionButton}
                    menuClass={classes.viewMenu}
                    value={isGroupView}
                    options={surveyViewOptions}
                    parser={{ value: 'value', label: 'name' }}
                    onChange={this.handleChangeView}
                    isSearchDisabled
                    shouldRemoveLabel
                  />
                )}
                {isGroupView ? (
                  <CustomButton
                    className={classes.actionButton}
                    type="addRoundedNew"
                    onClick={this.handleOpenGroupDialog}
                  >
                    {translations.addGroupButton}
                  </CustomButton>
                ) : (
                  <CustomButton
                    className={classes.actionButton}
                    type="addRoundedNew"
                    onClick={validateFreemiumAction(
                      this.handleOpenSurveyTypeDialog,
                      FREEMIUM_LIMIT_TYPES.SURVEYS,
                      organizationSettings,
                      setDialogVisibility,
                      getOrganizationSettings
                    )}
                  >
                    {translations.sendSurvey}
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
          {!isGroupView &&
            isArray(surveys.results) &&
            !isArrayEmpty(surveys.results) && (
              <GridTable
                className={classes.table}
                translations={translations}
                customHeaderClass={classNames({
                  [classes.readMoreActive]: !isReadMore,
                })}
                initialSort={ordering}
                headerActions={gridHeaderActions}
                headers={gridHeaders}
                hasLoadMore={hasLoadMore}
                rows={prepareSurveysTableData(
                  surveys.results,
                  translations,
                  surveyTypes,
                  allSurveyGroups,
                  this.renderTitle,
                  this.renderType,
                  this.renderStatusAndCompleted,
                  this.renderActions,
                  isAdmin
                )}
                onLoadMore={this.onLoadMore}
                onSort={this.onSort}
                onRowClick={this.handleRowClick(isAdmin)}
              />
            )}
          {isGroupView &&
            isArray(surveyGroups.results) &&
            !isArrayEmpty(surveyGroups.results) && (
              <SurveyGroups
                translations={translations.groupView}
                surveyColumns={[...gridHeaders, ...gridHeaderActions]}
                groups={prepareSurveyGroupsData(
                  surveyGroups.results,
                  translations,
                  surveyTypes,
                  allSurveyGroups,
                  this.renderTitle,
                  this.renderType,
                  this.renderStatusAndCompleted,
                  this.renderActions,
                  isAdmin
                )}
                hasLoadMore={hasLoadMoreGroups}
                onLoadMore={this.onLoadMore}
                onEditGroupTitle={this.handleOpenGroupDialog}
                onDeleteGroup={this.handleOpenGroupDelete}
                onDuplicateGroup={this.handleOpenGroupDuplicate}
                onSurveyClick={this.handleRowClick(isAdmin)}
                onCreateSurvey={validateFreemiumAction(
                  this.handleOpenSurveyTypeDialog,
                  FREEMIUM_LIMIT_TYPES.SURVEYS,
                  organizationSettings,
                  setDialogVisibility,
                  getOrganizationSettings
                )}
                onReorderGroups={() => {}}
                onReorderGroupItems={this.handleReorderGroupItems}
              />
            )}
          <NotificationCard
            {...getNoSurveyResultsData(
              translations,
              isLoading,
              isGroupView,
              search || filterIsActive
            )}
            shouldFade={
              !isLoading &&
              ((!isGroupView &&
                isArray(surveys.results) &&
                isArrayEmpty(surveys.results)) ||
                (isGroupView &&
                  isArray(surveyGroups.results) &&
                  isArrayEmpty(surveyGroups.results)))
            }
          />
          <SurveyTypeDialog
            translations={translations.surveyTypeDialog}
            isOpened={isSurveyTypeDialogOpened}
            surveyTypes={surveyTypes}
            selectedType={selectedSurveyType || SURVEY_TYPES.SELF}
            onSelect={this.handleSelectSurveyType}
            onCancel={this.handleCloseSurveyType}
            onConfirm={this.handleSaveSurveyType}
          />
          <CustomFormDrawer
            customHeaderClass={classes.createSurveyHeader}
            customContentClass={classes.createSurveyContent}
            customColumnsClass={classes.columns}
            translations={translations.createSurveyForm}
            initialData={getCreateSurveyInitialData(
              translations,
              selectedSurveyType,
              surveyTypes,
              this.renderOrganizationUser,
              surveyGroup,
              predefinedSurveyData
            )}
            isOpened={isCreateSurveyOpened}
            columns={getCreateSurveyColumns(
              translations.createSurveyForm.columns,
              translations.defaultEmailMessage,
              selectedSurveyType,
              surveyTypes,
              predefinedSurveyData,
              this.handleChangeSurveyType
            )}
            allUsers={users}
            associates={associates}
            employees={employees}
            surveyTypes={surveyTypes}
            attributesWithQuestions={attributesWithQuestions}
            attribute={attribute}
            getAttribute={getAttribute}
            clearAttribute={clearAttribute}
            onClose={this.handleCloseCreateSurveyDrawer}
            onSave={this.handleCreateSurveySave}
            hasColumnScroll
            hasCancelButton
            isWholeScreen
          />
          {surveyData && isSendSurveyNotificationOpened && (
            <SendSurveyNotificationDialog
              translations={translations.sendSurveyNotificationDialog}
              isOpened={isSendSurveyNotificationOpened}
              surveyData={surveyData}
              currentUser={auth}
              onFillInlineChange={this.handleFillInlineChange}
              onClose={this.handleCloseSurveyNotificationDialog}
              onConfirm={this.handleCreateSurvey}
            />
          )}
          <AlertDialog
            translations={getSurveyDeleteTranslations(translations, survey)}
            isOpened={forceDeleteSurveyDialogOpened}
            onClose={() => {
              this.setState({ survey: null }, () => {
                setDialogVisibility({
                  dialogName: 'forceDeleteSurveyDialog',
                  opened: false,
                });
              });
            }}
            onConfirm={this.handleDeleteSurvey}
            isWarning
          />
          <AlertDialog
            translations={translations.forceCompleteDialog}
            isOpened={forceCompleteSurveyDialogOpened}
            onClose={() => {
              this.setState({ survey: null }, () => {
                setDialogVisibility({
                  dialogName: 'forceCompleteSurveyDialog',
                  opened: false,
                });
              });
            }}
            onConfirm={this.handleForceCompleteSurvey}
            isWarning
          />
          <AlertDialog
            translations={translations.surveyGroupDeleteDialog}
            isOpened={isDeleteSurveyGroupOpened}
            onClose={this.handleCloseGroupDelete}
            onConfirm={this.handleDeleteGroup}
            isWarning
          />
          <AlertDialog
            translations={translations.removeFromGroupDialog}
            isOpened={isRemoveFromGroupOpened}
            onClose={this.handleCloseRemoveFromGroupDialog}
            onConfirm={this.handleSaveRemoveFromGroup}
            isWarning
          />
          <AlertDialog
            translations={translations.surveyGroupDuplicateDialog}
            isOpened={isDuplicateGroupOpened}
            onClose={this.handleCloseGroupDuplicate}
            onConfirm={this.handleDuplicateGroup}
          />
          <AlertDialog
            translations={translations.surveyDuplicateDialog}
            isOpened={isDuplicateSurveyOpened}
            onClose={this.handleCloseSurveyDuplicate}
            onConfirm={this.handleSaveSurveyDuplicate}
          />
          <ChooseNameDialog
            translations={translations.renameSurveyDialog}
            title={translations.renameSurveyDialog.title}
            description={translations.renameSurveyDialog.description}
            isOpened={isSurveyTitleDialogOpened}
            initialValue={survey?.title}
            field={SURVEY_TITLE_FIELD}
            onCancel={this.handleToggleSurveyTitleDialog}
            onSave={this.handleChangeSurveyTitle}
          />
          <ChooseNameDialog
            translations={translations.manageGroupDialog}
            title={
              surveyGroup
                ? translations.manageGroupDialog.title.edit
                : translations.manageGroupDialog.title.create
            }
            description={translations.manageGroupDialog.description}
            isOpened={isGroupDialogOpened}
            initialValue={surveyGroup?.title}
            field={SURVEYS_GROUP_FIELD}
            onCancel={this.handleCloseGroupDialog}
            onSave={this.handleSaveGroup}
          />
          <CustomSelectDialog
            translations={translations.manageGroupItemsDialog}
            subTitle={survey?.title}
            isOpened={isManageSurveyGroupDialogOpened}
            options={allSurveyGroups}
            isEdit={!!survey?.group}
            initialValue={survey?.group}
            field={SURVEY_GROUP_FIELD}
            onCancel={this.handleCloseSurveyGroupDialog}
            onSave={this.handleSaveSurveyGroup}
          />
        </div>
      )
    );
  }
}

SurveysPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  organizationSettings: PropTypes.shape({}).isRequired,
  auth: PropTypes.object.isRequired,
  dialogs: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allSurveyGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  attributesWithQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  surveyGroups: PropTypes.object.isRequired,
  surveys: PropTypes.object.isRequired,
  attribute: PropTypes.shape({}).isRequired,
  getSurveys: PropTypes.func.isRequired,
  clearSurveys: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  setIsInlineSurvey: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
  getAttributesWithQuestions: PropTypes.func.isRequired,
  clearAttributesWithQuestions: PropTypes.func.isRequired,
  getTrackingById: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  clearAttribute: PropTypes.func.isRequired,
  editSurvey: PropTypes.func.isRequired,
  clearSurvey: PropTypes.func.isRequired,
  getSurveyGroups: PropTypes.func.isRequired,
  clearSurveyGroups: PropTypes.func.isRequired,
  getAllSurveyGroups: PropTypes.func.isRequired,
  clearAllSurveyGroups: PropTypes.func.isRequired,
  setGroupSurveys: PropTypes.func.isRequired,
  deleteSurveyFromGroup: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default withStyles(styles)(SurveysPage);
