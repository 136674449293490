import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people-icon.svg';
import ConditionalTooltip from '../conditionalTooltip';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    backgroundColor: primary.bluish8,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'baseline',
    flexShrink: 0,
    padding: spacing(1, 3),
    borderRadius: 40,
  },
  icon: {
    marginLeft: spacing(1),
  },
  tooltip: {
    fontSize: 14,
    fontFamily: 'ProximaNova-Bold',
  },
  iconWrapper: {
    padding: 5,
    cursor: 'pointer',
  },
}));

const UserCountBadge = ({
  className,
  userCount,
  hasUserCount,
  tooltipLabel,
  icon: Icon,
  hasTooltip,
}) => {
  const classes = useStyles();
  const UserIcon = Icon || PeopleIcon;

  const renderPeopleIcon = () => {
    return (
      <div
        className={classNames(
          classes.root,
          { [classes.iconWrapper]: !hasUserCount },
          className
        )}
      >
        {hasUserCount ? (
          <Typography variant="subtitle1">{userCount}</Typography>
        ) : null}
        <UserIcon className={classNames({ [classes.icon]: hasUserCount })} />
      </div>
    );
  };

  return hasUserCount ? (
    renderPeopleIcon()
  ) : (
    <ConditionalTooltip
      customLabelClass={classes.tooltip}
      message={tooltipLabel}
      addTooltip={hasTooltip}
      position="top"
    >
      {renderPeopleIcon()}
    </ConditionalTooltip>
  );
};

UserCountBadge.defaultProps = {
  className: null,
  userCount: 0,
  tooltipLabel: '',
  hasUserCount: true,
  icon: null,
  hasTooltip: false,
};

UserCountBadge.propTypes = {
  className: PropTypes.string,
  tooltipLabel: PropTypes.string,
  userCount: PropTypes.number,
  hasUserCount: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  icon: PropTypes.object,
};

export default memo(UserCountBadge);
