import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import DraggableScroll from '../../../draggableScroll';
import HorizontalAnswer from '../horizontalAnswer';
import ScrollIndicators from '../../../scrollIndicators';
import { updateScrollPosition } from '../../../../../utility/fillSurveyUtils';
import { getAnswerWeight } from '../../../../../utility/attribute';
import {
  usePreviousValue,
  useDraggableScrollPosition,
} from '../../../../../utility/hooks';
import { NEUTRAL_ANSWER_WEIGHT } from '../../../questionEntry/config';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    maxWidth: '100%',
  },
  rootAttributePreview: {
    // subtract weight label
    maxWidth: 'calc(100% - 40px)',
  },
  answersScroll: {
    display: 'flex',
    width: '100%',
    paddingTop: spacing(10),
  },
  groupReviewAnswersScroll: {
    margin: spacing(0, 0, 2, 2),
    '&:after': {
      content: '""',
      flex: '0 0 8px',
    },
    [breakpoints.up('sm')]: {
      margin: spacing(0, 0, 4, 4),
      '&::-webkit-scrollbar-track': {
        marginRight: spacing(4),
      },
      '&:after': {
        content: '""',
        flex: '0 0 16px',
      },
    },
  },
  answerListWrapper: {
    width: 'auto',
  },
  answersWrapper: {
    display: 'flex',
  },
  answerWrapper: {
    marginRight: spacing(1),
    display: 'grid',
    justifyContent: 'flex-start',
    '&:last-child': {
      marginRight: 0,
    },
    [breakpoints.up('sm')]: {
      marginRight: spacing(2),
    },
  },
  answerWeight: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: 12,
    fontFamily: 'ProximaNova-Regular',
    marginTop: spacing(3),
  },
  arrow: {
    width: 24,
    height: 24,
    [breakpoints.up('xLg')]: {
      width: 24,
      height: 24,
    },
  },
  arrowLeft: {
    left: 8,
    top: 8,
    [breakpoints.up('sm')]: {
      left: 16,
    },
  },
  arrowLeftSingle: {
    left: 0,
  },
  arrowRight: {
    right: 8,
    top: 8,
    [breakpoints.up('sm')]: {
      right: 16,
    },
  },
  arrowRightSingle: {
    right: 0,
  },
  arrowRightAttributePreview: {
    top: 8,
  },
  arrowLeftAttributePreview: {
    top: 8,
  },
}));

const HorizontalAnswersGroup = ({
  neutralAnswerLabel,
  answers,
  subject,
  selection,
  questionId,
  isAttributePreview,
  isSingleReview,
  isDisabled,
  getSelectedAnswer,
  onSelect,
}) => {
  const classes = useStyles();
  const draggableScrollRef = useRef(null);
  const answersRef = useRef(null);
  const numberOfAnswers = answers.length;
  const previousQuestionId = usePreviousValue(questionId);

  const [
    activeScroll,
    onChangeScrollPosition,
    onScrollToRight,
    ,
    onScrollToStart,
  ] = useDraggableScrollPosition(draggableScrollRef, answersRef);

  const handleSelect = (answerId, user) => () => {
    if (!isDisabled) {
      onSelect(questionId, answerId, user);
    }
  };

  const handleScrollEnd = () => onChangeScrollPosition();

  useEffect(() => {
    if (previousQuestionId !== questionId) {
      onChangeScrollPosition();
      updateScrollPosition(draggableScrollRef.current, false);
    }
  }, [previousQuestionId, questionId, onChangeScrollPosition]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootAttributePreview]: isAttributePreview,
      })}
    >
      <DraggableScroll
        ref={el => {
          draggableScrollRef.current = el;
        }}
        className={classNames(classes.answersScroll, {
          [classes.groupReviewAnswersScroll]: !isSingleReview,
        })}
        onEndScroll={handleScrollEnd}
      >
        <div ref={answersRef} className={classes.answerListWrapper}>
          <div className={classes.answersWrapper}>
            {answers.map(answer => {
              const isSelected = getSelectedAnswer(
                selection,
                answer.id,
                subject
              );
              const answerWeight = getAnswerWeight(answer.weight);

              return (
                <div
                  key={`answer_item_${answer.id}`}
                  className={classes.answerWrapper}
                >
                  <HorizontalAnswer
                    neutralAnswerLabel={neutralAnswerLabel}
                    numberOfAnswers={numberOfAnswers}
                    isSelected={isSelected}
                    isDisabled={isDisabled}
                    answer={answer}
                    onSelect={handleSelect(answer.id, subject)}
                  />
                  {isAttributePreview && (
                    <div className={classes.answerWeight}>
                      {answerWeight !== NEUTRAL_ANSWER_WEIGHT
                        ? answerWeight
                        : ''}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </DraggableScroll>
      <ScrollIndicators
        arrowClass={classes.arrow}
        arrowLeftClass={classNames(classes.arrowLeft, {
          [classes.arrowLeftSingle]: isSingleReview,
          [classes.arrowLeftAttributePreview]: isAttributePreview,
        })}
        arrowRightClass={classNames(classes.arrowRight, {
          [classes.arrowRightSingle]: isSingleReview,
          [classes.arrowRightAttributePreview]: isAttributePreview,
        })}
        activeScroll={activeScroll}
        onScrollToRight={onScrollToRight}
        onScrollToStart={onScrollToStart}
      />
    </div>
  );
};

HorizontalAnswersGroup.defaultProps = {
  subject: {},
  isSingleReview: false,
  isDisabled: false,
  isAttributePreview: false,
  questionId: 0,
  selection: {},
  getSelectedAnswer: () => {},
  onSelect: () => {},
};

HorizontalAnswersGroup.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questionId: PropTypes.number,
  subject: PropTypes.shape({}),
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  neutralAnswerLabel: PropTypes.string.isRequired,
  selection: PropTypes.shape({}),
  getSelectedAnswer: PropTypes.func,
  onSelect: PropTypes.func,
  isAttributePreview: PropTypes.bool,
};

export default HorizontalAnswersGroup;
