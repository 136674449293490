import { FIELD_TYPES } from './fieldTypes';

export const ASSIGN_JOB_TITLE_TO_FORM_FIELDS = [
  {
    name: 'assign_to',
    type: FIELD_TYPES.SELECT,
    translationKey: 'assignTo',
    optionsKey: 'allUsers',
    isUser: true,
    isMulti: true,
    parser: {
      value: 'id',
      label: 'first_name',
    },
  },
];
