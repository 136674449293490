import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import Tag from '../tag';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ActionButton from '../actionButton';
import NotificationCard from '../notificationCard';
import ColorBox from '../colorBox';
import CustomButton from '../customButton';
import ConditionalTooltip from '../conditionalTooltip';
import ShareTypeBox from '../shareTypeBox';
import { isArrayEmpty } from '../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { PLACEMENTS } from '../../../constants/tags';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  header: {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(4),
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: ({ isEditable, hasPeoplePreview }) => {
      if (isEditable && hasPeoplePreview) {
        return 'calc(100% - 163px)';
      }

      if (hasPeoplePreview) {
        return 'calc(100% - 38px)';
      }

      return '100%';
    },
  },
  colorBox: {
    flexShrink: 0,
    marginRight: spacing(3),
  },
  noTags: {
    padding: spacing(6),
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagsDraggable: {
    paddingLeft: spacing(9.5),
  },
  tag: {
    margin: spacing(0, 2, 2, 0),
  },
  manageTagsButton: {
    padding: spacing(1, 3, 1, 3),
    maxHeight: 24,
    '& span': {
      fontSize: 12,
    },
  },
  manageTagsIcon: {
    marginRight: spacing(1),
    width: 16,
    height: 16,
  },
  dragButton: {
    flexShrink: 0,
    marginRight: spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: spacing(2),
  },
  placementWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0, max-content))',
    gridColumnGap: 8,
  },
  placementWrapperDraggable: {
    marginLeft: spacing(9.5),
  },
  placement: {
    border: `1px solid ${primary.bluish5}`,
    borderRadius: 4,
    color: primary.bluish2,
    userSelect: 'none',
    marginBottom: spacing(4),
  },
}));

const TagsCategory = ({
  className,
  translations,
  category,
  hasPeoplePreview,
  isHighlighted,
  isTagSelectable,
  isEditable,
  isSmallCategoryBox,
  isDraggable,
  hasPlacements,
  dragRef,
  dragProps,
  onPreview,
  onManageTags,
  onEdit,
  onSelectTag,
}) => {
  const classes = useStyles({ isEditable, hasPeoplePreview });
  const hasTags = useMemo(() => !isArrayEmpty(category.tags), [category.tags]);
  const hasFiltersPlacements = useMemo(
    () =>
      hasPlacements &&
      !isArrayEmpty(category.placements) &&
      category.placements.some(
        placement => placement.id !== PLACEMENTS.ORGANIZATION_MENU.id
      ),
    [hasPlacements, category.placements]
  );

  const handleEdit = useCallback(() => onEdit(category), [category, onEdit]);

  const handleSelectTag = useCallback(
    tag => onSelectTag(tag, category),
    [category, onSelectTag]
  );

  const handleManageTags = useCallback(
    () => onManageTags(category),
    [category, onManageTags]
  );

  const handlePreview = useCallback(
    () => onPreview(category.id),
    [category, onPreview]
  );

  return (
    <div
      ref={dragRef}
      {...(dragProps ? { ...dragProps.draggableProps } : {})}
      className={className}
    >
      <div className={classes.header}>
        <div className={classes.nameWrapper}>
          {isDraggable && (
            <ConditionalTooltip
              className={classes.dragButton}
              message={translations.draggableTooltip}
              addTooltip
            >
              <ActionButton
                type={ACTION_BUTTON_TYPES.DRAG}
                {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
              />
            </ConditionalTooltip>
          )}
          <ColorBox
            className={classes.colorBox}
            bgColor={category.color}
            isSmall={isSmallCategoryBox}
          />
          <TextBoxWithTooltip
            variant="h5"
            text={`${category.name} (${category?.tags?.length ?? 0})`}
          />
          {isEditable && (
            <ActionButton
              className={classes.button}
              type={ACTION_BUTTON_TYPES.EDIT}
              onClickHandler={handleEdit}
            />
          )}
        </div>
        <div className={classes.actions}>
          {hasPeoplePreview && hasTags && (
            <ActionButton
              className={classes.button}
              type={ACTION_BUTTON_TYPES.PEOPLE}
              tooltipText={translations.previewPeople}
              onClickHandler={handlePreview}
            />
          )}
          {isEditable && (
            <CustomButton
              className={classNames(classes.manageTagsButton, classes.button)}
              customIconWrapper={classes.manageTagsIcon}
              type="addDarkRoundedOutlined"
              onClick={handleManageTags}
            >
              {translations.manageTagsButton}
            </CustomButton>
          )}
        </div>
      </div>
      <TransitionGroup
        className={classNames(classes.placementWrapper, {
          [classes.placementWrapperDraggable]: isDraggable,
        })}
      >
        {hasPlacements &&
          category.placements.map(placement => {
            return (
              <Fade key={placement.id} in unmountOnExit>
                <div>
                  <ShareTypeBox
                    className={classes.placement}
                    label={placement.label}
                  />
                </div>
              </Fade>
            );
          })}
      </TransitionGroup>
      <TransitionGroup
        className={classNames(classes.tags, {
          [classes.tagsDraggable]: isDraggable,
        })}
      >
        {category.tags.map(tag => (
          <Fade key={`tag_item_${tag.id}`} in unmountOnExit>
            <div>
              <Tag
                className={classes.tag}
                tag={tag}
                color={category.color}
                isSelectable={isTagSelectable}
                isSelected={isHighlighted}
                onTagSelect={handleSelectTag}
              />
            </div>
          </Fade>
        ))}
      </TransitionGroup>
      <NotificationCard
        className={classes.noTags}
        title={translations.noTags.title}
        content={translations.noTags.content}
        {...(hasFiltersPlacements
          ? { message: translations.noTags.message }
          : {})}
        shouldFade={!hasTags}
      />
    </div>
  );
};

TagsCategory.defaultProps = {
  className: '',
  translations: {},
  isEditable: false,
  isTagSelectable: false,
  isSmallCategoryBox: false,
  isHighlighted: false,
  isDraggable: false,
  hasPeoplePreview: false,
  hasPlacements: false,
  dragProps: null,
  dragRef: () => {},
  onPreview: () => {},
  onManageTags: () => {},
  onSelectTag: () => {},
  onEdit: () => {},
};

TagsCategory.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object,
  hasPeoplePreview: PropTypes.bool,
  isEditable: PropTypes.bool,
  isTagSelectable: PropTypes.bool,
  isSmallCategoryBox: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isDraggable: PropTypes.bool,
  hasPlacements: PropTypes.bool,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  category: PropTypes.object.isRequired,
  onPreview: PropTypes.func,
  onManageTags: PropTypes.func,
  onSelectTag: PropTypes.func,
  onEdit: PropTypes.func,
};

export default TagsCategory;
