import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { ellipsis } from '../../../../constants/helperCssRules';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(4),
      },
    },
    reviewName: {
      wordBreak: 'break-word',
      ...ellipsis(),
    },
    completionTime: {
      color: primary.bluish2,
      marginLeft: spacing(3),
    },
    numberOfReviews: {
      color: primary.bluish3,
      marginLeft: spacing(1),
      flexShrink: 0,
    },
  })
);

const SurveyInfo = ({ survey, surveyStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.reviewName}
        variant="subtitle2"
        component="h4"
      >
        {survey.attributes[surveyStep].name}
      </Typography>
      <Typography variant="subtitle2" className={classes.numberOfReviews}>
        {`(${surveyStep + 1}/${survey.totalReviews})`}
      </Typography>
    </div>
  );
};

SurveyInfo.propTypes = {
  survey: PropTypes.shape({}).isRequired,
  surveyStep: PropTypes.number.isRequired,
};

export default SurveyInfo;
