import { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, makeStyles } from '@material-ui/core';
import ConditionalTooltip from '../../conditionalTooltip';
import AsyncListTooltip from '../../asyncListTooltip';
import UserAvatar from '../../userAvatar';
import { ReactComponent as TextReplyIcon } from '../../../../assets/icons/text_snippet.svg';

const useStyles = makeStyles(() => ({
  scoreButton: {
    width: 40,
    height: 40,
  },
  score: {
    flexShrink: 0,
    textAlign: 'center',
  },
  userScoreWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userAvatar: {
    width: 'calc(100% - 40px)',
  },
  textReplyWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const AttributeScore = ({
  translations,
  averageScore,
  isTextReply,
  shouldDisableIndividualScores,
  onGetScores,
}) => {
  const classes = useStyles();

  const renderIndividualScores = user => {
    const userScore = user.score !== null ? user.score : '/';

    return (
      <div className={classes.userScoreWrapper}>
        <UserAvatar
          className={classes.userAvatar}
          variant="subtitle1"
          user={user}
          small
          caption
        />
        <Typography variant="h5" component="p">
          {userScore}
        </Typography>
      </div>
    );
  };

  if (isTextReply) {
    return (
      <ConditionalTooltip
        message={translations.textReplyNoAverageScore}
        addTooltip
      >
        <div className={classes.textReplyWrapper}>
          <TextReplyIcon />
        </div>
      </ConditionalTooltip>
    );
  }

  if (shouldDisableIndividualScores) {
    return (
      <ConditionalTooltip
        message={translations.attributeScore.anonymous}
        addTooltip
      >
        <Typography className={classes.score} variant="h5">
          {averageScore}
        </Typography>
      </ConditionalTooltip>
    );
  }

  if (averageScore !== null) {
    return (
      <AsyncListTooltip
        position="left"
        renderLabelItem={renderIndividualScores}
        getLabelData={onGetScores}
        isDisabled
      >
        <ConditionalTooltip
          message={translations.attributeScore.default}
          addTooltip
        >
          <IconButton className={classes.scoreButton}>
            <Typography className={classes.score} variant="h5" component="span">
              {averageScore}
            </Typography>
          </IconButton>
        </ConditionalTooltip>
      </AsyncListTooltip>
    );
  }

  return null;
};

AttributeScore.defaultProps = {
  averageScore: null,
  shouldDisableIndividualScores: false,
  onGetScores: () => {},
};

AttributeScore.propTypes = {
  translations: PropTypes.object.isRequired,
  isTextReply: PropTypes.bool.isRequired,
  averageScore: PropTypes.number,
  shouldDisableIndividualScores: PropTypes.bool,
  onGetScores: PropTypes.func,
};

export default memo(AttributeScore);
