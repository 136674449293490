import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import UserAvatar from '../../userAvatar';
import HorizontalAnswersGroup from './horizontalAnswersGroup';
import { getPersonShortName } from '../../../../utility/helpers';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
    },
    reviewAnswers: {
      display: 'flex',
      width: '100%',
    },
    groupReviewAnswers: {
      height: '100%',
      [breakpoints.up('sm')]: {
        height: 'auto',
        maxHeight: '100%',
      },
    },
    groupReviewAnswer: {
      boxSizing: 'border-box',
      border: `1px solid ${primary.bluish6}`,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: spacing(2),
      userSelect: 'none',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    subject: {
      margin: spacing(2, 2, 0, 2),
      [breakpoints.up('sm')]: {
        margin: spacing(4, 4, 0, 4),
      },
    },
    userAvatar: {
      height: 20,
      width: 20,
      fontSize: 7,
      lineHeight: '18px',
      [breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: 1,
        height: 32,
        width: 32,
      },
    },
    userLabel: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 12,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
    answersScroll: {
      display: 'flex',
    },
    horizontalWeightLabel: {
      fontSize: 12,
      fontFamily: 'ProximaNova-Bold',
      marginLeft: spacing(2),
      marginTop: spacing(23),
      marginRight: spacing(2),
    },
  })
);

const HorizontalAnswers = ({
  neutralAnswerLabel,
  weightLabel,
  subjects,
  answers,
  selection,
  questionId,
  isSingleReview,
  isDisabled,
  isAttributePreview,
  onSelect,
  getSelectedAnswer,
}) => {
  const classes = useStyles();

  const [subject] = subjects;

  return (
    <div className={classes.main}>
      {isSingleReview ? (
        <div className={classes.reviewAnswers}>
          <HorizontalAnswersGroup
            questionId={questionId}
            subject={subject}
            isSingleReview={isSingleReview}
            isDisabled={isDisabled}
            isAttributePreview={isAttributePreview}
            neutralAnswerLabel={neutralAnswerLabel}
            answers={answers}
            selection={selection}
            getSelectedAnswer={getSelectedAnswer}
            onSelect={onSelect}
          />
          {isAttributePreview && (
            <div className={classes.horizontalWeightLabel}>{weightLabel}</div>
          )}
        </div>
      ) : (
        <div className={classes.groupReviewAnswers}>
          {subjects.map(sub => {
            return (
              <div
                key={`review_subject_${sub.id}`}
                className={classes.groupReviewAnswer}
              >
                <UserAvatar
                  className={classes.subject}
                  customAvatarClass={classes.userAvatar}
                  labelClass={classes.userLabel}
                  getCustomCaption={getPersonShortName}
                  user={sub}
                  caption
                />
                <div
                  className={classNames({
                    [classes.reviewAnswers]: isAttributePreview,
                  })}
                >
                  <HorizontalAnswersGroup
                    questionId={questionId}
                    subject={sub}
                    isSingleReview={isSingleReview}
                    isAttributePreview={isAttributePreview}
                    isDisabled={isDisabled}
                    neutralAnswerLabel={neutralAnswerLabel}
                    answers={answers}
                    selection={selection}
                    getSelectedAnswer={getSelectedAnswer}
                    onSelect={onSelect}
                  />
                  {isAttributePreview && (
                    <div className={classes.horizontalWeightLabel}>
                      {weightLabel}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

HorizontalAnswers.defaultProps = {
  isSingleReview: false,
  isDisabled: false,
  questionId: 0,
  subjects: [],
  selection: {},
  getSelectedAnswer: () => {},
  onSelect: () => {},
  weightLabel: '',
  isAttributePreview: false,
};

HorizontalAnswers.propTypes = {
  weightLabel: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questionId: PropTypes.number,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  isSingleReview: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  neutralAnswerLabel: PropTypes.string.isRequired,
  selection: PropTypes.shape({}),
  getSelectedAnswer: PropTypes.func,
  onSelect: PropTypes.func,
};

export default HorizontalAnswers;
