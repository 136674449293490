import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import UserAvatar from '../userAvatar';
import ConditionalTooltip from '../conditionalTooltip';
import CustomScrollBar from '../customScrollBar';
import ActionButton from '../actionButton';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon-new.svg';
import { isEmpty } from '../../../utility/validation';
import { useResizeObserver } from '../../../utility/hooks';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const DEFAULT_SPACING = 6;
const USER_COLUMN_WIDTH = 240;
const ATTRIBUTE_SQUARE_SIZE = 52;

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    maxWidth: '100%',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: ({ attributesCount }) =>
      attributesCount > 1
        ? `${USER_COLUMN_WIDTH}px minMax(${ATTRIBUTE_SQUARE_SIZE}px, ${
            attributesCount * ATTRIBUTE_SQUARE_SIZE +
            (attributesCount - 1) * DEFAULT_SPACING
          }px) repeat(2, minMax(${ATTRIBUTE_SQUARE_SIZE}px, ${ATTRIBUTE_SQUARE_SIZE}px))`
        : `${USER_COLUMN_WIDTH}px repeat(2, minMax(${ATTRIBUTE_SQUARE_SIZE}px, ${ATTRIBUTE_SQUARE_SIZE}px))`,
    gridColumnGap: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
  },
  headerAttributesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerAttributes: {
    display: 'grid',
    gridTemplateColumns: ({ attributesCount }) =>
      `repeat(${attributesCount}, ${ATTRIBUTE_SQUARE_SIZE}px)`,
    gridColumnGap: DEFAULT_SPACING,
  },
  userHeader: {
    boxSizing: 'border-box',
    paddingLeft: DEFAULT_SPACING,
    width: USER_COLUMN_WIDTH,
    padding: spacing(1, 0),
  },
  attributeHeader: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ATTRIBUTE_SQUARE_SIZE,
    userSelect: 'none',
    padding: spacing(1, 0),
  },
  averageHeader: {
    backgroundColor: primary.bluish3,
    borderRadius: 4,
    color: primary.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ATTRIBUTE_SQUARE_SIZE,
    userSelect: 'none',
    padding: spacing(1, 0),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: ({ attributesCount }) =>
      `${USER_COLUMN_WIDTH}px minMax(${ATTRIBUTE_SQUARE_SIZE}px, ${
        attributesCount * ATTRIBUTE_SQUARE_SIZE +
        (attributesCount - 1) * DEFAULT_SPACING
      }px) repeat(${
        attributesCount > 1 ? 2 : 1
      }, minMax(${ATTRIBUTE_SQUARE_SIZE}px, ${ATTRIBUTE_SQUARE_SIZE}px))`,
    gridColumnGap: DEFAULT_SPACING,
  },
  column: {
    display: 'grid',
    gridRowGap: DEFAULT_SPACING,
  },
  attributesColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  averageColumn: {
    display: 'grid',
    gridTemplateColumns: ({ attributesCount }) =>
      `repeat(${
        attributesCount > 1 ? 2 : 1
      }, minMax(${ATTRIBUTE_SQUARE_SIZE}px, ${ATTRIBUTE_SQUARE_SIZE}px))`,
    gridColumnGap: DEFAULT_SPACING,
    gridRowGap: DEFAULT_SPACING,
  },
  contentAttributes: {
    display: 'grid',
    gridTemplateColumns: ({ attributesCount }) =>
      `repeat(${attributesCount}, ${ATTRIBUTE_SQUARE_SIZE}px)`,
    gridColumnGap: DEFAULT_SPACING,
  },
  user: {
    boxSizing: 'border-box',
    paddingLeft: DEFAULT_SPACING,
    width: USER_COLUMN_WIDTH,
  },
  userFullName: {
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '21px',
  },
  userPosition: {
    position: 'static',
  },
  attribute: {
    display: 'flex',
    flexDirection: 'column',
    width: ATTRIBUTE_SQUARE_SIZE,
    height: ATTRIBUTE_SQUARE_SIZE,
    alignItems: 'center',
    backgroundColor: primary.bluish8,
    borderRadius: 8,
  },
  averageScore: {
    backgroundColor: primary.bluish3,
  },
  attributeValue: {
    marginTop: spacing(4),
  },
  averageValue: {
    color: primary.bluish9,
  },
  notAvailable: {
    color: primary.bluish5,
  },
  removeUser: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ATTRIBUTE_SQUARE_SIZE,
  },
  removeUserItem: {
    backgroundColor: primary.bluish8,
    borderRadius: 8,
  },
  closeIcon: {
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  barContainer: {
    marginTop: spacing(1),
    width: 40,
    height: 2,
    borderRadius: 30,
    backgroundColor: primary.bluish5,
  },
  bar: {
    height: 2,
    backgroundColor: primary.bluish1,
  },
  personalAttributeIcon: {
    marginLeft: spacing(1),
  },
  pointer: { cursor: 'pointer' },
  scrollX: {
    backgroundColor: primary.bluish9,
    left: 0,
    bottom: -16,
    width: '100%',
  },
  scrollY: {
    top: 0,
    right: -12,
    height: '100%',
  },
  scrollXAttributes: {
    backgroundColor: primary.bluish9,
    left: 0,
    bottom: ({ scrollOffset }) => -scrollOffset + 10,
    width: '100%',
    zIndex: 99,
  },
}));

const AVERAGE_KEY = 'avg';
const MAX_SCORE = 10;

const SkillsMatrixTable = ({
  className,
  translations,
  teamAttributes,
  teamData,
  onEmployeeClick,
  onRemoveEmployee,
  onRemoveAllEmployees,
}) => {
  const attributeScrollRef = useRef(null);
  const [ref, rect] = useResizeObserver();
  const attributesCount = teamAttributes?.length;
  const teamDataCount = teamData?.length;
  const classes = useStyles({
    attributesCount,
    teamDataCount,
    scrollOffset: rect?.height,
  });
  const attributeKeys = teamAttributes.map(attr => attr.id);
  const calculateBarWidth = val => {
    return (val * 100) / MAX_SCORE;
  };

  const onAttributesScrollChange = ({ scrollLeft }) => {
    return attributeScrollRef?.current?.scrollbarRef?.current?.scrollTo(
      scrollLeft
    );
  };

  return (
    <div ref={ref} className={classNames(classes.root, className)}>
      <div className={classes.header}>
        <Typography variant="caption" className={classes.userHeader}>
          {translations.employee}
        </Typography>
        <div className={classes.headerAttributesWrapper}>
          <CustomScrollBar
            customScrollBarXClass={classes.scrollXAttributes}
            handleScrollPositionChange={onAttributesScrollChange}
            passContentHeight
          >
            <div className={classes.headerAttributes}>
              {teamAttributes?.map(attr => (
                <ConditionalTooltip
                  key={`attribute_${attr.code}_${attr.id}`}
                  message={attr.name}
                  addTooltip
                >
                  <Typography
                    className={classes.attributeHeader}
                    style={{ backgroundColor: attr.color }}
                    variant="caption"
                    component="div"
                  >
                    {attr.code}
                  </Typography>
                </ConditionalTooltip>
              ))}
            </div>
          </CustomScrollBar>
        </div>
        {attributesCount > 1 ? (
          <Typography variant="caption" className={classes.averageHeader}>
            {translations.average}
          </Typography>
        ) : null}

        <div className={classes.removeUser}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => onRemoveAllEmployees()}
          />
        </div>
      </div>
      <CustomScrollBar
        customScrollBarYClass={classes.scrollY}
        customScrollBarXClass={classes.scrollX}
        passContentHeight
        removeScrollX
        verticalScroll
      >
        <div className={classes.content}>
          <div className={classes.column}>
            {teamData.map(user => (
              <div key={`user_${user.id}`} className={classes.user}>
                <UserAvatar
                  labelClass={classes.userFullName}
                  customCaptionContainerClass={classes.userPosition}
                  user={user}
                  captionDescription={
                    user.position
                      ? user.position?.name
                      : translations.noJobTitle
                  }
                  onClickHandler={() => onEmployeeClick(user.id)}
                  clickableCaption
                  caption
                  withDescription
                  medium
                />
              </div>
            ))}
          </div>
          <div className={classes.attributesColumn}>
            <CustomScrollBar
              ref={attributeScrollRef}
              passContentHeight
              removeScrollX
            >
              <div className={classes.column}>
                {teamData.map(attribute => (
                  <div
                    key={`attribute_column_${attribute.id}`}
                    className={classes.contentAttributes}
                  >
                    {attributeKeys.map(key => {
                      return (
                        <div
                          key={`${attribute.id}_${key}`}
                          className={classes.attribute}
                        >
                          <Typography
                            variant="h5"
                            component="p"
                            className={classNames(classes.attributeValue, {
                              [classes.notAvailable]: !isEmpty(
                                attribute?.attr_scores[key.toString()]
                              ),
                            })}
                          >
                            {attribute?.attr_scores[key.toString()]
                              ? attribute.attr_scores[key.toString()]
                              : translations.notAvailable}
                          </Typography>
                          {attribute?.attr_scores[key.toString()] && (
                            <div className={classes.barContainer}>
                              <div
                                className={classes.bar}
                                style={{
                                  width: `${calculateBarWidth(
                                    attribute?.attr_scores[key.toString()]
                                  )}%`,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </CustomScrollBar>
          </div>
          <div className={classes.column}>
            {teamData.map(scores => (
              <div
                key={`average_column_${scores.id}`}
                className={classes.averageColumn}
              >
                {attributesCount > 1 && (
                  <div
                    className={classNames(
                      classes.attribute,
                      classes.averageScore
                    )}
                  >
                    <Typography
                      variant="h5"
                      component="p"
                      className={classNames(
                        classes.attributeValue,
                        classes.averageValue,
                        {
                          [classes.notAvailable]: !isEmpty(
                            scores?.attr_scores[AVERAGE_KEY]
                          ),
                        }
                      )}
                    >
                      {scores?.attr_scores[AVERAGE_KEY]
                        ? scores.attr_scores[AVERAGE_KEY]
                        : translations.notAvailable}
                    </Typography>
                  </div>
                )}
                <div
                  className={classNames(
                    classes.removeUser,
                    classes.removeUserItem
                  )}
                >
                  <ActionButton
                    iconClass={classes.closeIcon}
                    type={ACTION_BUTTON_TYPES.CLOSE}
                    onClickHandler={() => onRemoveEmployee(scores.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </CustomScrollBar>
    </div>
  );
};

SkillsMatrixTable.defaultProps = {
  className: null,
  teamData: [],
  teamAttributes: [],
  onRemoveEmployee: () => {},
  onRemoveAllEmployees: () => {},
};

SkillsMatrixTable.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  onEmployeeClick: PropTypes.func.isRequired,
  onRemoveEmployee: PropTypes.func,
  onRemoveAllEmployees: PropTypes.func,
  teamAttributes: PropTypes.arrayOf(PropTypes.object),
  teamData: PropTypes.arrayOf(PropTypes.object),
};

export default SkillsMatrixTable;
