import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import FreeTextAnswer from './freeTextAnswer';
import VerticalAnswer from './verticalAnswer';
import HorizontalAnswers from './horizontalAnswers';
import { isAnswerSelected } from '../../../utility/fillSurveyUtils';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Answers = ({
  translations,
  answers,
  isSingleReview,
  withTextReplyQuestions,
  subjects,
  results,
  isVertical,
  questionId,
  hasNeutralAnswer,
  answerReplyErrors,
  isPreview,
  isAttributePreview,
  onSetAnswerReplyErrors,
  onSelect,
  onChange,
}) => {
  const classes = useStyles();

  if (withTextReplyQuestions) {
    return (
      <div className={classes.root}>
        {subjects.map(subject => (
          <FreeTextAnswer
            key={`answer_item_${subject.id}`}
            translations={translations}
            initialAnswer={results?.[subject.review_tracking]?.[questionId]}
            questionId={questionId}
            subject={subject}
            error={answerReplyErrors?.[subject.review_tracking]}
            isSingleReview={isSingleReview}
            hasNeutralAnswer={hasNeutralAnswer}
            isDisabled={isPreview}
            onSetError={onSetAnswerReplyErrors}
            onChange={onChange}
          />
        ))}
      </div>
    );
  }

  if (isVertical) {
    return (
      <div className={classes.root}>
        {answers.map(answer => (
          <VerticalAnswer
            key={`answer_item_${answer.id}`}
            questionId={questionId}
            subjects={subjects}
            answer={answer}
            selection={results}
            neutralAnswerLabel={translations.neutralAnswer.label}
            isSingleReview={isSingleReview}
            isDisabled={isPreview}
            isAttributePreview={isAttributePreview}
            getSelectedAnswer={isAnswerSelected}
            onSelect={onSelect}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <HorizontalAnswers
        questionId={questionId}
        isSingleReview={isSingleReview}
        neutralAnswerLabel={translations.neutralAnswer.label}
        weightLabel={translations.weight}
        answers={answers}
        selection={results}
        subjects={subjects}
        isDisabled={isPreview}
        isAttributePreview={isAttributePreview}
        getSelectedAnswer={isAnswerSelected}
        onSelect={onSelect}
      />
    </div>
  );
};

Answers.defaultProps = {
  questionId: 0,
  subjects: [],
  isSingleReview: false,
  withTextReplyQuestions: false,
  isPreview: false,
  isAttributePreview: false,
  hasNeutralAnswer: false,
  results: {},
  answerReplyErrors: {},
  onSetAnswerReplyErrors: () => {},
  onSelect: () => {},
  onChange: () => {},
};

Answers.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  isVertical: PropTypes.bool.isRequired,
  isSingleReview: PropTypes.bool,
  withTextReplyQuestions: PropTypes.bool,
  isPreview: PropTypes.bool,
  isAttributePreview: PropTypes.bool,
  hasNeutralAnswer: PropTypes.bool,
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  results: PropTypes.shape({}),
  questionId: PropTypes.number,
  answerReplyErrors: PropTypes.shape({}),
  onSetAnswerReplyErrors: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
};

export default Answers;
