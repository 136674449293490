import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import FillReviewFlow from '../../shared/fillReviewFlow';
import AlertDialog from '../../shared/alertDialog';
import { ReactComponent as SurveyExpiredIcon } from '../../../assets/icons/expired.svg';
import { asyncDebounce, isObjectEmpty } from '../../../utility/helpers';
import { getActiveReviewIndex } from '../../../utility/fillSurveyUtils';
import {
  getSurveySteps,
  AUTO_CHANGE_QUESTION_DELAY,
  setPageHeight,
} from './config';

const styles = ({ breakpoints, spacing }) => ({
  main: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    fallbacks: [
      { height: '-moz-available' },
      { height: '-webkit-fill-available' },
      { height: 'fill-available' },
    ],
  },
  content: {
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: spacing(4, 6, 0),
    width: '100%',
    maxWidth: 600,
    height: '100%',
    [breakpoints.up('sm')]: {
      width: 600,
      padding: spacing(4, 0, 6),
    },
    [breakpoints.up('lg')]: {
      width: 930,
      maxWidth: 930,
    },
  },
  generateSurvey: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  generateSurveyMessage: {
    textAlign: 'center',
  },
  surveyExpiredIcon: {
    marginBottom: spacing(6),
    width: 98,
    height: 98,
  },
});

class FillSurveyPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitOpened: false,
      feedbackErrors: {},
      answerReplyErrors: {},
    };
    this.handleFeedbackErrors = asyncDebounce(this.handleFeedbackErrors);
    this.handleSetComments = asyncDebounce(this.handleSetComments);
    this.setPageHeight = asyncDebounce(setPageHeight);
  }

  componentDidMount() {
    this.setPageHeight();
    window.addEventListener('resize', this.setPageHeight, false);
  }

  componentWillUnmount() {
    const { isInlineSurvey, clearFillSurvey } = this.props;

    clearFillSurvey(isInlineSurvey);
    window.removeEventListener('resize', this.setPageHeight, false);
  }

  handleStepChange = step => {
    const {
      trackingToken,
      fillSurvey,
      surveyStep,
      getSurveyReview,
      setReviewStep,
    } = this.props;

    if (step === -1) {
      return getSurveyReview(
        trackingToken,
        fillSurvey.attributes[surveyStep - 1].id
      );
    }

    return setReviewStep(step);
  };

  handleAnswerReplyErrors = (errorType, user) =>
    this.setState(prevState => ({
      answerReplyErrors: {
        ...prevState.answerReplyErrors,
        [user.review_tracking]: errorType,
      },
    }));

  handleFeedbackErrors = (errorType, userTracking) =>
    this.setState(prevState => ({
      feedbackErrors: {
        ...prevState.feedbackErrors,
        [userTracking]: errorType,
      },
    }));

  handleSetComments = comments => {
    const { setComments } = this.props;

    setComments(comments);
  };

  handleCloseInlineSurvey = () => {
    const { history, setIsInlineSurvey } = this.props;

    setIsInlineSurvey(false);

    history.back();
  };

  handleFinishReview = () => {
    const {
      history,
      review,
      fillSurvey,
      surveyStep,
      submitReview,
      trackingToken,
    } = this.props;

    submitReview(trackingToken, review.comments).then(() => {
      if (surveyStep + 1 < fillSurvey.totalReviews) {
        this.handleGetReview(surveyStep + 1).then(
          this.toggleSubmitDialog,
          this.toggleSubmitDialog
        );
      } else {
        history.push('/thank-you');
      }
    });
  };

  handleAnswerSelect = (
    questionId,
    answerId,
    user,
    shouldDeleteAnswer = false
  ) => {
    const {
      fillSurvey,
      review,
      reviewStep,
      trackingToken,
      setAnswerResult,
      setReviewStep,
    } = this.props;

    setAnswerResult(trackingToken, {
      review_tracking: user.review_tracking,
      answer: shouldDeleteAnswer ? null : answerId,
      question: questionId,
    }).then(() => {
      // switch to next question automatically
      if (fillSurvey.isSingleSurvey && review.totalSteps > reviewStep + 1) {
        setTimeout(() => {
          setReviewStep(reviewStep + 1);
        }, AUTO_CHANGE_QUESTION_DELAY);
      }
    });
  };

  handleAnswerChange = (questionId, user, answer) => {
    const { trackingToken, setAnswerTextResult } = this.props;

    return setAnswerTextResult(trackingToken, {
      review_tracking: user.review_tracking,
      answer,
      question: questionId,
    });
  };

  handleGetReview = (surveyStep = 0) => {
    const { fillSurvey, trackingToken, getSurveyReview } = this.props;

    return getSurveyReview(trackingToken, fillSurvey.attributes[surveyStep].id);
  };

  toggleSubmitDialog = () =>
    this.setState(prevState => ({ isSubmitOpened: !prevState.isSubmitOpened }));

  handleSubmitReview = () => {
    const { surveyStep, review } = this.props;

    if (review.isSubmitted) {
      return this.handleGetReview(surveyStep + 1);
    }

    this.toggleSubmitDialog();
  };

  renderExpiredSurvey = () => {
    const { classes, translations } = this.props;

    return (
      <Grid className={classes.generateSurvey} item xs={12}>
        <SurveyExpiredIcon className={classes.surveyExpiredIcon} />
        <Typography variant="h1" className={classes.generateSurveyMessage}>
          {translations.expiredSurveyMessage}
        </Typography>
      </Grid>
    );
  };

  render() {
    const {
      classes,
      translations,
      isSurveyLoaded,
      fillSurvey,
      review,
      surveyStep,
      reviewStep,
      isInlineSurvey,
    } = this.props;
    const { isSubmitOpened, feedbackErrors, answerReplyErrors } = this.state;
    const activeReviewIndex = getActiveReviewIndex(fillSurvey.attributes);

    return (
      <Grid id="fill-survey-page" className={classes.main} container>
        {isSurveyLoaded && (
          <>
            {isObjectEmpty(fillSurvey) ? (
              this.renderExpiredSurvey()
            ) : (
              <Grid className={classes.content} item xs={12}>
                <FillReviewFlow
                  translations={translations}
                  surveyStep={surveyStep}
                  survey={fillSurvey}
                  review={review}
                  steps={getSurveySteps(review)}
                  activeStep={reviewStep}
                  feedbackErrors={feedbackErrors}
                  answerReplyErrors={answerReplyErrors}
                  isInlineSurvey={isInlineSurvey}
                  isPreview={review.isSubmitted}
                  onCloseInlineSurvey={this.handleCloseInlineSurvey}
                  onSetFeedbackErrors={this.handleFeedbackErrors}
                  onSetFeedbackComments={this.handleSetComments}
                  onStepChange={this.handleStepChange}
                  onAnswerSelect={this.handleAnswerSelect}
                  onAnswerChange={this.handleAnswerChange}
                  onSetAnswerReplyErrors={this.handleAnswerReplyErrors}
                  onSubmit={this.handleSubmitReview}
                  onGetStarted={() => this.handleGetReview(activeReviewIndex)}
                />
              </Grid>
            )}
          </>
        )}
        <AlertDialog
          translations={
            surveyStep + 1 < fillSurvey.totalReviews
              ? translations.submitMultipleDialog
              : translations.submitDialog
          }
          isOpened={isSubmitOpened}
          onClose={this.toggleSubmitDialog}
          onConfirm={this.handleFinishReview}
        />
      </Grid>
    );
  }
}

FillSurveyPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  trackingToken: PropTypes.string.isRequired,
  isSurveyLoaded: PropTypes.bool.isRequired,
  fillSurvey: PropTypes.shape({}).isRequired,
  review: PropTypes.shape({}).isRequired,
  surveyStep: PropTypes.number.isRequired,
  reviewStep: PropTypes.number.isRequired,
  isInlineSurvey: PropTypes.bool.isRequired,
  setIsInlineSurvey: PropTypes.func.isRequired,
  getSurveyReview: PropTypes.func.isRequired,
  setAnswerResult: PropTypes.func.isRequired,
  setAnswerTextResult: PropTypes.func.isRequired,
  setReviewStep: PropTypes.func.isRequired,
  setComments: PropTypes.func.isRequired,
  submitReview: PropTypes.func.isRequired,
  clearFillSurvey: PropTypes.func.isRequired,
};

export default withStyles(styles)(FillSurveyPage);
