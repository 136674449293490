import { useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import UserAvatar from '../userAvatar';
import DraggableScroll from '../draggableScroll';
import ScrollIndicators from '../scrollIndicators';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/close-gray.svg';
import { getPersonShortName } from '../../../utility/helpers';
import {
  usePreviousValue,
  useDraggableScrollPosition,
} from '../../../utility/hooks';
import { updateScrollPosition } from '../../../utility/fillSurveyUtils';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    main: {
      boxSizing: 'border-box',
      alignItems: 'center',
      overflowX: 'hidden',
      width: '100%',
      position: 'relative',
      maxWidth: '100%',
    },
    users: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 30,
      '& .selected': {
        backgroundColor: primary.blue6,
        '&$userDisabled': {
          backgroundColor: primary.bluish8,
        },
      },
      [breakpoints.up('sm')]: {
        minHeight: 40,
      },
    },
    user: {
      border: `1px solid ${primary.bluish6}`,
      borderRadius: 60,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1, 2),
      marginRight: spacing(1),
      pointerEvents: 'auto !important',
      '&:last-of-type': {
        marginRight: 0,
      },
      [breakpoints.up('sm')]: {
        padding: spacing(2, 4),
        marginRight: spacing(2),
      },
    },
    userDisabled: {
      cursor: 'default',
    },
    userAvatar: {
      height: 20,
      width: 20,
      fontSize: 7,
      lineHeight: '18px',
      [breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: 1,
        height: 32,
        width: 32,
      },
    },
    userLabel: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: 12,
      lineHeight: '14px',
      whiteSpace: 'nowrap',
      [breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
    removeIcon: {
      flexShrink: 0,
      height: 8,
      width: 8,
      marginLeft: spacing(1),
      [breakpoints.up('sm')]: {
        height: 14,
        width: 14,
        marginLeft: spacing(3),
      },
    },
    draggableScroll: {
      display: 'flex',
      margin: spacing(0, 0, 2, 2),
      paddingTop: spacing(10),
      '&:after': {
        content: '""',
        flex: '0 0 8px',
      },
      [breakpoints.up('sm')]: {
        margin: spacing(0, 0, 4, 4),
        '&::-webkit-scrollbar-track': {
          marginRight: spacing(4),
        },
        '&:after': {
          flex: '0 0 16px',
        },
      },
    },
    usersListWrapper: {
      width: 'auto',
    },
    usersWrapper: {
      display: 'flex',
    },
    arrow: {
      width: 24,
      height: 24,
      [breakpoints.up('xLg')]: {
        width: 24,
        height: 24,
      },
    },
    arrowLeft: {
      left: 8,
      top: 8,
      [breakpoints.up('sm')]: {
        left: 16,
      },
    },
    arrowRight: {
      right: 8,
      top: 8,
      [breakpoints.up('sm')]: {
        right: 16,
      },
    },
  })
);

const SelectableUserList = ({
  className,
  users,
  selection,
  questionId,
  selectionKey,
  isDisabled,
  getSelectedUser,
  shouldRenderUser,
  onSelect,
}) => {
  const classes = useStyles();
  const draggableScrollRef = useRef(null);
  const usersRef = useRef(null);
  const previousQuestionId = usePreviousValue(questionId);
  const [
    activeScroll,
    onChangeScrollPosition,
    onScrollToRight,
    ,
    onScrollToStart,
  ] = useDraggableScrollPosition(draggableScrollRef, usersRef);

  const selectableUsers = useMemo(() => {
    return users.filter(user => shouldRenderUser(user));
  }, [users, shouldRenderUser]);

  useEffect(() => {
    if (previousQuestionId !== questionId) {
      onChangeScrollPosition();
      updateScrollPosition(draggableScrollRef.current, false);
    }
  }, [questionId, previousQuestionId, onChangeScrollPosition]);

  useLayoutEffect(() => {
    onChangeScrollPosition();
  }, [selection, onChangeScrollPosition]);

  const handleScrollEnd = () => onChangeScrollPosition();

  return (
    <div className={classNames(classes.main, className)}>
      <DraggableScroll
        ref={el => {
          draggableScrollRef.current = el;
        }}
        className={classNames(classes.draggableScroll, classes.users)}
        onEndScroll={handleScrollEnd}
      >
        <div ref={usersRef} className={classes.usersListWrapper}>
          <div className={classes.usersWrapper}>
            {selectableUsers.map(user => {
              const isSelected = getSelectedUser(selection, selectionKey, user);

              return (
                <div
                  key={`user_${user.id}`}
                  className={classNames(classes.user, {
                    [classes.userDisabled]: isDisabled,
                    selected: isSelected,
                  })}
                  onClick={() => onSelect(user, isSelected)}
                >
                  <UserAvatar
                    customAvatarClass={classes.userAvatar}
                    labelClass={classes.userLabel}
                    getCustomCaption={getPersonShortName}
                    user={user}
                    caption
                  />
                  {isSelected && !isDisabled && (
                    <RemoveIcon className={classes.removeIcon} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </DraggableScroll>
      <ScrollIndicators
        arrowClass={classes.arrow}
        arrowLeftClass={classes.arrowLeft}
        arrowRightClass={classes.arrowRight}
        activeScroll={activeScroll}
        onScrollToRight={onScrollToRight}
        onScrollToStart={onScrollToStart}
      />
    </div>
  );
};

SelectableUserList.defaultProps = {
  className: null,
  selectionKey: 0,
  questionId: 0,
  selection: {},
  isDisabled: false,
  getSelectedUser: () => {},
  shouldRenderUser: () => true,
  onSelect: () => {},
};

SelectableUserList.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  questionId: PropTypes.number,
  selectionKey: PropTypes.number,
  selection: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  getSelectedUser: PropTypes.func,
  shouldRenderUser: PropTypes.func,
  onSelect: PropTypes.func,
};

export default SelectableUserList;
