import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, breakpoints }) => ({
  arrow: {
    backgroundColor: primary.white,
    position: 'absolute',
    borderRadius: 8,
    width: 24,
    height: 24,
    '&:hover': {
      backgroundColor: primary.bluish8,
    },
    [breakpoints.up('xLg')]: {
      width: 30,
      height: 30,
    },
  },
  arrowTop: {
    top: -12,
    left: '50%',
    transform: 'translateX(-50%) rotate(90deg)',
    [breakpoints.up('xLg')]: {
      top: -15,
    },
  },
  arrowBottom: {
    bottom: -12,
    left: '50%',
    transform: 'translateX(-50%) rotate(-90deg)',
    [breakpoints.up('xLg')]: {
      bottom: -15,
    },
  },
  arrowRight: {
    right: -12,
    top: 75,
    transform: 'rotate(180deg)',
    [breakpoints.up('xLg')]: {
      right: -15,
      top: 72,
    },
  },
  arrowLeft: {
    left: -12,
    top: 75,
    [breakpoints.up('xLg')]: {
      left: -15,
      top: 72,
    },
  },
}));

const ScrollIndicators = ({
  arrowClass,
  arrowLeftClass,
  arrowRightClass,
  activeScroll,
  onScrollToRight,
  onScrollToStart,
  onScrollToBottom,
}) => {
  const classes = useStyles();
  return (
    <>
      <Fade in={!!activeScroll.right} unmountOnExit>
        <ActionButton
          className={classNames(
            classes.arrow,
            arrowClass,
            classes.arrowRight,
            arrowRightClass
          )}
          type={ACTION_BUTTON_TYPES.ARROW_LEFT}
          onClickHandler={onScrollToRight}
          hasBorder
        />
      </Fade>
      <Fade in={!!activeScroll.left} unmountOnExit>
        <ActionButton
          className={classNames(
            classes.arrow,
            arrowClass,
            classes.arrowLeft,
            arrowLeftClass
          )}
          type={ACTION_BUTTON_TYPES.ARROW_LEFT}
          onClickHandler={onScrollToStart}
          hasBorder
        />
      </Fade>
      <Fade in={!!activeScroll.top} unmountOnExit>
        <ActionButton
          className={classNames(classes.arrow, arrowClass, classes.arrowTop)}
          type={ACTION_BUTTON_TYPES.ARROW_LEFT}
          onClickHandler={onScrollToStart}
          hasBorder
        />
      </Fade>
      <Fade in={!!activeScroll.bottom} unmountOnExit>
        <ActionButton
          className={classNames(classes.arrow, arrowClass, classes.arrowBottom)}
          type={ACTION_BUTTON_TYPES.ARROW_LEFT}
          onClickHandler={onScrollToBottom}
          hasBorder
        />
      </Fade>
    </>
  );
};

ScrollIndicators.defaultProps = {
  arrowClass: null,
  arrowLeftClass: null,
  arrowRightClass: null,
  onScrollToBottom: () => {},
};

ScrollIndicators.propTypes = {
  arrowClass: PropTypes.string,
  arrowLeftClass: PropTypes.string,
  arrowRightClass: PropTypes.string,
  activeScroll: PropTypes.object.isRequired,
  onScrollToRight: PropTypes.func.isRequired,
  onScrollToStart: PropTypes.func.isRequired,
  onScrollToBottom: PropTypes.func,
};

export default ScrollIndicators;
