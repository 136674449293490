import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import SelectField from '../../shared/selectField';
import AttributesList from '../../shared/attributesList';
import NotificationCard from '../../shared/notificationCard';
import SkillsMatrixTable from '../../shared/skillsMatrixTable';
import CustomDateRange from '../../shared/customDateRange';
import PeoplePicker from '../../shared/peoplePicker';
import AlertDialog from '../../shared/alertDialog';
import PresetDropdown from '../../shared/presetDropdown';
import PlaceholderImage from '../../../assets/images/skills-matrix-placeholder.png';
import {
  showSuccessMessage,
  parseDuplicateParameters,
} from '../../../utility/uiUtils';
import {
  isArrayEmpty,
  checkUserRole,
  getObjectToNumberArray,
  isItemInList,
  asyncDebounce,
  areArraysContentsEqual,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  getActivePresetId,
  getActivePresetName,
  getFilteredPresets,
  getPlaceholderName,
  handleHasMaxPresets,
  handlePresetName,
} from '../../../utility/presets';
import { getStartEndOfDayUtc } from '../../../utility/dateUtils';
import { getGroupedSurveyOptions } from '../../../utility/reportUtils';
import { GROUPED_SURVEY_REPORTING_TYPES } from '../../../constants/survey';
import { SKILLS_MATRIX_ATTRIBUTE_CONFIG } from '../../../constants/attributes';
import { getLast12Months } from '../../shared/customDateRange/config';
import {
  API_COMPARE_REPORTS_REVIEWED_ATTRIBUTES,
  API_COMPARE_REPORTS_USERS_BY_ATTRIBUTES,
  API_USERS_BASIC,
} from '../../../constants/apiRoutes';
import { PARAMS } from '../../../constants/pages';
import { PRESETS } from '../../../constants/presets';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import {
  PEOPLE_PARAM_SCOPE_VALUES,
  PEOPLE_DEFAULT_ORDERING,
} from '../../../constants/people';

const styles = ({ palette: { primary }, spacing }) => ({
  filters: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 350px))',
    gridColumnGap: 16,
    padding: spacing(8, 0, 6, 0),
    ...sticky(primary.white, 105),
  },
  attributesWrapper: {
    position: 'relative',
    marginBottom: spacing(6),
  },
  attributesList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  attributeWarning: {
    color: primary.red1,
    position: 'absolute',
    left: 0,
    bottom: -16,
  },
  attributeLabel: {
    marginBottom: spacing(2),
  },
  colorizedTags: {
    marginTop: spacing(-2),
  },
  noResults: {
    marginTop: spacing(2),
  },
  imagePlaceholder: {
    marginTop: spacing(2),
    width: '100%',
  },
  presetMenu: {
    zIndex: 999,
  },
  resultsTable: {
    // 100vh - headerHeight - filtersHeight - pageContainerBottomPadding
    maxHeight: `calc(100vh - 105px - 122px - 48px)`,
  },
});

const SKILL_MATRIX_PRESET_ID = PRESETS.SKILLS_MATRIX;
const DROPDOWN_WIDTH = '340px';
const INPUT_WIDTH = 300;

class SkillsMatrixPage extends PureComponent {
  state = {
    surveyType: GROUPED_SURVEY_REPORTING_TYPES[0],
    ranges: getLast12Months(),
    selectedAttributes: [],
    attributes: [],
    displayWarningMessage: false,
    message: '',
    selectedUsers: [],
    teamData: [],
    isFirstLoad: true,
    isLoading: true,
    isDeletePresetDialogOpened: false,
    selectedPreset: undefined,
    alreadySavedMessage: '',
    defaultPlaceholder: false,
    presetPlaceholderName: '',
  };

  componentDidMount() {
    const { auth, organizationSettings, getAllUsers, presets, translations } =
      this.props;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

    getAllUsers({
      ...(isAdmin
        ? { [PARAMS.EXCLUDE_ROLE]: ROLES.ASSOCIATE }
        : {
            [PARAMS.SCOPE]: [
              PEOPLE_PARAM_SCOPE_VALUES.ALL_REPORTS,
              ...(organizationSettings.global_see_himself
                ? [PEOPLE_PARAM_SCOPE_VALUES.ME]
                : []),
            ],
          }),
    }).then(async () => {
      const skillMatrixPresets = presets[SKILL_MATRIX_PRESET_ID];
      const existsPresetsArray =
        skillMatrixPresets && !isArrayEmpty(skillMatrixPresets);
      const initialPreset = existsPresetsArray
        ? skillMatrixPresets.find(
            preset => preset.id === presets.active[SKILL_MATRIX_PRESET_ID]
          )
        : undefined;
      const directReporters = await this.getDirectReporters();
      const users = isAdmin ? this.props.allUsers : directReporters;
      const filteredUsers =
        initialPreset &&
        !isArrayEmpty(initialPreset?.data?.users) &&
        !isArrayEmpty(users)
          ? users.filter(user => initialPreset?.data?.users.includes(user.id))
          : [];
      const initialUsers = initialPreset ? filteredUsers : users;

      if (!isArrayEmpty(initialUsers)) {
        this.setState(
          {
            selectedUsers: skillMatrixPresets ? initialUsers : directReporters,
            ...(initialPreset
              ? {
                  surveyType: GROUPED_SURVEY_REPORTING_TYPES.find(groupedType =>
                    areArraysContentsEqual(
                      groupedType.values,
                      initialPreset?.data?.survey_type
                    )
                  ),
                  ranges: initialPreset?.data?.ranges,
                  selectedPreset: initialPreset,
                  alreadySavedMessage: translations.filters.presets.used,
                }
              : {}),
          },
          this.getAttributes
        );
      } else {
        this.setState({ isLoading: false, isFirstLoad: false });
      }
    });
  }

  componentWillUnmount() {
    const { clearAllUsers } = this.props;

    clearAllUsers();
  }

  getDirectReporters = async () => {
    const { SCOPE } = PARAMS;
    const { DIRECT_REPORTS } = PEOPLE_PARAM_SCOPE_VALUES;

    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [SCOPE]: DIRECT_REPORTS,
      },
    });
    return data;
  };

  goToProfilePage = userId => {
    const { history } = this.props;
    history.push(`/people/${userId}`);
  };

  handleResetPreset = () => {
    const { editPresetActivity } = this.props;

    editPresetActivity(-1, SKILL_MATRIX_PRESET_ID, false);
    this.setState({ selectedPreset: undefined, alreadySavedMessage: '' });
  };

  checkValueInPresets = (
    surveyType,
    selectedUsers,
    ranges,
    selectableAttributes
  ) => {
    const { presets, editPresetActivity, translations } = this.props;
    const skillMatrixPresets = presets[SKILL_MATRIX_PRESET_ID];

    if (skillMatrixPresets && !isArrayEmpty(skillMatrixPresets)) {
      const activePreset = skillMatrixPresets.find(preset => {
        const presetData = preset?.data;
        const { end, start } = presetData.ranges;

        return (
          ranges.end === end &&
          ranges.start === start &&
          areArraysContentsEqual(presetData.attributes, selectableAttributes) &&
          areArraysContentsEqual(presetData.users, selectedUsers) &&
          areArraysContentsEqual(surveyType, presetData.survey_type)
        );
      });
      if (activePreset) {
        editPresetActivity(activePreset.id, SKILL_MATRIX_PRESET_ID, true);
        this.setState({
          selectedPreset: activePreset,
          alreadySavedMessage: translations.filters.presets.used,
        });
      } else {
        this.handleResetPreset();
      }
    }
  };

  getTeamPerformance = () => {
    const { surveyType, ranges, selectedUsers, selectedAttributes } =
      this.state;
    const { ATTRIBUTE, SURVEY_TYPE, START, END, START_AT, END_AT, USER } =
      PARAMS;
    const attributes = getObjectToNumberArray(selectedAttributes);

    const params = {
      [SURVEY_TYPE]: surveyType?.values,
      [START_AT]: getStartEndOfDayUtc(ranges[START]),
      [END_AT]: getStartEndOfDayUtc(ranges[END], true),
      [ATTRIBUTE]: attributes,
      [USER]: getObjectToNumberArray(selectedUsers),
    };

    http
      .get(API_COMPARE_REPORTS_USERS_BY_ATTRIBUTES, {
        params,
        paramsSerializer: data => parseDuplicateParameters(data),
      })
      .then(({ data }) => {
        this.setState(
          { teamData: data, isLoading: false, isFirstLoad: false },
          () =>
            this.checkValueInPresets(
              params[SURVEY_TYPE],
              params[USER],
              ranges,
              attributes
            )
        );
      });
  };

  onAttributeSelect = attribute => {
    const { selectedAttributes } = this.state;
    const { translations } = this.props;
    const { MIN, MAX } = SKILLS_MATRIX_ATTRIBUTE_CONFIG;
    const isSelected = isItemInList(selectedAttributes, attribute);
    const attributesCount = selectedAttributes.length;
    let updatedAttributes = [];

    if (isSelected) {
      if (attributesCount > MIN) {
        updatedAttributes = selectedAttributes.filter(
          selectedAttribute => selectedAttribute.id !== attribute.id
        );
        this.setState(
          {
            selectedAttributes: updatedAttributes,
          },
          this.getTeamPerformance
        );
      } else {
        this.showMessage(`${translations.filters.minAttribute} ${MIN}`);
      }
    } else if (attributesCount < MAX) {
      updatedAttributes = [...selectedAttributes, attribute];
      this.setState(
        { selectedAttributes: updatedAttributes },
        this.getTeamPerformance
      );
    } else {
      this.showMessage(`${translations.filters.maxAttribute} ${MAX}`);
    }
  };

  getAttributes = () => {
    const { surveyType, selectedPreset, ranges, selectedUsers } = this.state;
    const { SURVEY_TYPE, START, END, START_AT, END_AT, USER } = PARAMS;
    const presetData = selectedPreset?.data;

    const params = {
      [SURVEY_TYPE]: surveyType?.values,
      [START_AT]: getStartEndOfDayUtc(ranges[START]),
      [END_AT]: getStartEndOfDayUtc(ranges[END], true),
      [USER]: getObjectToNumberArray(selectedUsers),
    };

    this.setState({ isLoading: true });
    http
      .get(API_COMPARE_REPORTS_REVIEWED_ATTRIBUTES, {
        params,
        paramsSerializer: data => parseDuplicateParameters(data),
      })
      .then(({ data }) => {
        const selectableAttributes = data
          .filter(
            attribute =>
              !attribute.text_reply_questions &&
              (!presetData || presetData.attributes?.includes(attribute.id))
          )
          .slice(
            0,
            presetData?.attributes
              ? SKILLS_MATRIX_ATTRIBUTE_CONFIG.MAX
              : SKILLS_MATRIX_ATTRIBUTE_CONFIG.DEFAULT
          );
        this.setState(
          {
            attributes: data,
            selectedAttributes: selectableAttributes,
          },
          this.getTeamPerformance
        );
      });
  };

  handleSavePreset = presetName => {
    const { translations, addNewPreset } = this.props;
    const { surveyType, ranges, selectedUsers, selectedAttributes } =
      this.state;
    const selectedSurveyType = GROUPED_SURVEY_REPORTING_TYPES.find(
      srt => srt.key === surveyType.key
    );

    const data = {
      survey_type: selectedSurveyType.values,
      ranges,
      attributes: selectedAttributes.map(attribute => attribute.id),
      users: selectedUsers.map(user => user.id),
    };
    addNewPreset({
      name: presetName,
      location: SKILL_MATRIX_PRESET_ID,
      data,
    }).then(() => {
      this.setState({
        alreadySavedMessage: translations.filters.presets.used,
      });
      showSuccessMessage(
        translations.filters.presets.successMessages.createdPreset
      );
    });
  };

  handleDeletePreset = () => {
    const { deleteSelectedPreset, translations, presets } = this.props;
    const { selectedPreset } = this.state;

    deleteSelectedPreset(selectedPreset.id, SKILL_MATRIX_PRESET_ID).then(() => {
      this.setState({
        isDeletePresetDialogOpened: false,
        ...(presets.active[SKILL_MATRIX_PRESET_ID] === selectedPreset.id
          ? { selectedPreset: undefined, alreadySavedMessage: '' }
          : {}),
      });
      showSuccessMessage(
        translations.filters.presets.successMessages.deletedPreset
      );
    });
  };

  handleApplyPreset = preset => {
    const { editPresetActivity, allUsers } = this.props;
    const presetData = preset?.data;

    editPresetActivity(preset.id, SKILL_MATRIX_PRESET_ID, true);
    this.setState(
      {
        surveyType: GROUPED_SURVEY_REPORTING_TYPES.find(groupedType =>
          areArraysContentsEqual(groupedType.values, presetData?.survey_type)
        ),
        selectedUsers: allUsers.filter(user =>
          presetData?.users.includes(user.id)
        ),
        ranges: presetData?.ranges,
        selectedPreset: preset,
      },
      this.getAttributes
    );
  };

  onRemoveEmployee = userId => {
    const { selectedUsers } = this.state;
    this.setState(
      {
        selectedUsers: selectedUsers.filter(u => u.id !== userId),
      },
      this.getAttributes
    );
  };

  onRemoveAllEmployees = () => {
    this.setState(
      {
        selectedUsers: [],
      },
      this.getAttributes
    );
  };

  handleDeletePresetDialog = preset => {
    this.setState({ selectedPreset: preset, isDeletePresetDialogOpened: true });
  };

  handleSurveyTypeChange = selectedSurveyType => {
    this.handleResetPreset();
    this.setState(
      {
        isLoading: true,
        surveyType: selectedSurveyType,
        defaultPlaceholder: true,
      },
      this.getAttributes
    );
  };

  showMessage = (message, hideAfter = 2000) =>
    this.setState(
      { displayWarningMessage: true, message },
      asyncDebounce(
        () => this.setState({ displayWarningMessage: false, message: '' }),
        hideAfter
      )
    );

  handlePlaceholder = name =>
    this.setState({
      isLoading: true,
      defaultPlaceholder: false,
      presetPlaceholderName: name,
    });

  renderNoResultMessage() {
    const { classes, translations } = this.props;
    const { attributes, isLoading, selectedUsers } = this.state;
    const hasQuantitativeAttributes = attributes.every(
      att => !att.text_reply_questions
    );
    const message = hasQuantitativeAttributes
      ? translations.filters.attributeNoSurveyed
      : translations.filters.attributeNoQuantitativeData;

    return (
      !isLoading && (
        <>
          {!isArrayEmpty(selectedUsers) ? (
            <NotificationCard className={classes.noResults} content={message} />
          ) : (
            <img
              className={classes.imagePlaceholder}
              src={PlaceholderImage}
              alt={translations.imagePlaceholder}
            />
          )}
        </>
      )
    );
  }

  render() {
    const { classes, translations, allUsers, formattedUsers, presets } =
      this.props;
    const {
      surveyType,
      ranges,
      attributes,
      selectedAttributes,
      displayWarningMessage,
      message,
      selectedUsers,
      teamData,
      selectedPreset,
      isDeletePresetDialogOpened,
      alreadySavedMessage,
      isFirstLoad,
    } = this.state;
    const hasAttributes = !isFirstLoad && !isArrayEmpty(attributes);
    const hasResults =
      !isFirstLoad &&
      !isArrayEmpty(selectedAttributes) &&
      !isArrayEmpty(teamData);
    const filteredPresets = getFilteredPresets(presets, SKILL_MATRIX_PRESET_ID);
    const filteredAttributes = !selectedPreset?.data
      ? selectedAttributes
      : selectedAttributes.filter(attribute =>
          selectedPreset?.data?.attributes?.includes(attribute.id)
        );
    const activePresetId = getActivePresetId(presets, SKILL_MATRIX_PRESET_ID);
    const presetName = getActivePresetName(filteredPresets, activePresetId);
    const handledPresetName = handlePresetName(
      filteredPresets,
      activePresetId,
      presetName,
      this.state,
      translations.filters.peoplePicker.startSearch
    );
    const placeholderName = getPlaceholderName(
      handledPresetName,
      presetName,
      translations.filters.presets.startSearch
    );

    return (
      <div>
        <div>
          {!isFirstLoad && (
            <div className={classes.filters}>
              <SelectField
                label={translations.filters.surveyType}
                value={surveyType}
                options={getGroupedSurveyOptions(translations.surveyOptions)}
                parser={{ value: 'key', label: 'name' }}
                onChange={this.handleSurveyTypeChange}
                shouldReturnOption
                isSearchDisabled
              />
              <CustomDateRange
                initialRange={{
                  startDate: ranges[PARAMS.START],
                  endDate: ranges[PARAMS.END],
                }}
                startAtKey={PARAMS.START}
                endAtKey={PARAMS.END}
                label={translations.filters.range}
                onChange={newRange => {
                  this.handleResetPreset();
                  this.setState(
                    {
                      isLoading: true,
                      ranges: newRange,
                      defaultPlaceholder: true,
                    },
                    this.getAttributes
                  );
                }}
              />
              <PeoplePicker
                dropdownWidth={DROPDOWN_WIDTH}
                translations={translations.filters.peoplePicker}
                preselectedUsers={selectedUsers}
                options={allUsers}
                onSelect={items => {
                  this.handleResetPreset();
                  this.setState(
                    {
                      isLoading: true,
                      selectedUsers: items,
                      defaultPlaceholder: true,
                    },
                    this.getAttributes
                  );
                }}
                showDirectReports
                formattedUsers={formattedUsers}
                hasSelectAll
              />
              <PresetDropdown
                translations={translations.filters.presets}
                paperClass={classes.presetMenu}
                dropdownWidth={DROPDOWN_WIDTH}
                inputWidth={INPUT_WIDTH}
                options={filteredPresets}
                activePreset={activePresetId}
                handleApply={this.handleApplyPreset}
                handleDelete={this.handleDeletePresetDialog}
                handleSavePreset={this.handleSavePreset}
                disabledPreset={handleHasMaxPresets(
                  filteredPresets,
                  translations.presets
                )}
                alreadySavedMessage={alreadySavedMessage}
                presetPlaceholderName={placeholderName}
                handlePlaceholder={this.handlePlaceholder}
              />
            </div>
          )}
          <div>
            <div>
              {hasAttributes && (
                <div className={classes.attributesWrapper}>
                  <Typography
                    className={classes.attributeLabel}
                    variant="body2"
                  >
                    {translations.filters.measuredAttributes}
                  </Typography>
                  <div className={classes.attributesList}>
                    <AttributesList
                      attributes={attributes}
                      selectedAttributes={filteredAttributes}
                      checkIsSelectable={attribute =>
                        !attribute.text_reply_questions
                      }
                      onSelect={this.onAttributeSelect}
                      isHorizontalList
                      isScrollable
                    />
                  </div>
                  {displayWarningMessage ? (
                    <Typography
                      className={classes.attributeWarning}
                      variant="caption"
                    >
                      {message}
                    </Typography>
                  ) : null}
                </div>
              )}
              {hasResults ? (
                <SkillsMatrixTable
                  className={classes.resultsTable}
                  translations={translations.compareWidget}
                  teamAttributes={selectedAttributes}
                  teamData={teamData}
                  onEmployeeClick={this.goToProfilePage}
                  onRemoveEmployee={this.onRemoveEmployee}
                  onRemoveAllEmployees={this.onRemoveAllEmployees}
                />
              ) : (
                this.renderNoResultMessage()
              )}
            </div>
          </div>
        </div>
        <AlertDialog
          translations={translations.filters.presets.presetDeleteDialog}
          isOpened={isDeletePresetDialogOpened}
          onClose={() => this.setState({ isDeletePresetDialogOpened: false })}
          onConfirm={this.handleDeletePreset}
          isWarning
        />
      </div>
    );
  }
}

SkillsMatrixPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formattedUsers: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
};

export default withStyles(styles)(SkillsMatrixPage);
