import { connect } from 'react-redux';
import { PLACEMENTS } from 'constants/tags';
import JobTitlesPage from '../../components/pages/jobTitlesPage';
import PageContainer from '../../components/shared/pageContainer';
import { surveysSelector } from '../../store/selectors/surveysSelector';
import { jobTitlesSelector } from '../../store/selectors/jobTitlesSelector';
import { tagsSelector } from '../../store/selectors/tagsSelector';
import {
  getAttributesWithQuestions,
  clearAttributesWithQuestions,
} from '../../store/modules/surveys';
import {
  getJobTitles,
  clearJobTitles,
  setJobTitles,
} from '../../store/modules/jobTitles';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
  getPageQuickFilters,
  clearPageQuickFilters,
} from '../../store/modules/tags';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';
import { getAllUsers } from '../../store/modules/people';
import { peopleSelector } from '../../store/selectors/peopleSelector';

const mapStateToProps = state => ({
  ...jobTitlesSelector(state),
  ...tagsSelector(state),
  allAttributes: [...(surveysSelector(state)?.attributesWithQuestions || [])],
  allUsers: [...peopleSelector(state).allUsers],
});

const mapDispatchToProps = dispatch => ({
  getAttributesWithQuestions: params =>
    getAttributesWithQuestions(dispatch, params),
  getJobTitles: params => getJobTitles(dispatch, params),
  getAllUsers: params => getAllUsers(dispatch, params),
  clearAttributesWithQuestions: () => dispatch(clearAttributesWithQuestions()),
  clearJobTitles: () => dispatch(clearJobTitles()),
  addMultipleTags: data => dispatch(addMultipleTags(data)),
  getTagsCategories: () => getTagsCategories(dispatch),
  getPageQuickFilters: () => getPageQuickFilters(dispatch, PLACEMENTS.JOBS.id),
  clearTagsCategories: () => dispatch(clearTagsCategories()),
  clearPageQuickFilters: () => dispatch(clearPageQuickFilters()),
  setJobTitles: data => dispatch(setJobTitles(data)),
});

const JobTitlesPageView = props => {
  const translations = useTranslations(APP_PAGES.JOB_TITLES);

  return (
    <PageContainer {...props} translations={translations}>
      <JobTitlesPage />
    </PageContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTitlesPageView);
