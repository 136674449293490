import ColorBox from 'components/shared/colorBox';
import { ReactComponent as DashboardIcon } from '../assets/icons/widgets.svg';
import { ReactComponent as AlignmentIcon } from '../assets/icons/pending_actions.svg';
import { ReactComponent as ActionPlansIcon } from '../assets/icons/assignment.svg';
import { ReactComponent as MyPrivateNotesIcon } from '../assets/icons/person_pin.svg';
import { ReactComponent as OneOnOneIcon } from '../assets/icons/people.svg';
import { ReactComponent as OneOnOneReportIcon } from '../assets/icons/insights.svg';
import { ReactComponent as AttributeReportIcon } from '../assets/icons/details.svg';
import { ReactComponent as LearningIcon } from '../assets/icons/school.svg';
import { ReactComponent as ReviewIcon } from '../assets/icons/star-icon.svg';
import { ReactComponent as StatsIcon } from '../assets/icons/show_chart.svg';
import { ReactComponent as CompareIcon } from '../assets/icons/bar_chart.svg';
import { ReactComponent as SurveysIcon } from '../assets/icons/message.svg';
import { ReactComponent as AttributesIcon } from '../assets/icons/fact_check.svg';
import { ReactComponent as CareerIcon } from '../assets/icons/landscape.svg';
import { ReactComponent as LevelingIcon } from '../assets/icons/trending_up.svg';
import { ReactComponent as JobTitlesIcon } from '../assets/icons/title-grey.svg';
import { ReactComponent as CompanyIcon } from '../assets/icons/group_work.svg';
import { ReactComponent as PeopleIcon } from '../assets/icons/groups-grey.svg';
import { ReactComponent as OrgChartIcon } from '../assets/icons/table_chart.svg';
import { ReactComponent as TagsIcon } from '../assets/icons/tag.svg';
import { ReactComponent as ReportsIcon } from '../assets/icons/graphic_eq.svg';
import { ReactComponent as CompanyOverviewIcon } from '../assets/icons/dashboard.svg';
import { ReactComponent as ComparePeopleIcon } from '../assets/icons/compare-light-grey.svg';
import { ReactComponent as SurveySummaryIcon } from '../assets/icons/topic-icon-gray.svg';
import { checkUserRole, isArrayEmpty } from './helpers';
import { ROLES } from '../constants/rolesAndPermissionList';
import { ROUTE_ALL_ROLES } from '../constants/routeRequiredRoles';

export const getSidebarNavigationItems = (
  labels,
  user,
  organizationSettings,
  organizationMenuSetup,
  classes
) => {
  const { users_see_leveling, users_see_positions, mods_see_competence } =
    organizationSettings;
  const isAdmin = checkUserRole(user.role, ROLES.ADMIN);
  const isModerator = checkUserRole(user.role, ROLES.MODERATOR);
  const hasUnlimitedView = isAdmin || isModerator;
  const childrenSection = [];

  if (user?.id) {
    return [
      {
        id: 1,
        title: labels.dashboard.title,
        url: '/',
        icon: DashboardIcon,
      },
      ...(hasUnlimitedView
        ? [
            {
              id: 2,
              title: labels.alignment.title,
              icon: AlignmentIcon,
              items: [
                {
                  title: labels.alignment.items.actionPlans,
                  url: 'action-plans',
                  icon: ActionPlansIcon,
                },
                {
                  title: labels.alignment.items.myPrivateNotes,
                  url: 'my-private-notes',
                  icon: MyPrivateNotesIcon,
                },
                {
                  title: labels.alignment.items.oneOnOne,
                  url: '1-1',
                  icon: OneOnOneIcon,
                },
              ],
            },
          ]
        : []),
      {
        id: 3,
        title: labels.learning.title,
        url: 'learning',
        icon: LearningIcon,
      },
      ...(hasUnlimitedView
        ? [
            {
              id: 4,
              title: labels.review.title,
              icon: ReviewIcon,
              items: [
                {
                  title: labels.review.items.surveys,
                  url: 'surveys',
                  icon: SurveysIcon,
                },
                {
                  title: labels.review.items.attributes,
                  url: 'attributes',
                  icon: AttributesIcon,
                },
              ],
            },
          ]
        : []),
      {
        id: 5,
        title: labels.reports.title,
        icon: ReportsIcon,
        items: [
          ...(isAdmin
            ? [
                {
                  title: labels.reports.items.companyOverview,
                  url: '/reports/company-overview',
                  icon: CompanyOverviewIcon,
                },
              ]
            : []),
          ...(isAdmin || (isModerator && mods_see_competence)
            ? [
                {
                  title: labels.reports.items.stats,
                  url: '/reports/competence-map',
                  icon: StatsIcon,
                },
              ]
            : []),
          ...(hasUnlimitedView
            ? [
                {
                  title: labels.reports.items.skillsMatrix,
                  url: '/reports/skills-matrix',
                  icon: CompareIcon,
                },
              ]
            : []),
          ...(hasUnlimitedView
            ? [
                {
                  title: labels.reports.items.oneOnOneReport,
                  url: '/reports/general-mood',
                  icon: OneOnOneReportIcon,
                },
              ]
            : []),
          ...(isAdmin
            ? [
                {
                  title: labels.reports.items.attribute,
                  url: '/reports/attribute',
                  icon: AttributeReportIcon,
                },
              ]
            : []),
          ...(isAdmin
            ? [
                {
                  title: labels.reports.items.compare,
                  url: '/reports/compare',
                  icon: ComparePeopleIcon,
                },
              ]
            : []),
          {
            title: labels.reports.items.surveySummary,
            url: '/reports/survey-summary',
            icon: SurveySummaryIcon,
          },
        ],
      },
      ...(hasUnlimitedView || users_see_leveling || users_see_positions
        ? [
            {
              id: 6,
              title: labels.career.title,
              icon: CareerIcon,
              items: [
                ...(hasUnlimitedView || users_see_leveling
                  ? [
                      {
                        title: labels.career.items.leveling,
                        url: 'levels-and-tracks',
                        icon: LevelingIcon,
                      },
                    ]
                  : []),
                ...(hasUnlimitedView || users_see_positions
                  ? [
                      {
                        title: labels.career.items.jobTitles,
                        url: 'job-titles',
                        icon: JobTitlesIcon,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        id: 7,
        title: labels.company.title,
        icon: CompanyIcon,
        items: [
          {
            title: labels.company.items.people,
            url: 'people',
            icon: PeopleIcon,
          },
          {
            title: labels.company.items.orgChart,
            url: 'org-chart',
            icon: OrgChartIcon,
          },
          ...(hasUnlimitedView
            ? [
                {
                  title: labels.company.items.tags,
                  url: 'tags',
                  hasChildren: true,
                  icon: TagsIcon,
                },
              ]
            : []),
          ...(hasUnlimitedView &&
          organizationMenuSetup &&
          !isArrayEmpty(organizationMenuSetup)
            ? organizationMenuSetup?.map(filter => {
                const url = `tags/${filter.id}/people`;
                childrenSection.push(url);
                return {
                  title: filter.name,
                  hasParent: true,
                  icon: () => {
                    return <ColorBox className={classes.colorBox} isSmall />;
                  },
                  url,
                };
              }) || {}
            : []),
        ],
        childrenSection,
      },
    ];
  }
  return [];
};

export const getUserProfileNavigationItems = (id, currentUserId) => [
  {
    url: `/people/${id}`,
    name: 'Career',
    translationKey: 'career',
    isVisibleToCurrentUser: true,
    end: true,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/insights`,
    name: 'Insights',
    translationKey: 'insights',
    isVisibleToCurrentUser: true,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/my-people`,
    name: 'My people',
    translationKey: currentUserId === id ? 'ownTeam' : 'team',
    isVisibleToCurrentUser: true,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/action-plans`,
    name: 'Action plans',
    translationKey: 'actionPlans',
    isVisibleToCurrentUser: false,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/learning`,
    name: 'Learning',
    translationKey: 'learning',
    isVisibleToCurrentUser: true,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/notes`,
    name: 'Notes',
    translationKey: 'notes',
    isVisibleToCurrentUser: false,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/one-on-one`,
    name: '1-1',
    translationKey: 'oneOnOne',
    isVisibleToCurrentUser: false,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
  {
    url: `/people/${id}/general-info`,
    name: 'Info',
    translationKey: 'generalInfo',
    isVisibleToCurrentUser: true,
    end: false,
    requiredRoles: ROUTE_ALL_ROLES,
  },
];
